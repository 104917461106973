import { addDays, startOfTomorrow } from "date-fns";
import { dateToString } from "../utility/date";
import { generateRandomOrderNumber } from "../utility/misc";

const today = new Date();
const tomorrow = startOfTomorrow();
const todayPlus2 = addDays(today, 2);
const todayPlus3 = addDays(today, 3);

export const DEMO_CARGO_CONTENTS = [
    "TRANSPORT\n----PICKUP----\n6291045 Plywood 18mm standard\n8374920 PVC pipe 110mm\n3517824 Cement Portland 25kg\n7104682 Aluminum profile L-type\n\nFra.SH900 Delivery transport 03/11-25",
    "TRANSPORT\n----PICKUP----\n2945731 Steel cable 12mm\n8371645 Silicon cartridge\n5294168 Foam panel 50mm\n3614928 MDF board 12mm\n7156293 Polyester film roll\n\nFra.SH900 Delivery transport 14/7-25",
    "TRANSPORT\n----PICKUP----\n9348216 Kraft paper 250g\n4871395 Laminate panel oak\n8236941 EPDM membrane\n7194652 Polyethylene tube\n3742916 Mineral wool 100mm\n5937184 Zinc coating spray\n\nFra.SH900 Delivery transport 22/5-25",
    "TRANSPORT\n----PICKUP----\n1487369 Carbon fiber rod\n6293471 Ceramic tile 30x30\n9351742 Galvanized bolt M10\n5782361 Epoxy resin 5kg\n8246913 Polycarbonate sheet\n3691475 Asphalt granules\n7452918 Bubble wrap roll\n\nFra.SH900 Delivery transport 19/9-25",
];

export const SWE_DEMO_ORDERS = [
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Hornsgatan 45, Stockholm",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 300,
        tag: "Express",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Vintergatan 21, 172 69 Sundbyberg, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 2000,
        tag: "Under dagen",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Midgårdsvägen 9, 136 38 Handen, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 1500,
        tag: "Under dagen",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Vasagatan 13, 761 31 Norrtälje, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 760,
        tag: "Morgonpass",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Tingvallavägen 28C, 195 35 Märsta, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(tomorrow),
        time: "12:00",
        weight: 3000,
        tag: "Express",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Märstavägen 20, 193 40 Sigtuna, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(tomorrow),
        time: "14:00",
        weight: 400,
        tag: "Under dagen",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Vasavägen 250A, 192 69 Sollentuna, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 300,
        tag: "Under dagen",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Snösätravägen, 124 59 Bandhagen, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 2000,
        tag: "Morgonpass",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Dalgränd 6, 135 40 Tyresö, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(todayPlus2),
        time: "12:00",
        weight: 1500,
        tag: "Express",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Buskuddsvägen 16, 185 95 Vaxholm, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus2),
        time: "14:00",
        weight: 760,
        tag: "Under dagen",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Vikingshillsvägen 35, 132 37 Saltsjö-boo, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(todayPlus2),
        time: "13:00",
        weight: 3000,
        tag: "Under dagen",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Storsvängen 151, 129 44 Hägersten, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 400,
        tag: "Morgonpass",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Tomtbergavägen 115, 145 75 Norsborg, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 300,
        tag: "Express",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Gårdsfogdevägen 18A, 168 67 Bromma, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus3),
        time: "12:00",
        weight: 2000,
        tag: "Under dagen",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Äventyrsparken, Kalvhagsvägen 1, 181 31 Lidingö, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(todayPlus3),
        time: "14:00",
        weight: 1500,
        tag: "Under dagen",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Vasavägen 37, 181 42 Lidingö, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus3),
        time: "13:00",
        weight: 760,
        tag: "Morgonpass",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Åkargränd 3, 149 30 Nynäshamn, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(todayPlus3),
        time: "15:00",
        weight: 3000,
        tag: "Express",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Nybrogatan 79B, 114 41 Stockholm, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus3),
        time: "15:00",
        weight: 400,
        tag: "Under dagen",
    },
];

export const SWE_BIG_VOLUME_ORDERS = [
    ...SWE_DEMO_ORDERS.slice(0, 8),
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Tankebyggarbacken 40, 127 33 Skärholmen, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",

        weight: 300,
        tag: "Express",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Kungssätravägen 40, 127 37 Skärholmen, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 2000,
        tag: "Under dagen",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Storhagsvägen 46, 125 54 Älvsjö, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 1500,
        tag: "Under dagen",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Talldalsvägen 17, 141 69 Huddinge, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 760,
        tag: "Morgonpass",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Skolvägen 26, 141 44 Huddinge, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(tomorrow),
        time: "12:00",
        weight: 3000,
        tag: "Express",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Hörselslingan 8, 141 52 Huddinge, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(tomorrow),
        time: "14:00",
        weight: 400,
        tag: "Under dagen",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Flottiljvägen 16, 146 38 Tullinge, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 300,
        tag: "Under dagen",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Tunavägen 34, 147 42 Tumba, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 2000,
        tag: "Morgonpass",
    },
];

export const GB_DEMO_ORDERS = [
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "Carburton St, London W1W 5EE, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 300,
        tag: "Express",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "51 High St N, London E6 1HZ, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 2000,
        tag: "During the day",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "Whalebone Ln N, Romford RM6 6SB, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 1500,
        tag: "Morning",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "18 Heathway, Woodford, Woodford Green IG8 7RG, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 760,
        tag: "Afternoon",
    },
    {
        from: "Southey Rd, London SW19 1NU, United Kingdom",
        to: "45 Romford Road, London E15 4LY, UK",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 3000,
        tag: "Morning",
    },
    {
        from: "5 Stable St, London N1C 4AB, United Kingdom",
        to: "45 Romford Road, London E15 4LY, UK",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 400,
        tag: "During the day",
    },
    {
        from: "194 Southgate Rd, London N1 3HT, United Kingdom",
        to: "26 Blackhorse Rd, London E17 7BE, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 300,
        tag: "Morning",
    },
    {
        from: "Beigel Bake Brick Lane Bakery, Brick Lane, London E1 6SB, UK",
        to: "Severndroog Castle, London SE18 3RT, UK",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 2000,
        tag: "Afternoon",
    },
    {
        from: "1C Portland Pl, London W1B 1JA, United Kingdom",
        to: "119 Consort Rd, London SE15 3RU, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 1500,
        tag: "Morning",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "Hertford House, Manchester Square, London W1U 3BN, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 760,
        tag: "During the day",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "72 Honor Oak Park, London SE23 1DY, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 3000,
        tag: "Morning",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "Warwick Square, Pimlico, London SW1V 2AD, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(tomorrow),
        time: "15:00",
        weight: 400,
        tag: "Afternoon",
    },
    {
        from: "52 Cambridge St, Pimlico, London SW1V 4JD, United Kingdom",
        to: "45 Romford Road, London E15 4LY, UK",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 300,
        tag: "Morning",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "Drayton Park, London N5 1NT, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 2000,
        tag: "During the day",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "510C St Stephen's Rd, Bow, London E3 5ES, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(todayPlus2),
        time: "12:00",
        weight: 1500,
        tag: "Morning",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "51 Grove Rd., Bow, London E3 4PE, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus2),
        time: "14:00",
        weight: 760,
        tag: "Afternoon",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "185 Lower Rd, London SE16 2LW, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(todayPlus2),
        time: "13:00",
        weight: 3000,
        tag: "Morning",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "9 Priory Park, Blackheath, London SE3 9UY, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 400,
        tag: "During the day",
    },
];

export const DK_DEMO_ORDERS = [
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Copenhagen Airport (CPH), Lufthavnsboulevarden 6, 2770 Kastrup, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 300,
        tag: "Express",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Copenhagen Central Station, Bernstorffsgade, København, Danmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 2000,
        tag: "During the day",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Whalebone Ln N, Romford RM6 6SB, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 1500,
        tag: "Morning",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Alhambravej 11, 1826 Frederiksberg, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 760,
        tag: "Afternoon",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Marienborg Alle 60, 2860 Søborg, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 3000,
        tag: "Morning",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Roskildevej 173, 2620 Albertslund, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 400,
        tag: "During the day",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Park Allé 87, 2605 Brøndby, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 300,
        tag: "Morning",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Catherine Boothsvej 73, 2650 Hvidovre, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 2000,
        tag: "Afternoon",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Flensborggade 7, 1669 København, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 1500,
        tag: "Morning",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Perikumhaven 69A, 2730 Herlev, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 760,
        tag: "During the day",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Ejbydalsvej 15, 2600 Glostrup, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 3000,
        tag: "Morning",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Arne Jacobsens Allé 22, 2300 København, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(tomorrow),
        time: "15:00",
        weight: 400,
        tag: "Afternoon",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Markvænget 12, 2791 Dragør, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 300,
        tag: "Morning",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Drayton Park, London N5 1NT, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 2000,
        tag: "During the day",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Bagsværd Hovedgade 228, 2880 Bagsværd, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(todayPlus2),
        time: "12:00",
        weight: 1500,
        tag: "Morning",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Sekskanten A 9, 2630 Taastrup, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus2),
        time: "14:00",
        weight: 760,
        tag: "Afternoon",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Magnoliavej 28, 2600 Glostrup, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(todayPlus2),
        time: "13:00",
        weight: 3000,
        tag: "Morning",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Høyrups Alle 19, 2900 Hellerup, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 400,
        tag: "During the day",
    },
];

export const INTE_BARA_POSTEN_DEMO_DATA = [
    {
        from: "Regeringsgatan 47, 11156 Stockholm",
        to: "Engelbrektsplan 1, 11434 Östermalm",
        tag: "DirektPanik",
        pickupTime: "11:30",
    },
    {
        from: "Wahrendorffsgatan 2, 11147 Stockholm",
        to: "Storgatan 2, 11444 Östermalm",
        tag: "DirektPanik",
        pickupTime: "16:45",
    },
    {
        from: "Storgatan 2, 11444 Stockholm",
        to: "Wahrendorffsgatan 2, 11147 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "20:30",
    },
    {
        from: "Renstiernas Gata 20, 11631 Stockholm",
        to: "Kronborgsgränd 7, 16446 Kista",
        tag: "DirektPanik",
        pickupTime: "11:30",
    },
    {
        from: "SKEPPSBRON 21, 11130 Stockholm",
        to: "Mäster Samuelsgatan 42, 10tr, 11157 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "13:30",
    },
    {
        from: "Östermalmstorg 1, 11442 STOCKHOLM",
        to: "Kungsgatan 24, 6tr, 11135 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "11:00",
    },
    {
        from: "Holländargatan 20, 3 tr, 11160 Stockholm",
        to: "Stureplatån 15, 18132 Lidingö Västra",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Ranhammarsvägen 12, 16867 Bromma",
        to: "Artillerigatan 6, 1tr, 11434 Östermalm",
        tag: "Dags",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Valhallavägen 174, 11527 Stockholm",
        to: "Karlavägen 31, 11431 Östermalm",
        tag: "DirektPanik",
        pickupTime: "08:00",
    },
    {
        from: "Valhallavägen 65, 11428 Stockholm",
        to: "Sveavägen 4,4tr, 11157 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "07:30",
    },
    {
        from: "Döbelnsgatan 8, 11140 Stockholm",
        to: "Kungsgatan 28, 3tr, 11135 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "11:15",
    },
    {
        from: "Blasieholmsgatan 4 A, 11148 Stockholm",
        to: "Sjöviksbacken 55, 11756 Marievik",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sankt Paulsgatan 21, 11846 Stockholm",
        to: "Rösundavägen 2, 13336 Saltsjöbaden",
        tag: "DirektPanik",
        pickupTime: "09:00",
    },
    {
        from: "Karlavägen 77, 11449 Stockholm",
        to: "Grev Turegatan 9, 11446 Östermalm",
        tag: "DirektPanik",
        pickupTime: "08:00",
    },
    {
        from: "Artillerigatan 6, 1tr, 11451 Stockholm",
        to: "Kungsgatan 36, 8tr, 11135 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "10:00",
    },
    {
        from: "Malmtorgsgatan 3, 11151 STOCKHOLM",
        to: "Sturegatan 14, 11436 Östermalm",
        tag: "DirektPanik",
        pickupTime: "10:00",
    },
    {
        from: "Regeringsgatan 29, 11153 Stockholm",
        to: "Vendevägen 87, 18232 Danderyd",
        tag: "DirektPanik",
        pickupTime: "13:00",
        dropoffTime: "15:00",
    },
    {
        from: "Karlavägen 77, 11449 Stockholm",
        to: "Parlemogatan 17 / Magasin 5, 11556 Gärdet",
        tag: "DirektPanik",
        pickupTime: "14:00",
    },
    {
        from: "Odengatan 39, 11351 Stockholm",
        to: "Sturegatan 10, 11436 Östermalm",
        tag: "DirektPanik",
        pickupTime: "08:00",
    },
    {
        from: "Tegeluddsvägen 11-13, 11540 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "13:00",
        dropoffTime: "14:00",
    },
    {
        from: "Regeringsgatan 29, 11153 Stockholm",
        to: "Evenemangsgatan 17, 3tr, 16956 Solna",
        tag: "Panik (1h)",
        pickupTime: "13:00",
        dropoffTime: "15:00",
    },
    {
        from: "Valhallavägen 53, 11422 Stockholm",
        to: "Hantverkargatan 5, 11220 Kungsholmen",
        tag: "DirektPanik",
        pickupTime: "12:30",
    },
    {
        from: "Grev Turegatan 11 A vån 5, 11446 Stockholm",
        to: "Bigarråvägen 3, 11421 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "10:00",
        dropoffTime: "16:00",
    },
    {
        from: "Klarabergsgatan 60, 11121 Stockholm",
        to: "Hammarby Kaj 10A, 12032 Hammarby Sjöstad",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Klarabergsgatan 60, 11121 Stockholm",
        to: "Vasagatan 28, 11120 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Klarabergsgatan 60, 11121 Stockholm",
        to: "Sundbybergs Torg 1, 17267 Sundbyberg",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Bagartorpsringen 80, 17065 Järva",
        tag: "Dags",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Grev Turegatan 1, 11446 Stockholm",
        to: "Mäster Samuelsgatan 37, 11157 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "12:00",
    },
    {
        from: "Ringvägen 115, 11661 Stockholm",
        to: "Gärdesgatan 3, 11527 Gärdet",
        tag: "DirektPanik",
        pickupTime: "09:10",
    },
    {
        from: "Karlavägen 76, 11449 Stockholm",
        to: "Biblioteksgatan 12, 11446 Östermalm",
        tag: "DirektPanik",
        pickupTime: "11:10",
    },
    {
        from: "Strandvägen 5 B, 11451 Stockholm",
        to: "Birger Jarlsgatan 53, 11145 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Katarina Bangata 29, 11639 Stockholm",
        to: "Brovägen 5, 18276 Danderyd",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Kungsbro Strand 31, 11226 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "10:00",
        dropoffTime: "13:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Kistagången 20, 16440 Kista",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Tegeluddsvägen 11-13, 11541 Gärdet",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Stora Nygatan 26, 1tr, 11127 Gamla stan V",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Tegeluddsvägen 11-13, 11540 Gärdet",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Klarabergsviadukten 63, 11164 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Smålandsgatan 17, 11146 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Warfvinges väg 25, 11251 Marieberg",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Evenemangsgatan 17, 16956 Solna",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Sveavägen 9, 11157 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Regeringsgatan 38, 11153 Stockholm",
        to: "Stureplan 19, 5tr, 11145 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "09:30",
        dropoffTime: "11:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Hägerstensvägen 320, 12941 Hägersten",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Edelcrantz Väg 47, 13253 Saltsjö Boo",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Byvägen 18, 13334 Saltsjöbaden",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Robert Almströmsgatan 4, 11336 Vasastan",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Skeppargatan 76, 11459 Östermalm",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Mälarvägen 26, 16572 Hässelby",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Minkvägen 46, 19139 Sollentuna",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Båtvägen 11, 13452 Gustavsberg",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Starrängsringen 44, 11550 Gärdet",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Hantverkargatan 25B, 11221 Stockholm",
        to: "Kristinehovsgatan 23, 11729 Södermalm V",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "13:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Sagavägen 21, 13338 Saltsjöbaden",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Kristinehovsgatan 23, 11729 Stockholm",
        to: "Hantverkargatan 25B, 11221 Kungsholmen",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "16:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Scheelegatan 2, 11223 Kungsholmen",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Apelvägen 13, 12553 Långsjö",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Norrbergavägen 22, 14462 Rönninge",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Idrottsvägen 17A, 18247 Enebyberg",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Nabbenvägen 10, 15166 Södertälje",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Kontoristvägen 51, 12354 Farsta",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 19, 6tr, 11123 Stockholm",
        to: "Vasavägen 21D, 18773 Täby Kyrkby",
        tag: "Dags",
        pickupTime: "10:54",
        dropoffTime: "17:00",
    },
    {
        from: "Palermogatan 17, 11556 Stockholm",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "13:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Sankt Eriksplan 15, 11320 Vasastan",
        tag: "Dags Förbokad",
        pickupTime: "09:20",
        dropoffTime: "17:00",
    },
    {
        from: "Medevigatan 5, 1 tr, 11361 Stockholm",
        to: "Sveavägen 131, 11346 Vasastan",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "13:00",
    },
    {
        from: "Östermalmshallen -, 11439 STOCKHOLM",
        to: "Sturegatan 14, 11436 Östermalm",
        tag: "DirektPanik",
        pickupTime: "11:00",
    },
    {
        from: "Palermogatan 17, 11556 Stockholm",
        to: "Hornsgatan 103, 11728 Södermalm V",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "13:00",
    },
    {
        from: "Palermogatan 17, 11556 Stockholm",
        to: "Västmannagatan 6, 11124 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "13:00",
    },
    {
        from: "Palermogatan 17, 11556 Stockholm",
        to: "Jakobsgatan 6, 11152 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "13:00",
    },
    {
        from: "Palermogatan 17, 11556 Stockholm",
        to: "Fyrverkarbacken 25, 11260 Marieberg",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "13:00",
    },
    {
        from: "Tjärhovsgatan 1, 11621 Stockholm",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Dags Förbokad",
        pickupTime: "10:00",
        dropoffTime: "17:00",
    },
    {
        from: "Odengatan 26, 11351 Stockholm",
        to: "Klara Norra Kyrkogata 33 ,våning 6, 11122 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "10:30",
    },
    {
        from: "Tulegatan 11, 11353 Stockholm",
        to: "Fribordsgränd 5, 13655 Haninge",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "16:00",
    },
    {
        from: "Tulegatan 11, 11353 Stockholm",
        to: "Alpstigen 4, 13245 Orminge",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "16:00",
    },
    {
        from: "Wallingatan 5, 11160 Stockholm",
        to: "Karin Larssons Väg 70, 12863 Sköndal",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Jan Stenbecks Torg 17, 10tr, 16440 Kista",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "09:00",
        dropoffTime: "11:00",
    },
    {
        from: "Regeringsgatan 48, 11156 STOCKHOLM",
        to: "Sturegatan 14, 11436 Östermalm",
        tag: "DirektPanik",
        pickupTime: "11:00",
    },
    {
        from: "Birger Jarlsgatan 43, 4tr, 11145 Stockholm",
        to: "Sibyllegatan 15, 11442 Östermalm",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "15:30",
    },
    {
        from: "Karlaplan 10, 11520 STOCKHOLM",
        to: "Grev Turegatan 1, 11446 Östermalm",
        tag: "DirektPanik",
        pickupTime: "15:00",
    },
    {
        from: "Hornsgatan 79, 1tr, 11849 Stockholm",
        to: "Grev Turegatan 38, 11438 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Frihamnsgatan 28, 11556 Stockholm",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Panik (1h)",
        pickupTime: "13:00",
        dropoffTime: "15:00",
    },
    {
        from: "Drottninggatan 95 A, 11360 Stockholm",
        to: "Storskärsgatan 5, 11529 Gärdet",
        tag: "DirektPanik",
        pickupTime: "10:03",
    },
    {
        from: "Sibyllegatan 2, 11451 Stockholm",
        to: "Banérgatan 16, 11522 Östermalm",
        tag: "DirektPanik",
        pickupTime: "08:00",
    },
    {
        from: "Solnavägen 3 H, 2tr, 11363 Stockholm",
        to: "Norra Bankogränd 2, 11130 Skeppsbron",
        tag: "Dags Förbokad",
        pickupTime: "10:00",
        dropoffTime: "17:00",
    },
    {
        from: "Regeringsgatan 29, 11153 Stockholm",
        to: "Evenemangsgatan 17, 3tr, 16956 Solna",
        tag: "Panik (1h)",
        pickupTime: "13:00",
        dropoffTime: "15:00",
    },
    {
        from: "Grev Turegatan 1, 11446 Stockholm",
        to: "Råsundavägen 167, 4tr, 16936 Solna",
        tag: "Dags Förbokad",
        pickupTime: "10:00",
        dropoffTime: "16:00",
    },
    {
        from: "Humlegårdsgatan 13, 11446 Stockholm",
        to: "Rindögatan 40, 11558 Gärdet",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fiskargatan 8, 11620 Stockholm",
        to: "Bondegatan 48, 11633 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "12:00",
        dropoffTime: "15:00",
    },
    {
        from: "Östermalmsgatan 87D, 11459 Stockholm",
        to: "Nybrogatan 3, 11434 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Döbelnsgatan 8, 11140 Stockholm",
        to: "Kungsgatan 30, 14 tr, 11135 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "13:00",
    },
    {
        from: "Blasieholmsgatan 4 A, 11148 Stockholm",
        to: "Paradisvägen 8, 17831 Ekerö",
        tag: "Dags Förbokad",
        pickupTime: "09:30",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsträdgårdsgatan 18, 11147 Stockholm",
        to: "Jakobsgatan 6, 11152 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "16:45",
    },
    {
        from: "Sandhamnsgatan 73, 11528 Stockholm",
        to: "Malmskillnadsgatan 44a, 11157 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "14:30",
        dropoffTime: "15:30",
    },
    {
        from: "Östermalmsgatan 87D, 11459 Stockholm",
        to: "Åkerbyvägen 134, 18737 Täby Östra",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Lästmakaregatan 23, 11122 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 84, 11227 Stockholm",
        to: "Bergtallsvägen 24, 12560 Älvsjö",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Hornsbruksgatan 23B, 11734 Stockholm",
        to: "Spelmansvägen 14, 13675 Vendelsö",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 84, 11227 Stockholm",
        to: "Eva Bonniers gata 2, 12954 Fruängen",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Flygfältsgatan 15, 12830 Skarpnäck",
        to: "Nackagatan 4 1tr, 11641 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "16:00",
    },
    {
        from: "Kungsgatan 84, 11227 Stockholm",
        to: "Genvägen 7, 13338 Saltsjöbaden",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Terapivägen 18C, 14156 Huddinge",
        to: "Hammarby Kaj 10D, 6tr, 12032 Hammarby Sjöstad",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 84, 11227 Stockholm",
        to: "Eva Bonniers Gata 17, 12954 Fruängen",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 84, 11227 Stockholm",
        to: "Hammarbacken 1, 18235 Danderyd",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 84, 11227 Stockholm",
        to: "Vidarvägen 3, 18267 Djursholm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 84, 11227 Stockholm",
        to: "Ripvägen 4, 13941 Värmdö",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 84, 11227 Stockholm",
        to: "Örnbacken 28, 12651 Aspudden",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 84, 11227 Stockholm",
        to: "Vävar Johans Gata 32, 12070 Hammarby Sjöstad",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 84, 11227 Stockholm",
        to: "Idunvägen 30, 13338 Saltsjöbaden",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Drottninggatan 53, 11121 Stockholm",
        to: "Grev Turegatan 30 vån 3, 11438 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "10:00",
        dropoffTime: "17:00",
    },
    {
        from: "Magasin 5, 11538 STOCKHOLM",
        to: "Magasin 1, 11538 Gärdet",
        tag: "Fast Körning DP",
        pickupTime: "15:45",
        dropoffTime: "16:15",
    },
    {
        from: "Karlavägen 102, 11526 STOCKHOLM",
        to: "Magasin 1, 11538 Gärdet",
        tag: "Fast Körning DP",
        pickupTime: "09:30",
        dropoffTime: "09:45",
    },
    {
        from: "Magasin 1, 11538 Stockholm",
        to: "Karlavägen 102, 11526 Östermalm",
        tag: "Fast Körning DP",
        pickupTime: "16:00",
        dropoffTime: "17:30",
    },
    {
        from: "Magasin 1, 11538 Stockholm",
        to: "Magasin 5, 11538 Gärdet",
        tag: "Fast Körning DP",
        pickupTime: "09:30",
        dropoffTime: "10:00",
    },
    {
        from: "Vasagatan 16, 11120 Stockholm",
        to: "Regeringsgatan 65, 11139 Norrmalm",
        tag: "Fast Körning DP",
        pickupTime: "16:30",
        dropoffTime: "18:00",
    },
    {
        from: "Karlavägen 102, 11526 STOCKHOLM",
        to: "Sandhamnsgatan 63C, 1tr, 11528 Gärdet",
        tag: "Fast körning",
        pickupTime: "16:00",
        dropoffTime: "17:00",
    },
    {
        from: "Gustavslundsvägen 26, 16751 Bromma",
        to: "Regeringsgatan 65, 11139 Norrmalm",
        tag: "Fast Körning DP",
        pickupTime: "16:30",
        dropoffTime: "18:00",
    },
    {
        from: "Floragatan 13, 11431 Stockholm",
        to: "Järlavägen 23, 13141 Nacka C",
        tag: "Fast körning",
        pickupTime: "14:00",
        dropoffTime: "17:00",
    },
    {
        from: "Dalagatan 100, 9tr, 11343 Stockholm",
        to: "Frejgatan 103B, 11326 Vasastan",
        tag: "Tretimmars",
        pickupTime: "14:00",
        dropoffTime: "17:00",
    },
    {
        from: "Riddargatan 15, 11457 Stockholm",
        to: "Strandvägen 7A,2tr, 11456 Östermalm",
        tag: "Fast Körning DP",
        pickupTime: "07:30",
        dropoffTime: "08:15",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Västgötagatan 2, 3tr, 11624 Södermalm Ö",
        tag: "Fast körning",
        pickupTime: "08:30",
        dropoffTime: "11:30",
    },
    {
        from: "Valhallavägen 174, 11527 Stockholm",
        to: "Frihamnsgatan 28, 11556 Gärdet",
        tag: "DirektPanik",
        pickupTime: "08:20",
    },
    {
        from: "Regeringsgatan 65, 11139 STOCKHOLM",
        to: "Tullvaktsvägen 19, 11556 Gärdet",
        tag: "Fast Körning DP",
        pickupTime: "08:30",
        dropoffTime: "09:00",
    },
    {
        from: "Fleminggatan 18, 11226 Stockholm",
        to: "Regeringsgatan 65, 11139 Norrmalm",
        tag: "Fast Körning DP",
        pickupTime: "08:30",
        dropoffTime: "09:00",
    },
    {
        from: "Humlegårdsgatan 9, 11446 Stockholm",
        to: "Kungsgatan 12, 11135 Norrmalm",
        tag: "Fast Körning DP",
        pickupTime: "08:00",
    },
    {
        from: "Sandhamnsgatan 63C, 1tr, 11528 Stockholm",
        to: "Karlavägen 102, 11526 Östermalm",
        tag: "Fast körning",
        pickupTime: "16:00",
        dropoffTime: "17:00",
    },
    {
        from: "Frejgatan 46A, 11326 Stockholm",
        to: "Klarabergsviadukten 70 A 6th Floor, 11164 Norrmalm",
        tag: "Fast Körning DP",
        pickupTime: "07:30",
    },
    {
        from: "Tullvaktsvägen 19, 11556 Stockholm",
        to: "Regeringsgatan 65, 11139 Norrmalm",
        tag: "Fast Körning DP",
        pickupTime: "17:00",
        dropoffTime: "18:00",
    },
    {
        from: "Borgvägen 3, 11553 Stockholm",
        to: "Magasin 1, 11528 Gärdet",
        tag: "Fast Körning DP",
        pickupTime: "11:30",
    },
    {
        from: "Gustavslundsvägen 26, 16751 Bromma",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Fast Körning DP",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Gustavslundsvägen 159, 16751 Bromma",
        to: "Gustavslundsvägen 26, 16751 Alvik",
        tag: "Fast Körning DP",
        pickupTime: "09:00",
        dropoffTime: "09:20",
    },
    {
        from: "Regeringsgatan 65, 11139 STOCKHOLM",
        to: "Tullvaktsvägen 19, 11556 Gärdet",
        tag: "Fast Körning DP",
        pickupTime: "08:30",
        dropoffTime: "09:00",
    },
    {
        from: "Centralplan 15, 11120 Stockholm",
        to: "Nybrokajen 7, 11148 Norrmalm",
        tag: "Fast Körning DP",
        pickupTime: "10:30",
    },
    {
        from: "Gustav III:s Boulevard 54, 16973 Solna",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Fast Körning DP",
        pickupTime: "14:00",
        dropoffTime: "17:45",
    },
    {
        from: "Torshamnsgatan 48, 16440 Kista",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Fast Körning DP",
        pickupTime: "15:30",
    },
    {
        from: "Frejgatan 46A, 11326 Stockholm",
        to: "Eugeniavägen 18, 6tr, 11368 Vasastan",
        tag: "Fast Körning DP",
        pickupTime: "07:00",
        dropoffTime: "07:15",
    },
    {
        from: "Klara Östra Kyrkogata 4, 11152 Stockholm",
        to: "Klara Östra Kyrkogata 13, 11152 Norrmalm",
        tag: "Fast Körning DP",
        pickupTime: "11:00",
    },
    {
        from: "Regeringsgatan 65, 11139 STOCKHOLM",
        to: "Fleminggatan 18, 11226 Kungsholmen",
        tag: "Fast Körning DP",
        pickupTime: "08:30",
        dropoffTime: "09:00",
    },
    {
        from: "Karlavägen 102, 11526 STOCKHOLM",
        to: "Telegrafgatan 4, 3tr, 16972 Solna",
        tag: "Fast körning",
        pickupTime: "08:30",
        dropoffTime: "10:00",
    },
    {
        from: "Telegrafgatan 4, 3tr, 16972 Solna",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Fast körning",
        pickupTime: "16:00",
        dropoffTime: "17:00",
    },
    {
        from: "Vasagatan 28, 11120 Stockholm",
        to: "Regeringsgatan 65, 11139 Norrmalm",
        tag: "Fast Körning DP",
        pickupTime: "16:30",
        dropoffTime: "18:00",
    },
    {
        from: "Fleminggatan 18, 11226 Stockholm",
        to: "Regeringsgatan 65, 11139 Norrmalm",
        tag: "Fast Körning DP",
        pickupTime: "16:00",
        dropoffTime: "18:00",
    },
    {
        from: "Linnégatan 1, 11447 Stockholm",
        to: "Kronåsvägen 2-4, 19146 Sollentuna",
        tag: "Fast Körning DP",
        pickupTime: "07:30",
    },
    {
        from: "Nybrogatan 25-27, 11439 Stockholm",
        to: "Strandvägen 7 C, 11456 Östermalm",
        tag: "Fast Körning DP",
        pickupTime: "06:15",
    },
    {
        from: "Renstiernas gata 20, 11631 Stockholm",
        to: "Regeringsgatan 65, 11156 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "13:00",
    },
    {
        from: "Österlånggatan 43, 11131 Stockholm",
        to: "Lindhagensgatan 74, 11218 Marieberg",
        tag: "Dags",
        pickupTime: "09:00",
        dropoffTime: "16:30",
    },
    {
        from: "Gustavslundsvägen 159, 16751 BROMMA",
        to: "Gävlegatan 22, 11330 Vasastan",
        tag: "Tretimmars",
        pickupTime: "14:00",
        dropoffTime: "17:00",
    },
    {
        from: "Regeringsgatan 98, 11139 Stockholm",
        to: "Tegnérgatan 1, 11140 Norrmalm",
        tag: "Dags",
        pickupTime: "09:00",
        dropoffTime: "16:30",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Rådmansgatan 35, 11358 Vasastan",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Skytteholmsvägen 2, 17144 Solna",
        to: "Birger Jarlsgatan 27, 4tr, 11145 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Banérgatan 10, 11523 Stockholm",
        to: "Dalvägen 12, 16970 Solna",
        tag: "Dags",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Birger Jarlsgatan 43, 4tr, 11145 Stockholm",
        to: "Lilla Nygatan 5., 11128 Gamla stan V",
        tag: "Tretimmars",
        pickupTime: "11:00",
        dropoffTime: "14:00",
    },
    {
        from: "Birger Jarlsgatan 43, 4tr, 11145 Stockholm",
        to: "Kvarnholmsvägen 91, 13131 Kvarnholmen",
        tag: "Tretimmars",
        pickupTime: "09:00",
        dropoffTime: "12:00",
    },
    {
        from: "Birger Jarlsgatan 43, 4tr, 11145 Stockholm",
        to: "Vikdalsvägen 50, 13140 Nacka C",
        tag: "Dags",
        pickupTime: "09:00",
        dropoffTime: "16:00",
    },
    {
        from: "Arsenalsgatan 4, 11147 Stockholm",
        to: "Tunvägen 7, 17068 Sundbyberg",
        tag: "Dags Förbokad",
        pickupTime: "10:00",
        dropoffTime: "17:00",
    },
    {
        from: "Malmskillnadsgatan 19, 11157 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "DirektPanik",
        pickupTime: "09:30",
    },
    {
        from: "Tulegatan 11, 11353 Stockholm",
        to: "Alviksvägen 19, 16753 Bromma",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Birger Jarlsgatan 43, 4tr, 11145 Stockholm",
        to: "Brovägen 51, 18276 Danderyd",
        tag: "Dags",
        pickupTime: "09:00",
        dropoffTime: "16:00",
    },
    {
        from: "Fiskargatan 8, 11620 Stockholm",
        to: "Signalstigen 3, 19134 Sollentuna",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Regeringsgatan 58, 11156 Stockholm",
        to: "Klarabergsviadukten 70, 11164 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "11:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Skebokvarnsvägen 167, 12452 Högdalen",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Englundavägen 9, 17141 Solna",
        tag: "Panik (1h)",
        pickupTime: "12:30",
        dropoffTime: "14:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Eva Bonniers gata 2, 12954 Fruängen",
        tag: "Dags Förbokad",
        pickupTime: "08:30",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Tjärhovsgatan 40, 1tr, 11628 Södermalm Ö",
        tag: "Dags Förbokad",
        pickupTime: "08:30",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Jakobsbergsgatan 18, 8tr, 11144 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "08:30",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Dalagatan 100, 11343 Vasastan",
        tag: "Dags Förbokad",
        pickupTime: "08:30",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Blekholmsgatan 18, 11164 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "08:30",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 3, 3tr, 11143 Stockholm",
        to: "Gräsandsgatan 21, 13341 Fisksätra",
        tag: "Panik (1h)",
        pickupTime: "09:00",
        dropoffTime: "10:30",
    },
    {
        from: "Gräsandsgatan 21, 13341 Saltsjöbaden",
        to: "Kungsgatan 3, 3tr, 11143 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "09:00",
        dropoffTime: "12:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Skeppargatan 37, 11452 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "08:30",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Brunkebergsåsen 22, 19266 Norrviken",
        tag: "Dags Förbokad",
        pickupTime: "08:30",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63, 11528 Stockholm",
        to: "Rubingatan 2, 76150 Norrtälje",
        tag: "Tretimmars",
        pickupTime: "08:00",
        dropoffTime: "11:00",
    },
    {
        from: "Sveavägen 159, 11346 Stockholm",
        to: "Torshamnsgatan 21, 16440 Kista",
        tag: "Dags",
        pickupTime: "08:00",
        dropoffTime: "17:00",
    },
    {
        from: "Medevigatan 5, 1 tr, 11361 Stockholm",
        to: "Hästholmsvägen 17, 11644 Henriksdal",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Skeppsbron 28, 11130 Stockholm",
        to: "Gävlegatan 22, 11330 Vasastan",
        tag: "DirektPanik",
        pickupTime: "08:30",
        dropoffTime: "09:00",
    },
    {
        from: "Vasagatan 14A, 17266 Sundbyberg",
        to: "Tegeluddsvägen 31A, 11541 Gärdet",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Kammakargatan 48, 11160 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Vasagatan 14A, 17266 Sundbyberg",
        to: "Katarinavägen 9, 7tr, 11645 Södermalm Ö",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Fiskargatan 8, 11620 Södermalm Ö",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Brunnsgatan 21, 11138 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 73, 11528 Stockholm",
        to: "Kungsbro strand 29, 11226 Kungsholmen",
        tag: "Panik (1h)",
        pickupTime: "13:30",
        dropoffTime: "14:30",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Drottninggatan 95A, 11360 Vasastan",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Artillerigatan 16, 11456 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Lumaparksvägen 9, 12031 Hammarby Sjöstad",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Frejgatan 32, 11326 Vasastan",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Gamla Brogatan 26, 2tr, 11120 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Lästmakargatan 10, 11144 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Sturegatan 54, 11436 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Solna Torg 19, 4tr, 17145 Solna",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "09:00",
        dropoffTime: "11:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Ludvigsbergsgatan 20, 11823 Södermalm C",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Linnégatan 87A, 11523 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Drottninggatan 82, 11136 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Industrigatan 4C, 11246 Kungsholmen",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Valhallavägen 118, 11441 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 73, 11528 Stockholm",
        to: "Drottninggatan 57, 11121 Norrmalm",
        tag: "Dags",
        pickupTime: "08:00",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Tomtebodavägen 12A, 17165 Karolinska",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Karlavägen 72, 11449 STOCKHOLM",
        to: "Rådmansgatan 3, 11425 Östermalm",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "20:00",
    },
    {
        from: "Gävlegatan 22, 11330 Stockholm",
        to: "Storkvägen 8, 18156 Lidingö Västra",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Karlavägen 72, 11449 STOCKHOLM",
        to: "Generalsvägen 75, 18451 Åkersberga",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "20:00",
    },
    {
        from: "Karlavägen 72, 11449 STOCKHOLM",
        to: "Valhallavägen 143, 11531 Gärdet",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "20:00",
    },
    {
        from: "Vasagatan 14A, 17266 Sundbyberg",
        to: "Artillerigatan 50, 11445 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Karlavägen 72, 11449 STOCKHOLM",
        to: "Sabelvägen 25B, 18460 Åkersberga",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "20:00",
    },
    {
        from: "Karlavägen 72, 11449 STOCKHOLM",
        to: "Kapellvägen 26, 13146 Nacka Östra",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "20:00",
    },
    {
        from: "Nybrogatan 8, 11434 Stockholm",
        to: "Domnarvsgatan 11, 16353 Spånga",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Karlavägen 72, 11449 STOCKHOLM",
        to: "Muskötvägen 14, 18460 Åkersberga",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "20:00",
    },
    {
        from: "Blasieholmsgatan 4 A, 11148 Stockholm",
        to: "Sankt Eriksgatan 48F, 11234 Kungsholmen",
        tag: "Dags Förbokad",
        pickupTime: "09:30",
        dropoffTime: "17:00",
    },
    {
        from: "Karlavägen 72, 11449 STOCKHOLM",
        to: "Ekbacksvägen 62, 18238 Danderyd",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "20:00",
    },
    {
        from: "Karlavägen 72, 11449 STOCKHOLM",
        to: "Hortensiavägen 82A, 14168 Huddinge",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "20:00",
    },
    {
        from: "Peter Myndes Backe 16, 11846 Stockholm",
        to: "Slåttervallsgatan 10, 11544 Norra Djurgården",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Västmannagatan 1, 11124 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "09:00",
        dropoffTime: "12:00",
    },
    {
        from: "Biblioteksgatan 29, vån 6., 11435 Stockholm",
        to: "Sankt Paulsgatan 21, 11846 Södermalm C",
        tag: "Panik (1h)",
        pickupTime: "14:00",
        dropoffTime: "15:30",
    },
    {
        from: "Peter Myndes Backe 16, 11846 Stockholm",
        to: "Rålambsvägen 16A, 11259 Marieberg",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Peter Myndes Backe 16, 11846 Stockholm",
        to: "Torsvikssvängen 1, 2tr, 18134 Lidingö Västra",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Malmskillnadsgatan 44A, 11157 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "09:00",
        dropoffTime: "11:00",
    },
    {
        from: "Peter Myndes Backe 16, 11846 Stockholm",
        to: "Drottninggatan 83A, 11160 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Peter Myndes Backe 16, 11846 Stockholm",
        to: "Skånegatan 61, 11637 Södermalm Ö",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Peter Myndes Backe 16, 11846 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "09:00",
        dropoffTime: "11:00",
    },
    {
        from: "Artillerigatan 12, 11451 Stockholm",
        to: "Rökerigatan 20, 12162 Johanneshov",
        tag: "Tretimmars",
        pickupTime: "12:00",
        dropoffTime: "17:00",
    },
    {
        from: "Blasieholmsgatan 4 A, 11148 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "09:00",
        dropoffTime: "11:00",
    },
    {
        from: "Kungsgatan 58, 2tr, 11122 Stockholm",
        to: "Oxenstiernsgatan 34, 11527 Gärdet",
        tag: "Tretimmars",
        pickupTime: "11:00",
        dropoffTime: "13:00",
    },
    {
        from: "Sandhamnsgatan 73, 11528 Stockholm",
        to: "Klarabergsviadukten 70, 11164 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "13:30",
        dropoffTime: "14:30",
    },
    {
        from: "Luntmakargatan 65, 11351 STOCKHOLM",
        to: "Tulegatan 13, 11353 Vasastan",
        tag: "DirektPanik",
        pickupTime: "11:30",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Eva Bonniers gata 2, 12954 Fruängen",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 73, 11528 Stockholm",
        to: "Regeringsgatan 28, 11153 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "14:00",
        dropoffTime: "15:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Björkbacksvägen 39, 16869 Ulvsunda",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Ringvägen 53, 13335 Saltsjöbaden",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Sjättenovembervägen 222, 12534 Älvsjö",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Nybrogatan 47, 11439 STOCKHOLM",
        to: "Stureplan 6, 4tr, 11435 Östermalm",
        tag: "DirektPanik",
        pickupTime: "10:10",
    },
    {
        from: "Rosenlundsgatan 36, 11853 Stockholm",
        to: "Lingvägen 91, 12245 Enskede",
        tag: "Tretimmars",
        pickupTime: "09:00",
        dropoffTime: "16:00",
    },
    {
        from: "Biblioteksgatan 6, 11146 Stockholm",
        to: "Biblioteksgatan 12, 11446 Östermalm",
        tag: "DirektPanik",
        pickupTime: "11:30",
    },
    {
        from: "Sandhamnsgatan 73, 11528 Stockholm",
        to: "Kungsgatan 30, 11135 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "13:00",
        dropoffTime: "14:00",
    },
    {
        from: "Valhallavägen 157, 11553 Stockholm",
        to: "Ryan 40, 18694 Vallentuna",
        tag: "Tretimmars",
        pickupTime: "14:00",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Gästrikegatan 7, 2tr, 11362 Vasastan",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Tegnérgatan 51, 11161 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Österlånggatan 43, 11131 Stockholm",
        to: "Alströmergatan 39, 11247 Kungsholmen",
        tag: "Dags",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Riddargatan 60, 11451 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sveavägen 53, 11134 Stockholm",
        to: "Dåntorpsvägen 7, 13650 Jordbro",
        tag: "Dags",
        pickupTime: "08:00",
        dropoffTime: "17:00",
    },
    {
        from: "Kvarnholmsvägen 91, 13131 Nacka",
        to: "Dåntorpsvägen 32, 13650 Jordbro",
        tag: "Dags",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Blasieholmsgatan 4 A, 11148 Stockholm",
        to: "Tegnérgatan 1, 11140 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Blasieholmsgatan 4 A, 11148 Stockholm",
        to: "Hälsingegatan 1, 11323 Vasastan",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Blasieholmsgatan 4 A, 11148 Stockholm",
        to: "Domherrevägen 5, 16771 Bromma Västra",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Solna Strandväg 84,8tr, 17154 Solna",
        to: "Pastellvägen 2, 12136 Johanneshov",
        tag: "DirektPanik",
        pickupTime: "08:45",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Stora Gråmunkegränd 3, 11127 Gamla stan V",
        tag: "Tretimmars",
        pickupTime: "09:00",
        dropoffTime: "12:00",
    },
    {
        from: "Telegrafgatan 4, 16972 Solna",
        to: "Mekanikervägen 10, 14633 Tullinge",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Östermalmsgatan 26A, 11426 Stockholm",
        to: "Evenemangsgatan 17, 16956 Solna",
        tag: "Dags Förbokad",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Drottninggatan 27, 5tr, 11151 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "09:00",
        dropoffTime: "11:00",
    },
    {
        from: "Östermalmsgatan 26A, 11426 Stockholm",
        to: "Sveavägen 167, 11346 Vasastan",
        tag: "Dags Förbokad",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Östermalmsgatan 26A, 11426 Stockholm",
        to: "Svetsarvägen 15, 17141 Solna",
        tag: "Dags Förbokad",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Östermalmsgatan 26A, 11426 Stockholm",
        to: "Västra Järnvägsgatan 7, 11164 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Östermalmsgatan 26A, 11426 Stockholm",
        to: "Slottsallén -, 17079 Ulriksdal",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Östermalmsgatan 26A, 11426 Stockholm",
        to: "Vasagatan 10, 11120 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Östermalmsgatan 26A, 11426 Stockholm",
        to: "Malmskillnadsgatan 36, 11157 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Hornsgatan 114, 11726 Stockholm",
        to: "Frösundaviks Allé 1., 16970 Solna",
        tag: "Dags Förbokad",
        pickupTime: "09:15",
        dropoffTime: "17:00",
    },
    {
        from: "Jakobsbergsgatan 6, 11144 Stockholm",
        to: "Djupdalsvägen 71, 16771 Bromma Västra",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "18:00",
    },
    {
        from: "Västra Trädgårdsgatan 15, 11153 Stockholm",
        to: "Kabyssgatan 4D, 12030 Hammarby Sjöstad",
        tag: "DirektPanik",
        pickupTime: "08:00",
    },
    {
        from: "Stureplan 3, 11145 Stockholm",
        to: "Torstenssonsgatan 7, 3tr, 11456 Östermalm",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Sveavägen 159, 11346 Vasastan",
        tag: "Tretimmars",
        pickupTime: "09:00",
        dropoffTime: "12:00",
    },
    {
        from: "Våruddsringen 69, 12741 Skärholmen",
        to: "Hudiksvallsgatan 8, 11330 Vasastan",
        tag: "DirektPanik",
        pickupTime: "09:00",
    },
    {
        from: "Kåkbrinken 10, 11127 STOCKHOLM",
        to: "Katarinavägen 9, 11645 Södermalm Ö",
        tag: "Panik (1h)",
        pickupTime: "13:00",
        dropoffTime: "14:00",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Sjöviksvägen 144, 11757 Marievik",
        tag: "Dags",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Djurgårdsbrunnvägen 55, 11525 Djurgården",
        tag: "Tretimmars",
        pickupTime: "12:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Sveavägen 53, 11359 Vasastan",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Strandvägen 13, 18260 Djursholm",
        tag: "Panik (1h)",
        pickupTime: "08:00",
        dropoffTime: "10:30",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Lästmakargatan 23, 11144 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Sveavägen 33, 11134 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Erik Dahlbergsallén 15, 11520 Stockholm",
        to: "Frejgatan 93, 11326 Vasastan",
        tag: "Tretimmars",
        pickupTime: "09:00",
        dropoffTime: "12:00",
    },
    {
        from: "Birger Jarlsgatan 43, 11145 Stockholm",
        to: "Tomtebogatan 11A, 11339 Vasastan",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Nybrogatan 34, 11439 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "09:00",
        dropoffTime: "11:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Norrlandsgatan 20, 11143 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "09:00",
        dropoffTime: "12:00",
    },
    {
        from: "Jakobsgatan 6, 11152 Stockholm",
        to: "Östanvägen 14, 17771 Kalhäll",
        tag: "Tretimmars",
        pickupTime: "12:00",
        dropoffTime: "15:00",
    },
    {
        from: "Birger Jarlsgatan 43, 11145 Stockholm",
        to: "Ekensbergsvägen 117, 17141 Solna",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Östermalmsgatan A 26, 11426 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "11:00",
        dropoffTime: "13:00",
    },
    {
        from: "Norrlandsgatan 16, 11143 STOCKHOLM",
        to: "Kräftriket 4, 11419 Frescati",
        tag: "Panik (1h)",
        pickupTime: "08:00",
        dropoffTime: "09:30",
    },
    {
        from: "Nybrogatan 12,4tr, 11439 Stockholm",
        to: "Norra Agnegatan 33-37, 11229 Kungsholmen",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Banérgatan 29, 11522 Stockholm",
        to: "Linnégatan 12, 2 tr, 11447 Östermalm",
        tag: "Tretimmars",
        pickupTime: "09:00",
        dropoffTime: "13:00",
    },
    {
        from: "Rosenlundsgatan 58, 11863 Stockholm",
        to: "Norrlandsgatan 16, 11143 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "10:00",
        dropoffTime: "11:00",
    },
    {
        from: "Stormbyvägen 2, 16355 Spånga",
        to: "Nybodagatan 6A, 17142 Solna",
        tag: "Dags",
        pickupTime: "10:00",
        dropoffTime: "17:00",
    },
    {
        from: "Söder Mälarstrand 65,9tr, 11846 STOCKHOLM",
        to: "Brännkyrkagatan 44, 11822 Södermalm C",
        tag: "Tretimmars",
        pickupTime: "09:00",
        dropoffTime: "13:30",
    },
    {
        from: "Brännkyrkagatan 44, 11822 Stockholm",
        to: "Söder Mälarstrand 65,9tr, 11846 Södermalm C",
        tag: "Tretimmars",
        pickupTime: "09:11",
        dropoffTime: "16:30",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Nyckelvägen 3, 14250 Skogås",
        tag: "DirektPanik",
        pickupTime: "07:00",
    },
    {
        from: "Grev Turegatan 20, 11438 STOCKHOLM",
        to: "Odengatan 45, 4tr, 11351 Vasastan",
        tag: "Dags Förbokad",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sjövikskajen 18, 11758 Stockholm",
        to: "Östgötagatan 100, 4tr, 11664 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "12:30",
        dropoffTime: "15:30",
    },
    {
        from: "Banérgatan 30, 11523 Stockholm",
        to: "Barnängsgatan 7, 11649 Södermalm Ö",
        tag: "Panik (1h)",
        pickupTime: "10:00",
        dropoffTime: "11:00",
    },
    {
        from: "Kungsgatan 57, 11122 Stockholm",
        to: "Ollonstigen 15, 13560 Tyresö",
        tag: "Panik (1h)",
        pickupTime: "07:44",
        dropoffTime: "09:14",
    },
    {
        from: "Ängspiren 3, 13562 Tyresö",
        to: "Linnégatan 4, 11447 Östermalm",
        tag: "Panik (1h)",
        pickupTime: "07:46",
    },
    {
        from: "Torsgatan 4, 11123 Stockholm",
        to: "Mäster Samuelsgatan 59-61, 11121 Norrmalm",
        tag: "Dags",
        pickupTime: "08:00",
        dropoffTime: "17:00",
    },
    {
        from: "Drottninggatan 57, 11121 Stockholm",
        to: "Karlavägen 3, 4tr, 11424 Östermalm",
        tag: "DirektPanik",
        pickupTime: "10:00",
    },
    {
        from: "Regeringsgatan 25, 8tr, 11153 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "11:00",
        dropoffTime: "13:00",
    },
    {
        from: "Frösundaleden 2 A, 16970 Solna",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "08:10",
        dropoffTime: "10:10",
    },
    {
        from: "Olofsgatan 12, 11136 Stockholm",
        to: "Lugnets Allé 1, 5tr, 12065 Hammarby Sjöstad",
        tag: "Dags",
        pickupTime: "08:21",
        dropoffTime: "17:00",
    },
    {
        from: "Olofsgatan 12, 11136 Stockholm",
        to: "Karlsbodavägen 9-11, 16867 Ulvsunda",
        tag: "Dags",
        pickupTime: "08:22",
        dropoffTime: "17:00",
    },
    {
        from: "Sveavägen 33, 11134 Stockholm",
        to: "Alstervägen 8, 16839 Bromma Västra",
        tag: "Dags",
        pickupTime: "08:22",
        dropoffTime: "17:00",
    },
    {
        from: "Luntmakargatan 40, 11137 Stockholm",
        to: "Artillerigatan 28, 11451 Östermalm",
        tag: "Dags",
        pickupTime: "08:23",
        dropoffTime: "17:00",
    },
    {
        from: "Stureplan 3, 3 tr, 11145 Stockholm",
        to: "Nämndemansbacken 77, 12838 Skarpnäck",
        tag: "Panik (1h)",
        pickupTime: "09:00",
        dropoffTime: "11:30",
    },
    {
        from: "Nämndemansbacken 77, 12838 Skarpnäck",
        to: "Stureplan 3, 3 tr, 11145 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "09:00",
        dropoffTime: "11:30",
    },
    {
        from: "Frihamnen, Magasin 1, 11538 Stockholm",
        to: "Allévägen 2A, 13242 Björknäs",
        tag: "DirektPanik",
        pickupTime: "08:45",
    },
    {
        from: "Biblioteksgatan 11,2tr, 11146 Stockholm",
        to: "Golfvägen 2, 18231 Danderyd",
        tag: "Panik (1h)",
        pickupTime: "08:32",
        dropoffTime: "10:02",
    },
    {
        from: "Värtavägen 68, 11538 Stockholm",
        to: "Nybrogatan 34, 3tr, 11439 Östermalm",
        tag: "DirektPanik",
        pickupTime: "11:30",
    },
    {
        from: "Frihamnsgatan 28, 11556 Stockholm",
        to: "Gelbgjutarevägen 3, 17148 Solna",
        tag: "Tretimmars",
        pickupTime: "08:40",
        dropoffTime: "11:40",
    },
    {
        from: "Järngatan 40, 19572 Rosersberg",
        to: "Ranhammarsvägen 20, 16867 Ulvsunda",
        tag: "Tretimmars",
        pickupTime: "08:39",
        dropoffTime: "11:39",
    },
    {
        from: "Kungsbron 1, Uppgång G plan 5, 11122 Stockholm",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "08:39",
        dropoffTime: "09:39",
    },
    {
        from: "Kungsbron 1, Uppgång G plan 5, 11122 Stockholm",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "08:46",
        dropoffTime: "09:46",
    },
    {
        from: "Bristagatan 18A, 19560 Arlandastad",
        to: "Klarabergsgatan 50, 11121 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "08:47",
        dropoffTime: "11:47",
    },
    {
        from: "Regeringsgatan 48, 5tr, 11156 Stockholm",
        to: "Västmannagatan 4, 2tr, 11124 Norrmalm",
        tag: "Dags",
        pickupTime: "08:47",
        dropoffTime: "17:00",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Bagartorpsringen 80, 17065 Järva",
        tag: "Dags",
        pickupTime: "08:52",
        dropoffTime: "17:00",
    },
    {
        from: "Telegrafgatan 4, 16972 Solna",
        to: "Hovslagargatan 3, 11148 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "08:52",
        dropoffTime: "11:52",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Cardellgatan 2, 11436 Östermalm",
        tag: "Tretimmars",
        pickupTime: "08:54",
        dropoffTime: "17:00",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Kungsholms Kyrkoplan 6, 11224 Kungsholmen",
        tag: "Tretimmars",
        pickupTime: "08:55",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Cardellgatan 2, 11436 Östermalm",
        tag: "Dags",
        pickupTime: "08:55",
        dropoffTime: "17:00",
    },
    {
        from: "Birger Jarlsgatan 43, 4tr, 11145 Stockholm",
        to: "Ulvsundavägen 187, 16867 Ulvsunda",
        tag: "Tretimmars",
        pickupTime: "08:56",
        dropoffTime: "15:00",
    },
    {
        from: "Sandhamnsgatan 73, 11528 Stockholm",
        to: "Gjörwellsgatan 30, 11260 Marieberg",
        tag: "Dags",
        pickupTime: "08:57",
        dropoffTime: "17:00",
    },
    {
        from: "Karlavägen 102, 11526 Stockholm",
        to: "Söder Mälarstrand 27, 11825 Södermalm C",
        tag: "Tretimmars",
        pickupTime: "09:05",
        dropoffTime: "16:00",
    },
    {
        from: "Ynglingagatan 18, 11347 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "08:57",
        dropoffTime: "10:57",
    },
    {
        from: "Styrmansgatan 5, 11454 Stockholm",
        to: "Högmoravägen 11, 14136 Huddinge",
        tag: "Dags",
        pickupTime: "08:57",
        dropoffTime: "17:00",
    },
    {
        from: "Telegrafgatan 4, 16972 Solna",
        to: "Englundavägen 4, 17141 Solna",
        tag: "Dags",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Radiusbacken 1, 12629 Hägersten",
        to: "Sveavägen 84, 11359 Vasastan",
        tag: "Dags",
        pickupTime: "09:03",
        dropoffTime: "17:00",
    },
    {
        from: "Storgatan 10, 11444 Stockholm",
        to: "Tegeluddsvägen 3-5, 11541 Gärdet",
        tag: "Dags",
        pickupTime: "09:03",
        dropoffTime: "17:00",
    },
    {
        from: "Radiusbacken 1, 12629 Hägersten",
        to: "Linnégatan 9, 11447 Östermalm",
        tag: "Dags",
        pickupTime: "09:04",
        dropoffTime: "17:00",
    },
    {
        from: "Stadsgårdshamnen 24, 11645 Stockholm",
        to: "Hökmossevägen 25, 12642 Hägersten",
        tag: "Dags",
        pickupTime: "09:04",
        dropoffTime: "17:00",
    },
    {
        from: "Storgatan 10, 11444 Stockholm",
        to: "finska gatan 32, 12237 Enskede",
        tag: "Dags",
        pickupTime: "09:05",
        dropoffTime: "17:00",
    },
    {
        from: "Pilgatan 5, 11223 Stockholm",
        to: "Centralplan 15, 2 tr, 11120 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "09:07",
        dropoffTime: "12:07",
    },
    {
        from: "Gästrikegatan 7, 2tr, 11362 Stockholm",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Dags Förbokad",
        pickupTime: "09:10",
        dropoffTime: "17:00",
    },
    {
        from: "Trångsund 5, 11129 Stockholm",
        to: "Polhemsgatan 35, 11230 Kungsholmen",
        tag: "Tretimmars",
        pickupTime: "11:00",
        dropoffTime: "16:30",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Gästrikegatan 7, 2tr, 11362 Vasastan",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Östgötagatan 100, 4tr, 11664 Stockholm",
        to: "Kvarnholmsvägen 91, 13131 Kvarnholmen",
        tag: "Panik (1h)",
        pickupTime: "09:14",
        dropoffTime: "10:44",
    },
    {
        from: "Östermalmsgatan 26A, 11426 Stockholm",
        to: "Slottsallén -, 17079 Ulriksdal",
        tag: "Dags Förbokad",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Östermalmsgatan 26A, 11426 Stockholm",
        to: "Vasagatan 10, 11120 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Skånegatan 74, 11637 Stockholm",
        to: "Brahegatan 30, 11437 Östermalm",
        tag: "Dags",
        pickupTime: "09:17",
        dropoffTime: "17:00",
    },
    {
        from: "Handla -, 11440 STOCKHOLM",
        to: "Stadsgårdshamnen 24, 11645 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "09:17",
        dropoffTime: "13:00",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Karlskronavägen 37, 12152 Björkhagen",
        tag: "Panik (1h)",
        pickupTime: "09:18",
        dropoffTime: "11:30",
    },
    {
        from: "Odengatan 27, 11351 Stockholm",
        to: "Stadsgårdshamnen 24, 11645 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "09:19",
        dropoffTime: "13:00",
    },
    {
        from: "Klarafaret 4, 11152 Stockholm",
        to: "Plåtslagarvägen 12, 16831 Bromma",
        tag: "Tretimmars",
        pickupTime: "14:00",
        dropoffTime: "17:00",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Hornsbruksgatan 28, 11734 Södermalm V",
        tag: "Dags",
        pickupTime: "09:21",
        dropoffTime: "16:00",
    },
    {
        from: "Norrmalmstorg 2-4, 11146 Stockholm",
        to: "Floragatan 13, 11431 Östermalm",
        tag: "Dags",
        pickupTime: "09:22",
        dropoffTime: "17:00",
    },
    {
        from: "Kronobergsgatan 9, 11238 Stockholm",
        to: "Söder Mälarstrand 65,9tr, 11846 Södermalm C",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Medevigatan 5, 1 tr, 11361 Stockholm",
        to: "Hästholmsvägen 17, 11644 Henriksdal",
        tag: "Dags",
        pickupTime: "10:00",
        dropoffTime: "17:00",
    },
    {
        from: "Teknologgatan 9A, 4tr, 11360 Stockholm",
        to: "Kungsholmsgatan 40, 11227 Kungsholmen",
        tag: "Panik (1h)",
        pickupTime: "09:24",
        dropoffTime: "10:24",
    },
    {
        from: "Sveavägen 33, 11134 Stockholm",
        to: "Smedjegatan 2C, 13154 Sickla",
        tag: "Dags",
        pickupTime: "09:25",
        dropoffTime: "17:00",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Nanna Svartz väg 6a, 17165 Karolinska",
        tag: "Dags",
        pickupTime: "09:25",
        dropoffTime: "16:00",
    },
    {
        from: "Blåfjällsgatan 4, 16440 Kista",
        to: "Sveavägen 159, 11346 Vasastan",
        tag: "Dags",
        pickupTime: "09:26",
        dropoffTime: "17:00",
    },
    {
        from: "Hamngatan 13 4tr, 11147 Stockholm",
        to: "Smålandsgatan 20, 11146 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "09:27",
        dropoffTime: "12:27",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Domnarvsgatan 4, 16353 Spånga",
        tag: "Dags",
        pickupTime: "09:27",
        dropoffTime: "16:00",
    },
    {
        from: "Olivecronas Väg 8, 11361 Stockholm",
        to: "Frösundaviks Allé 1., 16970 Solna",
        tag: "DirektPanik",
        pickupTime: "10:15",
    },
    {
        from: "Luntmakargatan 40, 11137 Stockholm",
        to: "Birger Jarlsgatan 69, 11356 Vasastan",
        tag: "Tretimmars",
        pickupTime: "09:28",
        dropoffTime: "14:00",
    },
    {
        from: "Brunkebergstorg 5, 11151 Stockholm",
        to: "Vattenkraftsvägen 11D, 13570 Trollbäcken",
        tag: "Dags",
        pickupTime: "09:30",
        dropoffTime: "17:00",
    },
    {
        from: "Västmannagatan 1, 11124 Stockholm",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Tretimmars",
        pickupTime: "09:30",
        dropoffTime: "12:30",
    },
    {
        from: "Hornsgatan 49, 11849 Stockholm",
        to: "Hagagatan 6, 11348 Vasastan",
        tag: "DirektPanik",
        pickupTime: "09:31",
    },
    {
        from: "Linnégatan 26, 3tr, 11447 Stockholm",
        to: "Friluftsvägen 5B, 17240 Sundbyberg",
        tag: "Dags",
        pickupTime: "09:31",
        dropoffTime: "17:00",
    },
    {
        from: "Sturegatan 11, 11436 Stockholm",
        to: "Frösundaviks Allé 1., 16970 Solna",
        tag: "Dags",
        pickupTime: "09:32",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63A, 11528 Stockholm",
        to: "Stensätravägen 13, 12739 Skärholmen",
        tag: "Panik (1h)",
        pickupTime: "12:00",
        dropoffTime: "13:30",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Matilda Jungstedts väg 7, 12133 Enskede",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "13:00",
    },
    {
        from: "Rosenlundsgatan 50, 4tr, 11863 Stockholm",
        to: "Björkvägen 14, 18132 Lidingö Västra",
        tag: "Dags",
        pickupTime: "09:33",
        dropoffTime: "17:00",
    },
    {
        from: "Stensätravägen 13, 12739 Skärholmen",
        to: "Sandhamnsgatan 63A, 11528 Gärdet",
        tag: "Panik (1h)",
        pickupTime: "12:00",
        dropoffTime: "15:00",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Narvavägen 22, 11460 Östermalm",
        tag: "Tretimmars",
        pickupTime: "09:30",
        dropoffTime: "12:30",
    },
    {
        from: "Stadsgårdshamnen 24, 11645 Stockholm",
        to: "Kaprifolvägen 42, 16559 Hässelby",
        tag: "Panik (1h)",
        pickupTime: "09:35",
        dropoffTime: "12:00",
    },
    {
        from: "Sveavägen 159, 11346 Stockholm",
        to: "Regeringsgatan 29, 11139 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "13:00",
        dropoffTime: "15:00",
    },
    {
        from: "Regeringsgatan 29, 11139 Stockholm",
        to: "Sveavägen 159, 11346 Vasastan",
        tag: "Panik (1h)",
        pickupTime: "13:00",
        dropoffTime: "15:00",
    },
    {
        from: "Regeringsgatan 25, 9tr, 11135 Stockholm",
        to: "Alströmergatan 14, 11247 Kungsholmen",
        tag: "Dags",
        pickupTime: "09:37",
        dropoffTime: "17:00",
    },
    {
        from: "Regeringsgatan 61A, butiksingång., 11156 Stockholm",
        to: "Sturevägen 7i,, 18273 Stocksund",
        tag: "Dags",
        pickupTime: "09:38",
        dropoffTime: "17:00",
    },
    {
        from: "Vretensborgsvägen 16, 12630 Hägersten",
        to: "Sturegatan 11, 11436 Östermalm",
        tag: "Dags",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Hammarby Kaj 10D, 6tr, 12032 Stockholm",
        to: "Dalbobranten 31, 12868 Sköndal",
        tag: "Dags",
        pickupTime: "09:39",
        dropoffTime: "17:00",
    },
    {
        from: "Fleminggatan 14, 11226 Stockholm",
        to: "Blekholmstorget 20, 11164 Norrmalm",
        tag: "Dags",
        pickupTime: "09:41",
        dropoffTime: "17:00",
    },
    {
        from: "Rosenlundsgatan 50, 4tr, 11863 Stockholm",
        to: "Gamla Tyresövägen 351 D, 12134 Enskede",
        tag: "Dags",
        pickupTime: "09:42",
        dropoffTime: "17:00",
    },
    {
        from: "Erik Dahlbergsallén 15, 11520 Stockholm",
        to: "Mäster Samuelsgatan 11, 11144 Norrmalm",
        tag: "Dags",
        pickupTime: "09:43",
        dropoffTime: "17:00",
    },
    {
        from: "Torsgatan 20, 11123 Stockholm",
        to: "Lilla Bantorget 15, 11123 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "13:00",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Berzelli Park 9, 11147 Norrmalm",
        tag: "Dags",
        pickupTime: "09:41",
        dropoffTime: "16:00",
    },
    {
        from: "Sveavägen 53, 11359 Stockholm",
        to: "Klara Östra Kyrkogata 12, 11152 Norrmalm",
        tag: "Dags",
        pickupTime: "09:44",
        dropoffTime: "17:00",
    },
    {
        from: "Rådmansgatan 72, 11360 Stockholm",
        to: "Högbergsgatan 32A, 11620 Södermalm Ö",
        tag: "Panik (1h)",
        pickupTime: "09:44",
        dropoffTime: "12:00",
    },
    {
        from: "Sturegatan 10, 11436 Stockholm",
        to: "Nyponvägen 14, 18539 Vaxholm",
        tag: "Dags",
        pickupTime: "09:45",
        dropoffTime: "17:00",
    },
    {
        from: "Luntmakargatan 40, 11137 Stockholm",
        to: "Grev Turegatan 23, 11438 Östermalm",
        tag: "Dags",
        pickupTime: "09:46",
        dropoffTime: "17:00",
    },
    {
        from: "Karlavägen 108, 11526 Stockholm",
        to: "Kabyssgatan 4D, 12030 Hammarby Sjöstad",
        tag: "Dags",
        pickupTime: "09:46",
        dropoffTime: "17:00",
    },
    {
        from: "Augustendalsvägen 20, 1 tr, 13152 Nacka Strand",
        to: "Munkbron 11, 2 tr, 11128 Gamla stan V",
        tag: "DirektPanik",
        pickupTime: "09:44",
    },
    {
        from: "Birger Jarlsgatan 43, 4tr, 11145 Stockholm",
        to: "Boktryckarvägen 14, 12933 Hägersten",
        tag: "Dags",
        pickupTime: "09:48",
        dropoffTime: "16:00",
    },
    {
        from: "Odengatan 81, 2tr, 11322 Stockholm",
        to: "Skeppargatan 92, 11455 Östermalm",
        tag: "Tretimmars",
        pickupTime: "09:51",
        dropoffTime: "12:51",
    },
    {
        from: "Birger Jarlsgatan 43, 4tr, 11145 Stockholm",
        to: "Ulvsundavägen 187, 16867 Ulvsunda",
        tag: "Tretimmars",
        pickupTime: "09:51",
        dropoffTime: "15:00",
    },
    {
        from: "Banérgatan 16, 11522 Stockholm",
        to: "Vikinghillsvägen 44H, 13237 Kummelnäs Norra",
        tag: "DirektPanik",
        pickupTime: "09:51",
    },
    {
        from: "Regeringsgatan 25,9tr, 11135 Stockholm",
        to: "Svetsarvägen 24, 17141 Solna",
        tag: "DirektPanik",
        pickupTime: "09:52",
    },
    {
        from: "Sicklastråket 13, 13155 Nacka",
        to: "Frihamnsgatan 28, 11556 Gärdet",
        tag: "DirektPanik",
        pickupTime: "11:00",
        dropoffTime: "12:00",
    },
    {
        from: "Radiusbacken 1, 12629 Hägersten",
        to: "Sveavägen 84, 11359 Vasastan",
        tag: "Dags",
        pickupTime: "09:54",
        dropoffTime: "17:00",
    },
    {
        from: "Birger Jarlsgatan 43, 4tr, 11145 Stockholm",
        to: "Gröndalsvägen 159, 11769 Gröndal",
        tag: "Dags",
        pickupTime: "09:55",
        dropoffTime: "16:00",
    },
    {
        from: "Sveavägen 159, 11346 Stockholm",
        to: "VAGNMAKARVÄGEN 1, 15132 Södertälje",
        tag: "Dags",
        pickupTime: "09:56",
        dropoffTime: "17:00",
    },
    {
        from: "Frösundaviks Allé 1, 16970 Solna",
        to: "Döbelnsgatan 50, 11352 Vasastan",
        tag: "Tretimmars",
        pickupTime: "09:57",
        dropoffTime: "12:57",
    },
    {
        from: "Kungsbro Strand 17, 11226 Stockholm",
        to: "Bruttovägen 11, 17543 Jakobsberg",
        tag: "Tretimmars",
        pickupTime: "09:56",
        dropoffTime: "12:56",
    },
    {
        from: "Västmannagatan 14, 11124 Stockholm",
        to: "Vikingavägen 57, 18770 Täby Kyrkby",
        tag: "Dags",
        pickupTime: "09:59",
        dropoffTime: "17:00",
    },
    {
        from: "Västmannagatan 14, 11124 Stockholm",
        to: "Solhemsvägen 42, 16354 Spånga",
        tag: "Dags",
        pickupTime: "09:59",
        dropoffTime: "17:00",
    },
    {
        from: "Adlerbethsgatan 19,, 11255 Stockholm",
        to: "Engelbrektsgatan 3, 2tr, 11432 Östermalm",
        tag: "Dags",
        pickupTime: "09:59",
        dropoffTime: "17:00",
    },
    {
        from: "Västmannagatan 14, 11124 Stockholm",
        to: "Långsjövägen 110, 12551 Långsjö",
        tag: "Dags",
        pickupTime: "09:59",
        dropoffTime: "17:00",
    },
    {
        from: "Rosendalsvägen 27, 11521 Stockholm",
        to: "Skogsvägen 2, 12262 Enskede",
        tag: "DirektPanik",
        pickupTime: "15:45",
    },
    {
        from: "Västmannagatan 14, 11124 Stockholm",
        to: "Vendevägen 9, 13237 Kummelnäs Norra",
        tag: "Dags",
        pickupTime: "10:00",
        dropoffTime: "17:00",
    },
    {
        from: "Humlegårdsgatan 5, våning 3, 11446 Stockholm",
        to: "Gösta Ekmans Väg 25C, 13236 Saltsjö Boo",
        tag: "Dags",
        pickupTime: "10:01",
        dropoffTime: "17:00",
    },
    {
        from: "Blendagränd 3, 18777 Täby",
        to: "Banérgatan 12, 11523 Östermalm",
        tag: "Dags",
        pickupTime: "10:00",
        dropoffTime: "17:00",
    },
    {
        from: "Cedergrensvägen 27, 12636 Hägersten",
        to: "Birger Jarls Torg 2A, 11128 Gamla stan V",
        tag: "Tretimmars",
        pickupTime: "10:00",
        dropoffTime: "13:00",
    },
    {
        from: "Klarabergsviadukten 63, 11164 Stockholm",
        to: "Tullvaktsvägen 15, 11556 Gärdet",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Birger Jarlsgatan 43, 4tr, 11145 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "10:05",
        dropoffTime: "11:05",
    },
    {
        from: "Sibyllegatan 2, 11451 Stockholm",
        to: "Gustav Adolfs Torg 18, 11152 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "11:15",
        dropoffTime: "11:45",
    },
    {
        from: "Karlavägen 104, 3tr, 11526 Stockholm",
        to: "Inedalsgatan 6/5tr, 11233 Kungsholmen",
        tag: "Dags",
        pickupTime: "10:08",
        dropoffTime: "17:00",
    },
    {
        from: "Nybrogatan 16, 11439 Stockholm",
        to: "Grev Turegatan 19, 2 tr, 11438 Östermalm",
        tag: "DirektPanik",
        pickupTime: "11:15",
    },
    {
        from: "Handla -, 11440 Stockholm",
        to: "Fiskargatan 8, 11620 Södermalm Ö",
        tag: "DirektPanik",
        pickupTime: "10:10",
    },
    {
        from: "Slåttervägen 20, 17067 Solna",
        to: "Smedjegatan 22, 13154 Sickla",
        tag: "Dags",
        pickupTime: "10:13",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 33, 8tr, 11135 Stockholm",
        to: "Götgatan 40, 11826 Södermalm C",
        tag: "Dags",
        pickupTime: "10:13",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Birger Jarlsgatan 37, 11145 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Stadsgården 6, 11645 Stockholm",
        to: "Lilla Bantorget 9,, 11123 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "10:13",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Norrbackagatan 27A, 4tr, 11334 Vasastan",
        tag: "Panik (1h)",
        pickupTime: "14:00",
        dropoffTime: "16:00",
    },
    {
        from: "Luntmakargatan 40, 11137 Stockholm",
        to: "Arsenalsgatan 3, 11147 Norrmalm",
        tag: "Dags",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sveavägen 159, 11346 Stockholm",
        to: "Örby Slottsväg 26, 12571 Älvsjö",
        tag: "Panik (1h)",
        pickupTime: "13:30",
        dropoffTime: "15:00",
    },
    {
        from: "Örby Slottsväg 26, 12571 ÄLVSJÖ",
        to: "Sveavägen 159, 11346 Vasastan",
        tag: "DirektPanik",
        pickupTime: "13:30",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Skevikstrandsvägen 4, 13432 Gustavsberg",
        tag: "Panik (1h)",
        pickupTime: "14:00",
        dropoffTime: "16:00",
    },
    {
        from: "Drottninggatan 53, 11121 Stockholm",
        to: "Maria Skolgata 83, 11853 Södermalm C",
        tag: "DirektPanik",
        pickupTime: "11:45",
    },
    {
        from: "Telegrafgatan 4, 16972 Solna",
        to: "Kabyssgatan 4D, 12030 Hammarby Sjöstad",
        tag: "Tretimmars",
        pickupTime: "12:00",
        dropoffTime: "15:00",
    },
    {
        from: "Bäckvägen 18C, 19254 Sollentuna",
        to: "Jakobsbergsgatan 3, 11144 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "10:20",
        dropoffTime: "13:20",
    },
    {
        from: "Vasagatan 7, 4tr, 11120 Stockholm",
        to: "Kabyssgatan 4D, 12030 Hammarby Sjöstad",
        tag: "Tretimmars",
        pickupTime: "12:00",
        dropoffTime: "15:00",
    },
    {
        from: "Observatoriegatan 13, 11329 Stockholm",
        to: "Tegeluddsvägen 11-13, 11541 Gärdet",
        tag: "Dags",
        pickupTime: "10:22",
        dropoffTime: "17:00",
    },
    {
        from: "Industrigatan 4A, 6tr, 11246 Stockholm",
        to: "Fridhemsgatan 11, 11240 Kungsholmen",
        tag: "Dags",
        pickupTime: "10:23",
        dropoffTime: "17:00",
    },
    {
        from: "Odengatan 104, 11322 Stockholm",
        to: "Bryggargatan 12, 2tr, 11121 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "10:23",
        dropoffTime: "13:23",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Markvardsgatan 8/1tr, 11353 Vasastan",
        tag: "Tretimmars",
        pickupTime: "10:25",
        dropoffTime: "13:30",
    },
    {
        from: "Enbärsvägen 9, 18164 Lidingö",
        to: "Karlsviksgatan 19, 11241 Kungsholmen",
        tag: "Panik (1h)",
        pickupTime: "10:24",
        dropoffTime: "12:00",
    },
    {
        from: "Drottninggatan 59, 11121 Stockholm",
        to: "Hanna Paulis gata 4, 12952 Fruängen",
        tag: "Dags",
        pickupTime: "10:26",
        dropoffTime: "17:00",
    },
    {
        from: "Förmansvägen 11, 7tr, 11743 Stockholm",
        to: "Mjölnarvägen 24, 5tr, 13174 Henriksdal",
        tag: "Tretimmars",
        pickupTime: "13:45",
        dropoffTime: "17:00",
    },
    {
        from: "Voltavägen 9-11, 16869 Bromma",
        to: "Norrbackagatan 60, 11334 Vasastan",
        tag: "Tretimmars",
        pickupTime: "10:27",
        dropoffTime: "13:27",
    },
    {
        from: "Linnegatan 2, 11447 Stockholm",
        to: "Tegeluddsvägen 11-13, 11540 Gärdet",
        tag: "Panik (1h)",
        pickupTime: "10:27",
        dropoffTime: "11:27",
    },
    {
        from: "Linnegatan 2, 11447 Stockholm",
        to: "Hantverkargatan 25, 7tr, 11231 Kungsholmen",
        tag: "Panik (1h)",
        pickupTime: "10:28",
        dropoffTime: "11:28",
    },
    {
        from: "Kolonnvägen 22, 16970 Solna",
        to: "Kabyssgatan 4D, 12030 Hammarby Sjöstad",
        tag: "Panik (1h)",
        pickupTime: "12:30",
        dropoffTime: "14:30",
    },
    {
        from: "Norrbackagatan 12, 11341 Stockholm",
        to: "Skånegatan 74, 11637 Södermalm Ö",
        tag: "Dags",
        pickupTime: "10:30",
        dropoffTime: "17:00",
    },
    {
        from: "Artillerigatan 13, 11451 Stockholm",
        to: "Drottninggatan 95 A, 11360 Vasastan",
        tag: "Tretimmars",
        pickupTime: "11:00",
        dropoffTime: "14:00",
    },
    {
        from: "Västmannagatan 14, 11124 Stockholm",
        to: "Granithöjden 7, 8tr, 13157 Nacka C",
        tag: "Dags",
        pickupTime: "10:32",
        dropoffTime: "17:00",
    },
    {
        from: "Rådmansgatan 72, 11360 Stockholm",
        to: "Enskedevägen 137, 12248 Enskede",
        tag: "Dags",
        pickupTime: "10:34",
        dropoffTime: "17:00",
    },
    {
        from: "Rosendalsvägen 3, 11460 Stockholm",
        to: "Vikingavägen 17B, 13333 Saltsjöbaden",
        tag: "Dags",
        pickupTime: "10:34",
        dropoffTime: "17:00",
    },
    {
        from: "Valhallavägen 118, 11441 Stockholm",
        to: "Malmskillnadsgatan 44A, 11157 Norrmalm",
        tag: "Dags Förbokad",
        pickupTime: "10:37",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsgatan 64, 11122 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "10:37",
        dropoffTime: "12:37",
    },
    {
        from: "Regeringsgatan 48, 11156 Stockholm",
        to: "Pilgatan 5, 11223 Kungsholmen",
        tag: "Tretimmars",
        pickupTime: "10:37",
        dropoffTime: "15:00",
    },
    {
        from: "Sankt Paulsgatan 21, 11846 Stockholm",
        to: "Tappvägen 3-5, 18764 Täby Östra",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "18:00",
    },
    {
        from: "Sankt Paulsgatan 21, 11846 Stockholm",
        to: "Sturegallerian 36, 11446 Östermalm",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "18:00",
    },
    {
        from: "Sankt Paulsgatan 21, 11846 Stockholm",
        to: "Alfred Nobels Allé 23, 14152 Flemmingsberg",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "18:00",
    },
    {
        from: "Riddargatan 23 A, 11457 Stockholm",
        to: "Eva Bonniers Gata 25, 12954 Fruängen",
        tag: "Dags",
        pickupTime: "10:38",
        dropoffTime: "17:00",
    },
    {
        from: "Sankt Paulsgatan 21, 11846 Stockholm",
        to: "Hamngatan 16-18, 11147 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "15:00",
        dropoffTime: "18:00",
    },
    {
        from: "Riddargatan 23 A, 11457 Stockholm",
        to: "Simrishamnsvägen 19, 12153 Björkhagen",
        tag: "Dags",
        pickupTime: "10:39",
        dropoffTime: "17:00",
    },
    {
        from: "Regeringsgatan 47, 11156 Stockholm",
        to: "Regeringsgatan 20, 11153 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "11:25",
    },
    {
        from: "Kungsgatan 24, 6tr, 11135 Stockholm",
        to: "Hantverkargatan 11, 11221 Kungsholmen",
        tag: "Dags",
        pickupTime: "10:42",
        dropoffTime: "17:00",
    },
    {
        from: "Riddargatan 30, 11457 Stockholm",
        to: "Vendevägen 15, 18260 Djursholm",
        tag: "Tretimmars",
        pickupTime: "10:42",
        dropoffTime: "15:30",
    },
    {
        from: "Vasagatan 11, 11120 Stockholm",
        to: "Ynglingagatan 7, 11347 Vasastan",
        tag: "Tretimmars",
        pickupTime: "10:43",
        dropoffTime: "13:43",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Olof Palmes gata 17, 11122 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "10:44",
        dropoffTime: "16:00",
    },
    {
        from: "Östermalmsgatan 87D, 11459 Stockholm",
        to: "Kabyssgatan 4D, 12030 Hammarby Sjöstad",
        tag: "Tretimmars",
        pickupTime: "12:00",
        dropoffTime: "15:00",
    },
    {
        from: "Hammarby Kaj 14, 12030 Stockholm",
        to: "Ringvägen 2, 11726 Södermalm V",
        tag: "Tretimmars",
        pickupTime: "13:15",
        dropoffTime: "17:00",
    },
    {
        from: "Sveavägen 53, 11359 Stockholm",
        to: "Skeppargatan 37, 11452 Östermalm",
        tag: "Dags",
        pickupTime: "10:46",
        dropoffTime: "17:00",
    },
    {
        from: "Drottninggatan 59, 11121 Stockholm",
        to: "Bergaliden 1, 17277 Sundbyberg",
        tag: "Tretimmars",
        pickupTime: "10:46",
        dropoffTime: "13:46",
    },
    {
        from: "Sveavägen 53, 11359 Stockholm",
        to: "Skomakargatan 28, 11129 Gamla stan V",
        tag: "Dags",
        pickupTime: "10:46",
        dropoffTime: "17:00",
    },
    {
        from: "Sveavägen 53, 11359 Stockholm",
        to: "Surbrunnsgatan 45, 0,5tr, 11348 Vasastan",
        tag: "Dags",
        pickupTime: "10:47",
        dropoffTime: "17:00",
    },
    {
        from: "Sveavägen 53, 11359 Stockholm",
        to: "Sehlstedtsgatan 3, 5tr, 11528 Gärdet",
        tag: "Dags",
        pickupTime: "10:47",
        dropoffTime: "17:00",
    },
    {
        from: "Hamngatan 16-18, 11147 STOCKHOLM",
        to: "Fågelviksvägen 9, 14553 Norsborg",
        tag: "Dags",
        pickupTime: "10:49",
        dropoffTime: "17:00",
    },
    {
        from: "Odengatan 3, 11424 Stockholm",
        to: "Emågatan 43, 12846 Bagarmossen",
        tag: "Dags",
        pickupTime: "10:50",
        dropoffTime: "17:00",
    },
    {
        from: "Drottninggatan 89, 11360 Stockholm",
        to: "Sibyllegatan 49, 11442 Östermalm",
        tag: "Tretimmars",
        pickupTime: "10:51",
        dropoffTime: "16:00",
    },
    {
        from: "Jakobsgatan 6, 2tr, 11152 Stockholm",
        to: "Marieviksgatan 19C, 11743 Marievik",
        tag: "Dags",
        pickupTime: "10:51",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Rökerigatan 19, 12162 Johanneshov",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Västmannagatan 27, 11325 Stockholm",
        to: "Tegeluddsvägen 3-5, 11540 Gärdet",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Lyftkransvägen 13B, 14250 Skogås",
        to: "Skeppargatan 75, 11459 Östermalm",
        tag: "Tretimmars",
        pickupTime: "10:53",
        dropoffTime: "13:53",
    },
    {
        from: "Kungsgatan 55, 11122 Stockholm",
        to: "Tullvaktsvägen 19, 11556 Gärdet",
        tag: "DirektPanik",
        pickupTime: "13:00",
    },
    {
        from: "Västmannagatan 27, 11325 Stockholm",
        to: "Finska Gatan 32, 12237 Enskede",
        tag: "Dags",
        pickupTime: "10:53",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63, 11528 Stockholm",
        to: "Malmskillnadsgatan 19, 11157 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "10:54",
        dropoffTime: "13:54",
    },
    {
        from: "Grev Turegatan 12, 11446 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "DirektPanik",
        pickupTime: "15:00",
    },
    {
        from: "Sergels Torg 14, 11157 Stockholm",
        to: "Drottninggatan 59, 11121 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "10:55",
        dropoffTime: "13:55",
    },
    {
        from: "Drottninggatan 59, 11121 Stockholm",
        to: "Humlegårdsgatan 6, 11446 Östermalm",
        tag: "Tretimmars",
        pickupTime: "10:55",
        dropoffTime: "13:55",
    },
    {
        from: "Smedjegatan 2C, 13154 Nacka",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Tretimmars",
        pickupTime: "10:56",
        dropoffTime: "17:00",
    },
    {
        from: "Hamngatan 27, 11147 Stockholm",
        to: "Bohusgatan 14, 10660 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "10:55",
        dropoffTime: "13:55",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Eva Bonniers Gata 17, 3tr, 12954 Fruängen",
        tag: "Tretimmars",
        pickupTime: "14:30",
        dropoffTime: "17:30",
    },
    {
        from: "Mosebacke Torg 4, 11646 Stockholm",
        to: "Östgötagatan 27, 11625 Södermalm Ö",
        tag: "Dags",
        pickupTime: "10:59",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Djupdalsvägen 107, 16772 Bromma Västra",
        tag: "Dags",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Såningsvägen 44, 17552 Viksjö",
        tag: "Dags",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Karlavägen 108, 11526 Stockholm",
        to: "Kabyssgatan 4D, 12030 Hammarby Sjöstad",
        tag: "Tretimmars",
        pickupTime: "12:00",
        dropoffTime: "15:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Jungfrugatan 60, 11531 Gärdet",
        tag: "Dags",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Franzéngatan 29, 1tr, 11216 Marieberg",
        tag: "Dags",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Rånövägen 7, 16839 Bromma Västra",
        tag: "Dags",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Ursviks Allé 22, 17466 Sundbyberg",
        tag: "Dags",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Linnégatan 87A, 11523 Stockholm",
        to: "Fleminggatan 14, 11226 Kungsholmen",
        tag: "Dags Förbokad",
        pickupTime: "11:02",
        dropoffTime: "17:00",
    },
    {
        from: "Nybrogatan 18,1tr, 11439 Stockholm",
        to: "Norrlandsgatan 21, 11143 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "11:03",
        dropoffTime: "14:03",
    },
    {
        from: "Warfvinges Väg 25,10tr, 11251 Stockholm",
        to: "Spannvägen 6, 16835 Bromma Västra",
        tag: "Dags",
        pickupTime: "11:00",
        dropoffTime: "17:00",
    },
    {
        from: "Hamngatan 37, 11147 Stockholm",
        to: "Söder Mälarstrand 65,9tr, 11846 Södermalm C",
        tag: "DirektPanik",
        pickupTime: "11:03",
    },
    {
        from: "Regeringsgatan 98, 11139 Stockholm",
        to: "Näckströmsgatan 6, 11147 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "11:03",
        dropoffTime: "14:30",
    },
    {
        from: "Rättarvägen 3, 16968 Solna",
        to: "Tegnérgatan 47B, 4tr, 11161 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "11:06",
        dropoffTime: "14:06",
    },
    {
        from: "Nytorgsgatan 36, 11640 Stockholm",
        to: "Torsgatan 53, 11337 Vasastan",
        tag: "Tretimmars",
        pickupTime: "11:06",
        dropoffTime: "18:00",
    },
    {
        from: "Odengatan 3, 11424 Stockholm",
        to: "Tranebergsvägen 53, 16745 Traneberg",
        tag: "Tretimmars",
        pickupTime: "11:06",
        dropoffTime: "14:06",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Linnégatan 87, 11523 Östermalm",
        tag: "Tretimmars",
        pickupTime: "11:07",
        dropoffTime: "15:00",
    },
    {
        from: "Nytorgsgatan 36, 11640 Stockholm",
        to: "Linnégatan 31, 11447 Östermalm",
        tag: "Tretimmars",
        pickupTime: "11:07",
        dropoffTime: "14:07",
    },
    {
        from: "Riddargatan 18, 11451 Stockholm",
        to: "Heleneborgsgatan 52, 11732 Södermalm V",
        tag: "Tretimmars",
        pickupTime: "11:07",
        dropoffTime: "14:07",
    },
    {
        from: "Sturegallerian -, 11446 STOCKHOLM",
        to: "Kungsholms Kyrkoplan 6, 11224 Kungsholmen",
        tag: "DirektPanik",
        pickupTime: "16:00",
    },
    {
        from: "Kungsbro Strand 17, 11226 Stockholm",
        to: "Barkarbyvägen 5, 17745 Barkaby",
        tag: "Tretimmars",
        pickupTime: "11:13",
        dropoffTime: "16:00",
    },
    {
        from: "Stureplan 3, 11450 Stockholm",
        to: "Hamngatan 15, 4tr, 11147 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "11:13",
        dropoffTime: "14:13",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Klippgatan 18, 11635 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "11:16",
        dropoffTime: "14:16",
    },
    {
        from: "Mäster Samuelsgatan 28,, 11157 Stockholm",
        to: "Grevgatan 14, 11453 Östermalm",
        tag: "Tretimmars",
        pickupTime: "11:17",
        dropoffTime: "14:17",
    },
    {
        from: "Peter Myndes Backe 16, 11846 Stockholm",
        to: "Hornsbruksgatan 23B, 11734 Södermalm V",
        tag: "Tretimmars",
        pickupTime: "11:18",
        dropoffTime: "14:18",
    },
    {
        from: "Kungsgatan 25, 11135 Stockholm",
        to: "Engelbrektsgatan 3, 4tr, 11432 Östermalm",
        tag: "DirektPanik",
        pickupTime: "11:45",
    },
    {
        from: "Kronobergsgatan 49, 4tr, 11233 Stockholm",
        to: "Drottninggatan 75, 11121 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "11:19",
        dropoffTime: "14:19",
    },
    {
        from: "Cardellgatan 1, 4tr, 11436 Stockholm",
        to: "Gjörwellsgatan 30, 11260 Marieberg",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Västmannagatan 1, 11124 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "13:11",
        dropoffTime: "14:11",
    },
    {
        from: "Klarabergsgatan 60, 11121 Stockholm",
        to: "Sjöviksvägen 59, 11758 Marievik",
        tag: "Tretimmars",
        pickupTime: "11:24",
        dropoffTime: "17:00",
    },
    {
        from: "Runebergsgatan 7, 2tr, 11429 Stockholm",
        to: "Johannesfredsvägen 15, 16868 Ulvsunda",
        tag: "Tretimmars",
        pickupTime: "11:25",
        dropoffTime: "14:25",
    },
    {
        from: "Regeringsgatan 38,4 tr, 11153 Stockholm",
        to: "Luntmakargatan 22, 11137 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "11:26",
        dropoffTime: "14:26",
    },
    {
        from: "Regeringsgatan 38,4 tr, 11153 Stockholm",
        to: "Birger Jarlsgatan 57, 11356 Vasastan",
        tag: "Tretimmars",
        pickupTime: "11:26",
        dropoffTime: "14:26",
    },
    {
        from: "Götgatan 20, 11846 Stockholm",
        to: "Birger Jarls Torg 2A, 11128 Gamla stan V",
        tag: "DirektPanik",
        pickupTime: "12:20",
    },
    {
        from: "Polhemsgatan 20A, 11230 Stockholm",
        to: "Näckströmsgatan 6, 11147 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "11:27",
        dropoffTime: "14:27",
    },
    {
        from: "Bäckvägen 18C, 19254 Sollentuna",
        to: "Birger Jarlsgatan 58, 4tr, 11429 Östermalm",
        tag: "Tretimmars",
        pickupTime: "11:29",
        dropoffTime: "15:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Brunkebergsåsen 22, 19266 Norrviken",
        tag: "Tretimmars",
        pickupTime: "12:40",
        dropoffTime: "15:40",
    },
    {
        from: "Tomtebodavägen 12A, 17165 Solna",
        to: "Ynglingagatan 14, 11347 Vasastan",
        tag: "Tretimmars",
        pickupTime: "11:38",
        dropoffTime: "17:00",
    },
    {
        from: "Brovägen 5, 3 tr, 18276 Stocksund",
        to: "Jakobs Torg 1, 11152 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "11:38",
        dropoffTime: "16:00",
    },
    {
        from: "Stora Nygatan 7, 2tr, 11127 Stockholm",
        to: "Narvavägen 13-17, 11439 Östermalm",
        tag: "Panik (1h)",
        pickupTime: "16:15",
        dropoffTime: "17:15",
    },
    {
        from: "Isafjordsgatan 37, 16440 Kista",
        to: "Jakobsgatan 6, 11152 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "11:39",
    },
    {
        from: "Karlavägen 108, 11526 Stockholm",
        to: "Näckströmsgatan 6, 11147 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "11:40",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Golfvägen 14, 18231 Danderyd",
        tag: "Tretimmars",
        pickupTime: "12:40",
        dropoffTime: "15:40",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Majorsvägen 28, 17747 Barkaby",
        tag: "Tretimmars",
        pickupTime: "12:40",
        dropoffTime: "15:40",
    },
    {
        from: "Humlegårdsgatan 17, 11435 Stockholm",
        to: "Vikingagatan 25, 4tr, 11342 Vasastan",
        tag: "Tretimmars",
        pickupTime: "11:43",
        dropoffTime: "14:43",
    },
    {
        from: "Odengatan 88, 11322 Stockholm",
        to: "Ringvägen 123, 11661 Södermalm Ö",
        tag: "Panik (1h)",
        pickupTime: "11:43",
        dropoffTime: "12:43",
    },
    {
        from: "Humlegårdsgatan 17, 11435 Stockholm",
        to: "Nekvägen 23, 16357 Spånga",
        tag: "Tretimmars",
        pickupTime: "11:43",
        dropoffTime: "14:43",
    },
    {
        from: "Birger Jarls Torg 2A, 11128 Stockholm",
        to: "Grundtvigsgatan 39, 16848 Blackeberg",
        tag: "Tretimmars",
        pickupTime: "11:43",
        dropoffTime: "14:43",
    },
    {
        from: "Humlegårdsgatan 17, 11435 Stockholm",
        to: "Bondegatan 82, 11634 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "11:44",
        dropoffTime: "14:44",
    },
    {
        from: "Humlegårdsgatan 17, 11435 Stockholm",
        to: "Slätbaksvägen 28, 12051 Årsta",
        tag: "Tretimmars",
        pickupTime: "11:44",
        dropoffTime: "14:44",
    },
    {
        from: "Humlegårdsgatan 17, 11435 Stockholm",
        to: "Örnbogatan 3, 16766 Bromma",
        tag: "Tretimmars",
        pickupTime: "11:44",
        dropoffTime: "14:44",
    },
    {
        from: "Humlegårdsgatan 17, 11435 Stockholm",
        to: "Folkskolegatan 24, 11735 Södermalm V",
        tag: "Tretimmars",
        pickupTime: "11:45",
        dropoffTime: "14:45",
    },
    {
        from: "Humlegårdsgatan 17, 11435 Stockholm",
        to: "Essinge Brogata 37, 11261 Lilla Essingen",
        tag: "Tretimmars",
        pickupTime: "11:45",
        dropoffTime: "14:45",
    },
    {
        from: "Engelbrektsgatan 5, 11432 Stockholm",
        to: "Sköldungagatan 2, 11427 Östermalm",
        tag: "Tretimmars",
        pickupTime: "11:46",
        dropoffTime: "14:46",
    },
    {
        from: "Riddargatan 18, 11451 Stockholm",
        to: "Surbrunnsgatan 45, 0,5tr, 11348 Vasastan",
        tag: "Tretimmars",
        pickupTime: "11:46",
        dropoffTime: "14:46",
    },
    {
        from: "Värtavägen 68, 11538 Stockholm",
        to: "Riddargatan 30, 11457 Östermalm",
        tag: "DirektPanik",
        pickupTime: "11:47",
    },
    {
        from: "Döbelnsgatan 35, 11352 Stockholm",
        to: "Polhemsgatan 20A, 11230 Kungsholmen",
        tag: "Tretimmars",
        pickupTime: "11:48",
        dropoffTime: "14:48",
    },
    {
        from: "Jungfrugatan 35, 11444 Stockholm",
        to: "Klippgatan 19, 11635 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "11:49",
        dropoffTime: "14:49",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Odengatan 75, 11322 Vasastan",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Slottsbacken 3, 11130 Skeppsbron",
        tag: "Panik (1h)",
        pickupTime: "15:00",
        dropoffTime: "16:00",
    },
    {
        from: "Söder Mälarstrand 65, 4tr, 11825 STOCKHOLM",
        to: "Stjärntorget 2, 16956 Solna",
        tag: "Tretimmars",
        pickupTime: "11:51",
        dropoffTime: "17:00",
    },
    {
        from: "Luntmakargatan 18,3tr, 11137 Stockholm",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Panik (1h)",
        pickupTime: "11:51",
        dropoffTime: "12:51",
    },
    {
        from: "Söder Mälarstrand 65, 4tr, 11825 STOCKHOLM",
        to: "Täby Centrum - , 18334 Täby Östra",
        tag: "Tretimmars",
        pickupTime: "11:52",
        dropoffTime: "17:00",
    },
    {
        from: "Östermalmsgatan 19, 11426 Stockholm",
        to: "Sturegatan 11, 11436 Östermalm",
        tag: "DirektPanik",
        pickupTime: "11:52",
    },
    {
        from: "Lindhagensgatan 126, 11251 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "13:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Brunkebergsåsen 22, 19266 Norrviken",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Palermogatan 17 2tr, 11556 Stockholm",
        to: "Jakobsgatan 6, 11152 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "11:54",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Olshammarsgatan 60, 6tr, 12476 Hagsätra",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Rapsodivägen 69, 14241 Skogås",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Eva Bonniers gata 2, 12954 Fruängen",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Tjärhovsgatan 40, 1tr, 11628 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Jakobsbergsgatan 18, 8tr, 11144 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Dalagatan 100, 11343 Vasastan",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Blekholmsgatan 18, 11164 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Skeppargatan 37, 11452 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Eva Bonniers Gata 17, 3tr, 12954 Fruängen",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Kolonnvägen 22 D, 16970 Solna",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Drakskeppsvägen 32, 18363 Täby Östra",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Jungfrugatan 38, 11533 Gärdet",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Valhallavägen 110, 11441 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Reimersholmsgatan 39, 11740 Reimersholme",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Gösvägen 40, 18130 Sticklinge",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Sehlstedtsgatan 3 5tr, 11528 Gärdet",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Surbrunnsgatan 45, 0,5tr, 11348 Vasastan",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Skeppargatan 37, 11452 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Skeppargatan 37, 11452 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Kvarnholmsvägen 91, 13131 Nacka",
        to: "Drottninggatan 59, 11121 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "11:56",
        dropoffTime: "17:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Besmansvägen 29, 16834 Bromma Västra",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Bergaliden 5, 17277 Sundbyberg",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Skomakargatan 28, 11129 Gamla stan V",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Mosebacke Torg 4, 11646 Stockholm",
        to: "Essinge Brogata 10, 2tr, 11261 Lilla Essingen",
        tag: "Tretimmars",
        pickupTime: "11:59",
        dropoffTime: "14:59",
    },
    {
        from: "Malmgårdsvägen 18, 11638 Stockholm",
        to: "Kungsgatan 48, 6tr, 11135 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "12:00",
        dropoffTime: "16:00",
    },
    {
        from: "Birger Jarlsgatan 106, 11420 Stockholm",
        to: "Upplandsgatan 7, 11123 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "12:03",
        dropoffTime: "15:03",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Drottninggatan 97, 11360 Vasastan",
        tag: "Tretimmars",
        pickupTime: "12:03",
        dropoffTime: "16:00",
    },
    {
        from: "Metargatan 11, 11666 Stockholm",
        to: "Vävar Johans Gata 32, 12070 Hammarby Sjöstad",
        tag: "Dags",
        pickupTime: "12:03",
        dropoffTime: "17:00",
    },
    {
        from: "Norrlandsgatan 3, 11146 Stockholm",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Tretimmars",
        pickupTime: "12:04",
        dropoffTime: "15:04",
    },
    {
        from: "Valhallavägen 126, 11441 Stockholm",
        to: "Söder Mälarstrand 65, 11825 Södermalm C",
        tag: "Panik (1h)",
        pickupTime: "12:05",
        dropoffTime: "13:05",
    },
    {
        from: "Karlsviksgatan 19, 11241 Stockholm",
        to: "Enbärsvägen 9, 18164 Lidingö Östra",
        tag: "Tretimmars",
        pickupTime: "12:06",
        dropoffTime: "17:00",
    },
    {
        from: "Jakobsgatan 6, 11152 Stockholm",
        to: "Grönviksvägen 29, 16771 Bromma Västra",
        tag: "Tretimmars",
        pickupTime: "12:06",
        dropoffTime: "15:06",
    },
    {
        from: "Regeringsgatan 38, 11153 Stockholm",
        to: "Linnégatan 2, 11447 Östermalm",
        tag: "DirektPanik",
        pickupTime: "12:09",
    },
    {
        from: "Riddargatan 35, 3tr, 11457 Stockholm",
        to: "Mäster Samuelsgatan 59-61, 11121 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "12:08",
        dropoffTime: "15:08",
    },
    {
        from: "Birger Jarlsgatan 37, 11145 Stockholm",
        to: "Sibyllegatan 41, 1tr, 11442 Östermalm",
        tag: "Tretimmars",
        pickupTime: "12:08",
        dropoffTime: "17:00",
    },
    {
        from: "Väpnargatan 6, 11451 Stockholm",
        to: "Linnégatan 87, 11523 Östermalm",
        tag: "Tretimmars",
        pickupTime: "12:08",
        dropoffTime: "15:08",
    },
    {
        from: "Regeringsgatan 98, 11139 Stockholm",
        to: "Kronobergsgatan 37, 11233 Kungsholmen",
        tag: "Tretimmars",
        pickupTime: "12:09",
        dropoffTime: "17:00",
    },
    {
        from: "Roslagsgatan 32, 11355 Stockholm",
        to: "Nybrogatan 9, 11434 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Grev Turegatan 12, 11446 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "DirektPanik",
        pickupTime: "15:00",
        dropoffTime: "17:00",
    },
    {
        from: "Birger Jarlsgatan 33, 2tr, 11145 Stockholm",
        to: "Klarabergsviadukten 90A, 5tr, 11164 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "12:12",
        dropoffTime: "16:00",
    },
    {
        from: "Hornsgatan 44, 11821 Stockholm",
        to: "Stjärntorget 2, 16956 Solna",
        tag: "Tretimmars",
        pickupTime: "12:12",
        dropoffTime: "15:12",
    },
    {
        from: "Halmsjövägen 40, 19561 Arlandastad",
        to: "Nymärsta Kulle 2, 19530 Märsta",
        tag: "DirektPanik",
        pickupTime: "14:00",
    },
    {
        from: "Valhallavägen 96, 11427 Stockholm",
        to: "Fiskargatan 8, 11620 Södermalm Ö",
        tag: "DirektPanik",
        pickupTime: "15:00",
    },
    {
        from: "Drottninggatan 33, 11151 Stockholm",
        to: "Hagagatan 17N, 11347 Vasastan",
        tag: "Tretimmars",
        pickupTime: "12:15",
        dropoffTime: "15:15",
    },
    {
        from: "Sveavägen 20, 11157 Stockholm",
        to: "Barks väg 15, 10680 Solna",
        tag: "Tretimmars",
        pickupTime: "12:18",
        dropoffTime: "17:00",
    },
    {
        from: "Luntmakargatan 40, 11137 Stockholm",
        to: "Torsgatan 51, 11337 Vasastan",
        tag: "Tretimmars",
        pickupTime: "12:20",
        dropoffTime: "15:30",
    },
    {
        from: "Wallingatan 2,5tr, 11160 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "12:21",
        dropoffTime: "14:21",
    },
    {
        from: "Cardellgatan 1, 11436 Stockholm",
        to: "Torsvikssvängen 4, 18134 Lidingö Västra",
        tag: "Tretimmars",
        pickupTime: "12:24",
        dropoffTime: "15:24",
    },
    {
        from: "Birger Jarlsgatan 106, 11420 Stockholm",
        to: "Björngårdsgatan 23, 11852 Södermalm C",
        tag: "Tretimmars",
        pickupTime: "12:25",
        dropoffTime: "15:25",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Banérgatan 37, 11522 Östermalm",
        tag: "Tretimmars",
        pickupTime: "12:29",
        dropoffTime: "17:00",
    },
    {
        from: "Birger Jarlsgatan 58, 4tr, 11429 Stockholm",
        to: "Frösundaviksalle 1, 16970 Solna",
        tag: "Tretimmars",
        pickupTime: "12:29",
        dropoffTime: "17:00",
    },
    {
        from: "Ranhammarsvägen 2, 16867 Bromma",
        to: "Västerlånggatan 28, 11129 Gamla stan V",
        tag: "Tretimmars",
        pickupTime: "12:33",
        dropoffTime: "15:33",
    },
    {
        from: "Hovslagargatan 4, 11148 Stockholm",
        to: "Nytorgsgatan 23 A, 11622 Södermalm Ö",
        tag: "DirektPanik",
        pickupTime: "12:35",
    },
    {
        from: "Jakobsgatan 6, 11152 Stockholm",
        to: "Makrillvägen 4B, 18130 Sticklinge",
        tag: "Tretimmars",
        pickupTime: "12:36",
        dropoffTime: "15:36",
    },
    {
        from: "Sturegatan 6, 11435 Stockholm",
        to: "Hammarby Kajgata 12, 12030 Hammarby Sjöstad",
        tag: "Panik (1h)",
        pickupTime: "12:36",
        dropoffTime: "14:06",
    },
    {
        from: "Stensätravägen 13, 12739 Skärholmen",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Panik (1h)",
        pickupTime: "12:00",
        dropoffTime: "13:30",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Stensätravägen 13, 12739 Skärholmen",
        tag: "Panik (1h)",
        pickupTime: "12:00",
        dropoffTime: "13:30",
    },
    {
        from: "Riddargatan 20, 11451 Stockholm",
        to: "Birger Jarlsgatan 58, 11444 Östermalm",
        tag: "Tretimmars",
        pickupTime: "12:42",
        dropoffTime: "15:42",
    },
    {
        from: "Riddargatan 20, 11451 Stockholm",
        to: "Bäckvägen 18C, 19254 Sollentuna Östra",
        tag: "Tretimmars",
        pickupTime: "12:42",
        dropoffTime: "15:42",
    },
    {
        from: "Riddargatan 20, 11451 Stockholm",
        to: "Jakobsbergsgatan 3, 11144 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "12:42",
        dropoffTime: "15:42",
    },
    {
        from: "Mosebacke Torg 4, 11646 Stockholm",
        to: "Essinge Brogata 10, 2tr, 11261 Lilla Essingen",
        tag: "Tretimmars",
        pickupTime: "12:43",
        dropoffTime: "15:43",
    },
    {
        from: "Centralplan 15, 2 tr, 11120 Stockholm",
        to: "Voltavägen 9-11, 16869 Ulvsunda",
        tag: "Tretimmars",
        pickupTime: "12:44",
        dropoffTime: "15:44",
    },
    {
        from: "Voltavägen 9-11, 16869 Bromma",
        to: "Centralplan 15, 2 tr, 11120 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "12:44",
        dropoffTime: "15:44",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Odengatan 71-73, 11322 Vasastan",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Gävlegatan 22, 11330 Stockholm",
        to: "Vickergatan 11, 11861 Södermalm C",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Hälsingegatan 40, 7tr, 11331 Stockholm",
        to: "Olivecronas Väg 8, 11361 Vasastan",
        tag: "DirektPanik",
        pickupTime: "12:48",
    },
    {
        from: "Klarabergsviadukten 90A, plan 5, 11164 Stockholm",
        to: "Ringvägen 52, 11867 Södermalm C",
        tag: "DirektPanik",
        pickupTime: "12:46",
    },
    {
        from: "Luntmakargatan 22, 11137 Stockholm",
        to: "Fleminggatan 56, 11245 Kungsholmen",
        tag: "Tretimmars",
        pickupTime: "12:52",
        dropoffTime: "15:52",
    },
    {
        from: "Riddargatan 20, 11451 Stockholm",
        to: "Vidarvägen 3, 18267 Djursholm",
        tag: "Tretimmars",
        pickupTime: "12:55",
        dropoffTime: "15:55",
    },
    {
        from: "Bilbioteksgatan 29, 11435 Stockholm",
        to: "Sandhamnsgatan 67, 11528 Gärdet",
        tag: "DirektPanik",
        pickupTime: "12:59",
    },
    {
        from: "Tulegatan 13, 11353 Stockholm",
        to: "Norrmalmstorg 1, 11146 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "13:03",
        dropoffTime: "14:03",
    },
    {
        from: "Norrmalmstorg 1, 11146 Stockholm",
        to: "Banmästargatan 2, 17067 Järva",
        tag: "Panik (1h)",
        pickupTime: "14:03",
        dropoffTime: "15:33",
    },
    {
        from: "Tegnérgatan 34, 2tr, 11359 Stockholm",
        to: "Parkgatan 20, 11230 Kungsholmen",
        tag: "Tretimmars",
        pickupTime: "13:07",
        dropoffTime: "16:07",
    },
    {
        from: "Torsgatan 30, 11321 Stockholm",
        to: "Frejgatan 93, 11326 Vasastan",
        tag: "Panik (1h)",
        pickupTime: "14:00",
        dropoffTime: "16:00",
    },
    {
        from: "Riddargatan 18, 11451 Stockholm",
        to: "Stadsgårdshamnen 24, 11645 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "13:10",
        dropoffTime: "16:10",
    },
    {
        from: "Hötorgshallen -, 11157 Stockholm",
        to: "Kungsgatan 33, 3tr, 11157 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:45",
        dropoffTime: "16:45",
    },
    {
        from: "Norrmalmstorg 14, 11146 Stockholm",
        to: "Banérgatan 23, 11522 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:11",
        dropoffTime: "16:11",
    },
    {
        from: "Västmannagatan 6, 11124 Stockholm",
        to: "Luntmakargatan 46, 10tr, 11137 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:11",
        dropoffTime: "16:11",
    },
    {
        from: "Mosebacke Torg 4, 11646 Stockholm",
        to: "Järnvägsgatan 23, 17275 Sundbyberg",
        tag: "Tretimmars",
        pickupTime: "13:12",
        dropoffTime: "16:12",
    },
    {
        from: "Kocksgatan 3, 11624 Stockholm",
        to: "Högbergsgatan 28, 11620 Södermalm Ö",
        tag: "DirektPanik",
        pickupTime: "19:00",
    },
    {
        from: "Karlavägen 31, 11431 Stockholm",
        to: "Alviksvägen 34, 16753 Bromma",
        tag: "Tretimmars",
        pickupTime: "13:16",
        dropoffTime: "16:16",
    },
    {
        from: "Bolinders Plan 2, 11224 Stockholm",
        to: "Sturegatan 25, 17231 Sundbyberg",
        tag: "DirektPanik",
        pickupTime: "13:17",
    },
    {
        from: "Virkesvägen 26, 6tr, 12030 Stockholm",
        to: "Biblioteksgatan 5, 11146 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:18",
        dropoffTime: "16:18",
    },
    {
        from: "Mäster Samuelsgatan 3, 11144 Stockholm",
        to: "Hamngatan 16-18, 11147 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:20",
        dropoffTime: "19:00",
    },
    {
        from: "Nytorgsgatan 36, 11640 Stockholm",
        to: "Norrmalmstorg 2, 11146 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "13:21",
        dropoffTime: "14:21",
    },
    {
        from: "Fiskargatan 8, 11620 Stockholm",
        to: "Hamngatan 37, 11147 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "13:22",
        dropoffTime: "14:22",
    },
    {
        from: "Fiskargatan 8, 11620 Stockholm",
        to: "Mäster Samuelsgatan 59, 11121 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "13:22",
        dropoffTime: "14:22",
    },
    {
        from: "Fiskargatan 8, 11620 Stockholm",
        to: "Regeringsgatan 38, 11156 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "13:22",
        dropoffTime: "14:22",
    },
    {
        from: "Slipgatan 5, 11739 Stockholm",
        to: "Pelargången 1, 19060 Arlanda",
        tag: "Tretimmars",
        pickupTime: "13:22",
        dropoffTime: "16:22",
    },
    {
        from: "Fiskargatan 8, 11620 Stockholm",
        to: "Mäster samuelsgatan 3, 11144 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "13:22",
        dropoffTime: "14:22",
    },
    {
        from: "Karlavägen 108, 11526 Stockholm",
        to: "Orvar Odds Väg 9, 11254 Marieberg",
        tag: "Tretimmars",
        pickupTime: "13:23",
        dropoffTime: "16:23",
    },
    {
        from: "Gustav III:s Boulevard 54, våning 3, 16973 Solna",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "15:00",
        dropoffTime: "16:00",
    },
    {
        from: "Gustav III:s Boulevard 54, våning 3, 16973 Solna",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "15:00",
        dropoffTime: "16:00",
    },
    {
        from: "Eriksbergsgatan 8B, 11430 Stockholm",
        to: "Grev Turegatan 30, 11438 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:27",
        dropoffTime: "17:30",
    },
    {
        from: "Eriksbergsgatan 8B, 11430 Stockholm",
        to: "Riddargatan 9, 11451 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:28",
        dropoffTime: "17:30",
    },
    {
        from: "Eriksbergsgatan 8B, 11430 Stockholm",
        to: "Bollhusgränd 3a., 11131 Gamla stan V",
        tag: "Tretimmars",
        pickupTime: "13:28",
        dropoffTime: "17:30",
    },
    {
        from: "Torsgatan 13, 11123 Stockholm",
        to: "Hornsbruksgatan 23B, 11734 Södermalm V",
        tag: "Tretimmars",
        pickupTime: "13:28",
        dropoffTime: "17:00",
    },
    {
        from: "Mäster Samuelsgatan 45, 16tr, 11157 Stockholm",
        to: "Djupdalsvägen 55, 16771 Bromma Västra",
        tag: "DirektPanik",
        pickupTime: "13:29",
    },
    {
        from: "Djupdalsvägen 55, 16771 Bromma",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "DirektPanik",
        pickupTime: "13:29",
    },
    {
        from: "Regeringsgatan 80, 11139 Stockholm",
        to: "Storkvägen 4, 18156 Lidingö Västra",
        tag: "Tretimmars",
        pickupTime: "13:28",
        dropoffTime: "16:28",
    },
    {
        from: "Nybrogatan 34, 3tr, 11439 Stockholm",
        to: "Sankt Eriksgatan 63C, 11234 Kungsholmen",
        tag: "Panik (1h)",
        pickupTime: "13:30",
        dropoffTime: "14:30",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Kungsbron 1, 11122 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:31",
        dropoffTime: "17:00",
    },
    {
        from: "Jakobs Torg 3, 11152 Stockholm",
        to: "Svetsarvägen 24, 17141 Solna",
        tag: "Tretimmars",
        pickupTime: "13:31",
        dropoffTime: "16:31",
    },
    {
        from: "Rörstrandsgatan 10, 11340 Stockholm",
        to: "Norra Stationsgatan 85, 3 tr, 11365 Vasastan",
        tag: "DirektPanik",
        pickupTime: "13:33",
    },
    {
        from: "Mosebacke Torg 4, 11646 Stockholm",
        to: "Götgatan 83D, 11662 Södermalm Ö",
        tag: "Tretimmars",
        pickupTime: "13:33",
        dropoffTime: "16:33",
    },
    {
        from: "Birger Jarlsgatan 82, 11356 Stockholm",
        to: "Engelbrektsgatan 25, 5tr, 11432 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:34",
        dropoffTime: "16:34",
    },
    {
        from: "Instrumentvägen 9, 12653 Hägersten",
        to: "Floragatan 13, 11431 Östermalm",
        tag: "Panik (1h)",
        pickupTime: "13:34",
        dropoffTime: "15:04",
    },
    {
        from: "Stadsgården 6, 11645 Stockholm",
        to: "Humlegården -, 11446 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:35",
        dropoffTime: "17:00",
    },
    {
        from: "Biblioteksgatan 11, 2tr, 11146 Stockholm",
        to: "Golfvägen 2, 18231 Danderyd",
        tag: "DirektPanik",
        pickupTime: "13:26",
    },
    {
        from: "Norrlandsgatan 16, 11143 STOCKHOLM",
        to: "Sibyllegatan 23, 11442 Östermalm",
        tag: "Tretimmars",
        pickupTime: "14:30",
        dropoffTime: "19:00",
    },
    {
        from: "Sankt Eriksgatan 115, 11331 Stockholm",
        to: "Regeringsgatan 59B, vån 9, 11156 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:38",
        dropoffTime: "16:38",
    },
    {
        from: "Birger Jarlsgatan 2, 11434 Stockholm",
        to: "Gustav III:S Boulevard 134, 8tr, 16970 Solna",
        tag: "Tretimmars",
        pickupTime: "13:40",
        dropoffTime: "17:00",
    },
    {
        from: "Biblioteksgatan 29, 11435 Stockholm",
        to: "Västra Järnvägsgatan 23, 11164 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:39",
        dropoffTime: "16:39",
    },
    {
        from: "Kungsgatan 29, 6tr, 11156 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "13:41",
        dropoffTime: "15:41",
    },
    {
        from: "Norrlandsgatan 16, 11143 Stockholm",
        to: "Storgatan 25, 11455 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:42",
        dropoffTime: "17:00",
    },
    {
        from: "Dalagatan 52, 11324 Stockholm",
        to: "Regeringsgatan 55, 11156 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "13:44",
        dropoffTime: "16:00",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Stavgränd 14, 12948 Hägersten",
        tag: "DirektPanik",
        pickupTime: "13:45",
    },
    {
        from: "Odengatan 104, 11322 Stockholm",
        to: "Finnboda Varvsväg 12B, 13172 Kvarnholmen",
        tag: "Panik (1h)",
        pickupTime: "13:45",
        dropoffTime: "16:45",
    },
    {
        from: "Kungsgatan 58, 2tr, 11122 Stockholm",
        to: "Regeringsgatan 65, 11156 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:45",
        dropoffTime: "17:00",
    },
    {
        from: "Blasieholmsgatan 4A, 11148 Stockholm",
        to: "Hallvägen 5, 12162 Johanneshov",
        tag: "Tretimmars",
        pickupTime: "13:46",
        dropoffTime: "17:00",
    },
    {
        from: "Riddargatan 17 D, 3tr, 11451 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "13:47",
        dropoffTime: "15:47",
    },
    {
        from: "Östgötagatan 100, 4tr, 11664 Stockholm",
        to: "Hammarbacken 1, 18235 Danderyd",
        tag: "Tretimmars",
        pickupTime: "13:48",
        dropoffTime: "16:48",
    },
    {
        from: "Magasin 1, 11538 Stockholm",
        to: "Martingalgatan 2, 18374 Täby",
        tag: "Tretimmars",
        pickupTime: "13:47",
        dropoffTime: "17:00",
    },
    {
        from: "Torkel Knutssonsgatan 2, 11825 Stockholm",
        to: "Hägerstensvägen 120, 12649 Aspudden",
        tag: "Panik (1h)",
        pickupTime: "13:48",
        dropoffTime: "17:30",
    },
    {
        from: "Sandhamnsgatan 67, 11528 Stockholm",
        to: "Rehnsgatan 11, 11357 Vasastan",
        tag: "Tretimmars",
        pickupTime: "13:51",
        dropoffTime: "17:00",
    },
    {
        from: "Karlavägen 74, 11449 STOCKHOLM",
        to: "Grevgatan 14, 3tr, 11453 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:52",
        dropoffTime: "16:52",
    },
    {
        from: "Kungsholmsgatan 2A, 11227 Stockholm",
        to: "Bryggargatan 12, 2tr, 11121 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "13:52",
        dropoffTime: "16:52",
    },
    {
        from: "Vasagatan 28, 11120 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "13:56",
        dropoffTime: "15:56",
    },
    {
        from: "Sankt Eriksgatan 79, 11332 Stockholm",
        to: "Holländargatan 20, 3 tr, 11160 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "16:00",
    },
    {
        from: "Holländargatan 20, 3 tr, 11160 Stockholm",
        to: "Sankt Eriksgatan 79, 11332 Vasastan",
        tag: "DirektPanik",
        pickupTime: "16:00",
    },
    {
        from: "Nekvägen 23, 16357 Spånga",
        to: "Humlegårdsgatan 17, 11435 Östermalm",
        tag: "Tretimmars",
        pickupTime: "13:58",
        dropoffTime: "16:58",
    },
    {
        from: "Tulegatan 11, 11353 Stockholm",
        to: "Birger Jarlsgatan 131A, 4tr, 11356 Vasastan",
        tag: "Tretimmars",
        pickupTime: "13:58",
        dropoffTime: "16:58",
    },
    {
        from: "Regeringsgatan 48, 11156 STOCKHOLM",
        to: "Engelbrektsgatan 21, 11432 Östermalm",
        tag: "DirektPanik",
        pickupTime: "15:00",
    },
    {
        from: "Östermals Salu Hall 49-52, 11439 STOCKHOLM",
        to: "Barnhusgatan 4, 11123 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "14:00",
        dropoffTime: "16:00",
    },
    {
        from: "Trollesundsvägen 56B, 12430 Bandhagen",
        to: "Nackagatan 4 1tr, 11641 Södermalm Ö",
        tag: "DirektPanik",
        pickupTime: "14:00",
    },
    {
        from: "Odengatan 29, 11351 Stockholm",
        to: "Sankt Paulsgatan 21, 11846 Södermalm C",
        tag: "Panik (1h)",
        pickupTime: "14:01",
        dropoffTime: "16:00",
    },
    {
        from: "Tomtebogatan 5, 11339 Stockholm",
        to: "Rålambsvägen 17, 11259 Marieberg",
        tag: "Panik (1h)",
        pickupTime: "14:01",
        dropoffTime: "15:01",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Spelmansvägen 14, 13675 Vendelsö",
        tag: "Panik (1h)",
        pickupTime: "14:03",
        dropoffTime: "15:33",
    },
    {
        from: "Tomtebogatan 5, 11339 Stockholm",
        to: "Tegeluddsvägen 21, 11541 Gärdet",
        tag: "Panik (1h)",
        pickupTime: "14:03",
        dropoffTime: "15:03",
    },
    {
        from: "Skeppargatan 92, 11455 Stockholm",
        to: "Textilgatan 43D, 12030 Hammarby Sjöstad",
        tag: "DirektPanik",
        pickupTime: "14:05",
    },
    {
        from: "Linnégatan 6, 11447 Stockholm",
        to: "Skotstigen 3, 18143 Lidingö",
        tag: "Tretimmars",
        pickupTime: "14:08",
        dropoffTime: "17:08",
    },
    {
        from: "Drottninggatan 59, 11121 Stockholm",
        to: "Finska Gatan 32, 12237 Enskede",
        tag: "Tretimmars",
        pickupTime: "14:10",
        dropoffTime: "17:10",
    },
    {
        from: "Banérgatan 16, 11522 Stockholm",
        to: "Vendevägen 49 A, 18264 Djursholm",
        tag: "Panik (1h)",
        pickupTime: "14:12",
        dropoffTime: "17:00",
    },
    {
        from: "Rehnsgatan 20, 4tr, 11357 Stockholm",
        to: "Hantverkarvägen 8, 18766 Arninge",
        tag: "Panik (1h)",
        pickupTime: "14:13",
        dropoffTime: "16:30",
    },
    {
        from: "Rehnsgatan 20, 4tr, 11357 Stockholm",
        to: "Industrivägen 9, 17148 Solna",
        tag: "Panik (1h)",
        pickupTime: "14:13",
        dropoffTime: "16:30",
    },
    {
        from: "Humlegårdsgatan 19A, 11446 Stockholm",
        to: "Norra Stationsgatan 99, 11364 Vasastan",
        tag: "Panik (1h)",
        pickupTime: "14:16",
        dropoffTime: "15:16",
    },
    {
        from: "Regeringsgatan 25-29, 11153 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "14:16",
        dropoffTime: "16:16",
    },
    {
        from: "Stora Gråmunkegränd 3, 11127 Stockholm",
        to: "Sankt Eriksgatan 7, 11239 Kungsholmen",
        tag: "Panik (1h)",
        pickupTime: "14:16",
        dropoffTime: "17:00",
    },
    {
        from: "Vasagatan 11, 6tr, 11120 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "14:20",
        dropoffTime: "15:20",
    },
    {
        from: "Klara Östra Kyrkogata 4, 11152 Stockholm",
        to: "Rissneleden 110, 17457 Sundbyberg",
        tag: "DirektPanik",
        pickupTime: "14:18",
    },
    {
        from: "Bolidenvägen 16 B, 12163 Johanneshov",
        to: "Maria Prästgårdsgata 15B, 11852 Södermalm C",
        tag: "Panik (1h)",
        pickupTime: "14:23",
        dropoffTime: "15:53",
    },
    {
        from: "Ingmar Bergmans Gata 2, 2tr, 11434 Stockholm",
        to: "Sveavägen 53, 11359 Vasastan",
        tag: "Tretimmars",
        pickupTime: "14:24",
        dropoffTime: "17:24",
    },
    {
        from: "Ingmar Bergmans Gata 2, 2tr, 11434 Stockholm",
        to: "Drivhjulsvägen 42, 12630 Västberga",
        tag: "Tretimmars",
        pickupTime: "14:25",
        dropoffTime: "17:25",
    },
    {
        from: "Ingmar Bergmans Gata 2, 2tr, 11434 Stockholm",
        to: "Jakobsbergsgatan 18, 11144 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "14:25",
        dropoffTime: "17:25",
    },
    {
        from: "Karlavägen 3, 4tr, 11424 Stockholm",
        to: "Humlegårdsgatan 4, 11446 Östermalm",
        tag: "DirektPanik",
        pickupTime: "14:26",
    },
    {
        from: "Norra Stationsgatan 44, BV, 11369 Stockholm",
        to: "Brännkyrkagatan 86A, 11726 Södermalm V",
        tag: "Panik (1h)",
        pickupTime: "14:26",
        dropoffTime: "15:26",
    },
    {
        from: "Riddargatan 30, 11457 Stockholm",
        to: "Landsvägen 50A,, 17263 Sundbyberg",
        tag: "Panik (1h)",
        pickupTime: "14:29",
        dropoffTime: "17:00",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Björkbacksvägen 39, 16869 Ulvsunda",
        tag: "Tretimmars",
        pickupTime: "14:30",
        dropoffTime: "17:30",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Ringvägen 53, 13335 Saltsjöbaden",
        tag: "Tretimmars",
        pickupTime: "14:30",
        dropoffTime: "17:30",
    },
    {
        from: "Gävlegatan 22, C, 11330 Stockholm",
        to: "Sjättenovembervägen 222, 12534 Älvsjö",
        tag: "Tretimmars",
        pickupTime: "14:31",
        dropoffTime: "17:31",
    },
    {
        from: "Regeringsgatan 25-29, 11153 Stockholm",
        to: "Stora Kvinns Väg 4, 12560 Älvsjö",
        tag: "Tretimmars",
        pickupTime: "14:32",
        dropoffTime: "17:32",
    },
    {
        from: "Jakobs Torg 3, 11152 Stockholm",
        to: "Västra Trädgårdsgatan 8, 11153 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "17:15",
        dropoffTime: "18:00",
    },
    {
        from: "Jakobs Torg 3, 11152 Stockholm",
        to: "Sturegatan 10, 11436 Östermalm",
        tag: "DirektPanik",
        pickupTime: "17:15",
        dropoffTime: "18:00",
    },
    {
        from: "Grev Turegatan 11 A vån 5, 11446 Stockholm",
        to: "Östermalmstorg 2, 11442 Östermalm",
        tag: "Panik (1h)",
        pickupTime: "14:37",
        dropoffTime: "17:30",
    },
    {
        from: "Vasagatan 7, 4tr, 11120 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "14:42",
        dropoffTime: "16:42",
    },
    {
        from: "Jakobsgatan 6, 11152 Stockholm",
        to: "Isafjordsgatan 37, 16440 Kista",
        tag: "DirektPanik",
        pickupTime: "14:44",
    },
    {
        from: "Banérgatan 16, 11522 Stockholm",
        to: "Mäster Samuelsgatan 45, 11157 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "15:00",
    },
    {
        from: "Hauptvägen 84, 2tr, 12358 Farsta",
        to: "Hantverkargatan 5 i, 11221 Kungsholmen",
        tag: "DirektPanik",
        pickupTime: "14:50",
        dropoffTime: "16:00",
    },
    {
        from: "Karlbergsvägen 20, 11327 Stockholm",
        to: "Storgatan 12, 11451 Östermalm",
        tag: "DirektPanik",
        pickupTime: "14:55",
    },
    {
        from: "Bolinders Plan 2, 11224 Stockholm",
        to: "Sturegatan 25, 17231 Sundbyberg",
        tag: "DirektPanik",
        pickupTime: "15:45",
    },
    {
        from: "Hamngatan 37, 11147 Stockholm",
        to: "Karlavägen 3, 4tr, 11424 Östermalm",
        tag: "DirektPanik",
        pickupTime: "14:57",
    },
    {
        from: "Ingmar Bergmans Gata 2, 2tr, 11434 Stockholm",
        to: "Birger Jarlsgatan 55, 3tr, 11145 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "15:03",
        dropoffTime: "18:03",
    },
    {
        from: "Ranhammarsvägen 20 H, 16867 Bromma",
        to: "S:t Paulsgatan 10, 11846 Södermalm C",
        tag: "DirektPanik",
        pickupTime: "15:05",
        dropoffTime: "16:30",
    },
    {
        from: "Luntmakargatan 34,7tr, 11137 Stockholm",
        to: "Tegeluddsvägen 3-5, 11540 Gärdet",
        tag: "Panik (1h)",
        pickupTime: "15:10",
        dropoffTime: "16:10",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Cardellgatan 2, 11436 Östermalm",
        tag: "Panik (1h)",
        pickupTime: "15:12",
        dropoffTime: "17:00",
    },
    {
        from: "Mäster Samuelsgatan 3, 11144 Stockholm",
        to: "Hamngatan 16-18, 11147 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "15:13",
    },
    {
        from: "Evenemangsgatan 21, 6tr, 16956 Solna",
        to: "Solnavägen 4, 7 tr, 11365 Vasastan",
        tag: "DirektPanik",
        pickupTime: "15:14",
    },
    {
        from: "Rehnsgatan 20, 4tr, 11357 Stockholm",
        to: "Hjovägen 2, 12150 Hammarbyhöjden",
        tag: "DirektPanik",
        pickupTime: "15:14",
        dropoffTime: "16:00",
    },
    {
        from: "Parkgatan 20, 11230 Stockholm",
        to: "Roslagsgatan 21, 11355 Vasastan",
        tag: "Tretimmars",
        pickupTime: "15:20",
        dropoffTime: "18:20",
    },
    {
        from: "Humlegårdsgatan 1, 11446 Stockholm",
        to: "Berzelii Park 9 7tr, 11147 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "16:20",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Jakobsbergsgatan 3, 11144 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "15:22",
        dropoffTime: "18:22",
    },
    {
        from: "Biblioteksgatan 29, 11435 Stockholm",
        to: "Valnötsvägen 4, 17549 Viksjö",
        tag: "Tretimmars",
        pickupTime: "15:23",
        dropoffTime: "18:23",
    },
    {
        from: "Uddvägen 11, 13154 Nacka",
        to: "Kungsgatan 6, 11143 Norrmalm",
        tag: "Tretimmars",
        pickupTime: "15:23",
        dropoffTime: "18:23",
    },
    {
        from: "Smålandsgatan 10, 11146 Stockholm",
        to: "Tysta Gatan 7, 11520 Östermalm",
        tag: "Panik (1h)",
        pickupTime: "15:29",
        dropoffTime: "17:00",
    },
    {
        from: "Linnégatan 76, 11523 Stockholm",
        to: "Önskehemsgatan 43, 1tr, 12454 Högdalen",
        tag: "Panik (1h)",
        pickupTime: "15:29",
        dropoffTime: "16:59",
    },
    {
        from: "Holländargatan 20, 3 tr, 11160 Stockholm",
        to: "Götgatan 48, 11136 Södermalm C",
        tag: "Panik (1h)",
        pickupTime: "15:30",
        dropoffTime: "16:30",
    },
    {
        from: "Linnégatan 76, 11523 Stockholm",
        to: "Yttersta Tvärgränd 4, 11823 Södermalm C",
        tag: "Panik (1h)",
        pickupTime: "15:30",
        dropoffTime: "17:00",
    },
    {
        from: "Linnégatan 76, 11523 Stockholm",
        to: "Pilvingegatan 58, 12835 Skarpnäck",
        tag: "Panik (1h)",
        pickupTime: "15:31",
        dropoffTime: "17:01",
    },
    {
        from: "Linnégatan 76, 11523 Stockholm",
        to: "Åhusvägen 23, 12151 Björkhagen",
        tag: "Panik (1h)",
        pickupTime: "15:31",
        dropoffTime: "17:01",
    },
    {
        from: "Gustav Adolfs Torg 18, 11152 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "DirektPanik",
        pickupTime: "15:31",
        dropoffTime: "17:00",
    },
    {
        from: "Örlogsvägen 11, 11149 Stockholm",
        to: "Norrmalmstorg 14, 11450 Östermalm",
        tag: "Panik (1h)",
        pickupTime: "15:32",
        dropoffTime: "16:32",
    },
    {
        from: "Hamngatan 2, 11147 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "15:37",
        dropoffTime: "17:37",
    },
    {
        from: "Jakobsbergsgatan 2, 6tr, 11144 Stockholm",
        to: "Sonja Kovalevskys g 2, 11366 Vasastan",
        tag: "DirektPanik",
        pickupTime: "15:37",
    },
    {
        from: "Mäster Samuelsgatan 3, 11144 Stockholm",
        to: "Regeringsgatan 65, 11139 Norrmalm",
        tag: "DirektPanik",
        pickupTime: "15:48",
    },
    {
        from: "Tegeluddsvägen 11-13, 11540 Stockholm",
        to: "Trevebovägen 23, 18432 Åkersberga",
        tag: "DirektPanik",
        pickupTime: "15:50",
    },
    {
        from: "Sandhamnsgatan 63B, 11528 Stockholm",
        to: "Styrmansgatan 14, 11454 Östermalm",
        tag: "DirektPanik",
        pickupTime: "23:30",
        dropoffTime: "23:59",
    },
    {
        from: "Sveavägen 20, 6tr, 11157 Stockholm",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Panik (1h)",
        pickupTime: "16:07",
        dropoffTime: "17:07",
    },
    {
        from: "Drottninggatan 25, 11151 Stockholm",
        to: "Skogsvägen 24, 18355 Täby Östra",
        tag: "Tretimmars",
        pickupTime: "15:52",
        dropoffTime: "20:00",
    },
    {
        from: "Birger Jarlsgatan 55, 11145 Stockholm",
        to: "Lötmogatan 4, 12632 Midsommarkransen",
        tag: "Tretimmars",
        pickupTime: "15:55",
        dropoffTime: "18:55",
    },
    {
        from: "Gustav den III:s Boulevard 130, 16974 Solna",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "15:56",
        dropoffTime: "16:56",
    },
    {
        from: "Trevebovägen 23, 18432 Åkersberga",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "DirektPanik",
        pickupTime: "15:50",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Torsplan 8, 11365 Vasastan",
        tag: "DirektPanik",
        pickupTime: "17:00",
    },
    {
        from: "Cardellgatan 2, 11436 Stockholm",
        to: "Sveavägen 131, 11346 Vasastan",
        tag: "DirektPanik",
        pickupTime: "17:00",
    },
    {
        from: "Birger Jarlsgatan 2/5tr, 11434 Stockholm",
        to: "Tingsvägen 11, 19161 Sollentuna",
        tag: "DirektPanik",
        pickupTime: "16:16",
    },
    {
        from: "Riddargatan 47, 11457 STOCKHOLM",
        to: "Sköldungagatan 3, 11427 Östermalm",
        tag: "DirektPanik",
        pickupTime: "16:32",
    },
    {
        from: "Engelbrektsgatan 5, 11432 Stockholm",
        to: "Eriksbergsgatan 14, 4 tr, 11430 Östermalm",
        tag: "DirektPanik",
        pickupTime: "16:37",
    },
    {
        from: "Biblioteksgatan 12, 11446 Stockholm",
        to: "Biblioteksgatan 6, 11146 Norrmalm",
        tag: "Panik (1h)",
        pickupTime: "16:39",
        dropoffTime: "18:00",
    },
    {
        from: "Åsögatan 176G, 11632 Stockholm",
        to: "Sandhamnsgatan 63B, 11528 Gärdet",
        tag: "Panik (1h)",
        pickupTime: "16:57",
        dropoffTime: "17:57",
    },
    {
        from: "Ringvägen 79, 11861 Stockholm",
        to: "Odengatan 92, 1tr, 11322 Vasastan",
        tag: "DirektPanik",
        pickupTime: "16:58",
    },
    {
        from: "Vasagatan 7, 11120 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "17:05",
        dropoffTime: "18:05",
    },
    {
        from: "Medevigatan 5, 1 tr, 11361 Stockholm",
        to: "Roslagsgatan 40D, 1tr, 11355 Vasastan",
        tag: "DirektPanik",
        pickupTime: "17:05",
    },
    {
        from: "Cardellgatan 1, 4tr, 11436 Stockholm",
        to: "Gjörwellsgatan 30, 11260 Marieberg",
        tag: "Tretimmars",
        pickupTime: "13:00",
        dropoffTime: "16:00",
    },
    {
        from: "Telegrafgatan 4, 16972 Solna",
        to: "Mekanikervägen 10, 14633 Tullinge",
        tag: "Dags Förbokad",
        pickupTime: "09:00",
        dropoffTime: "17:00",
    },
    {
        from: "Kungsbro Strand 31, 11226 Stockholm",
        to: "Sandhamnsgatan 63, 11528 Gärdet",
        tag: "Upphämtning",
        pickupTime: "10:00",
        dropoffTime: "13:00",
    },
];
