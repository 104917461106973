import { AnimatePresence, motion } from "framer-motion";
import { useMemo, useState } from "react";
import { TooltipAlignment } from "../../../shared/types/internal";
import "./style.scss";

type Props = {
    children: React.ReactNode;
    anchor: React.ReactNode;
    alignment: TooltipAlignment;
    minWidth?: string;
    hide?: boolean;
};

const motionVariants = {
    initial: { opacity: 0, scale: 0, y: 10 },
    animate: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0, y: 0 },
};

function Tooltip(props: Props) {
    const [showTooltip, setShowTooltip] = useState(false);

    const tooltipAlignmentStyle = useMemo(() => {
        return {
            top: props.alignment.y === "top" ? "auto" : "100%",
            bottom: props.alignment.y === "bottom" ? "auto" : "100%",
            left: props.alignment.x === "left" ? "0" : "auto",
            right: props.alignment.x === "right" ? "0" : "auto",
        };
    }, [props.alignment]);

    if (props.hide) {
        return props.anchor;
    }

    return (
        <div
            className="tooltip-container"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {props.anchor}
            <AnimatePresence>
                {showTooltip && (
                    <motion.div
                        className="tooltip"
                        variants={motionVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        style={{
                            ...tooltipAlignmentStyle,
                            minWidth: props.minWidth,
                        }}
                    >
                        {props.children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default Tooltip;
