import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { motion } from "framer-motion";
import { TooltipAlignment } from "../../../shared/types/internal";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import "./style.scss";

type Props = {
    label: string;
    color?: string;
    isCompleted?: boolean;
    groupedAmount?: number;
    tooltipData?: {
        address: string;
        status?: string;
        iconColor?: string;
        cargoContent?: string;
        comment?: string;
        alignment?: TooltipAlignment;
    };
    iconColor?: string;
};

function StopCardIcon(props: Props) {
    return (
        <div className="stop-card-icon-wrapper">
            {props.tooltipData && (
                <Tooltip
                    anchor={
                        <motion.div
                            whileHover={{ scale: 1.2 }}
                            className="stop-card-icon"
                            style={{
                                backgroundColor:
                                    props.color || "var(--color-neutral-100)",
                            }}
                        >
                            {!!props.groupedAmount && (
                                <div className="grouped-amount">
                                    <span className="text-2xs">
                                        {props.groupedAmount}
                                    </span>
                                </div>
                            )}
                            {props.iconColor && !props.isCompleted && (
                                <div className="status">
                                    <Icon
                                        icon={faCircle}
                                        size="xs"
                                        color={props.iconColor}
                                    />
                                </div>
                            )}
                            {props.isCompleted ? (
                                <Icon
                                    icon={faCheck}
                                    color="var(--color-pure-white)"
                                />
                            ) : (
                                <p className="text-xs">{props.label}</p>
                            )}
                        </motion.div>
                    }
                    alignment={
                        props.tooltipData.alignment || {
                            x: "right",
                            y: "bottom",
                        }
                    }
                >
                    <div className="stop-card-tooltip">
                        <p className="text-sm address">
                            {props.tooltipData.address}
                        </p>
                        {props.tooltipData.status && (
                            <div className="status">
                                <Icon
                                    icon={faCircle}
                                    size="xs"
                                    color={props.tooltipData.iconColor}
                                />

                                {props.tooltipData.status && (
                                    <p className="text-xs">
                                        {props.tooltipData.status}
                                    </p>
                                )}
                            </div>
                        )}
                        {props.tooltipData.cargoContent && (
                            <p className="text-xs cargo-content">
                                {props.tooltipData.cargoContent}
                            </p>
                        )}
                        {props.tooltipData.comment && (
                            <p className="text-xs cargo-content">
                                {props.tooltipData.comment}
                            </p>
                        )}
                    </div>
                </Tooltip>
            )}
        </div>
    );
}

export default StopCardIcon;
