import {
    addHours,
    addWeeks,
    eachDayOfInterval,
    endOfDay,
    endOfWeek,
    getDay,
    isAfter,
    isToday,
    startOfWeek,
    subWeeks,
} from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import alrikLogo from "../../assets/icons/alrik-logo.svg";
import alrikText from "../../assets/icons/alrik-text.svg";
import Spinner from "../../components/UI/Spinner";
import Button from "../../components/buttons/Button";
import IconButtonOLD from "../../components/buttons/IconButtonOLD";
import DateInput from "../../components/inputs/DateInput";
import CalendarTable from "../../fleet-planner/CalendarTable";
import useCalendar from "../../hooks/data/useCalendar";
import { useFleetRoute } from "../../hooks/route/useFleetRoute";
import CalendarTourMap from "../../maps/CalendarTourMap";
import StopsInfoPopup from "../../popups/StopsInfoPopup";
import {
    CalendarStop,
    CalendarStopDraft,
    CalendarTour,
    StopDraft,
} from "../../shared/types/api";
import { KeyString } from "../../shared/types/internal";
import { dateToString, stringToDate } from "../../shared/utility/date";
import {
    OUTSIDE_FLEET_COLUMN_ID,
    QUERY_PARAM,
    ROUTE,
    STOP_DRAFT_TYPE,
    UNHANDLED_COLUMN_ID,
    WEEK_DAYS,
} from "../../shared/values/enums";
import "./style.scss";

type Props = {
    fullPage?: boolean;
    autoRefresh?: boolean;
};

function FleetCalendar(props: Props) {
    const { t } = useTranslation();

    const { filterDate, setFilterDate } = useFleetRoute();

    const [popupStops, setPopupStops] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [popupTour, setPopupTour] = useState<CalendarTour | null>(null);

    const {
        calendar,
        isFetching: isCalendarFetching,
        refetch: refetchCalendar,
        status: calendarStatus,
    } = useCalendar({
        startDate: dateToString(
            startOfWeek(filterDate || new Date(), {
                weekStartsOn: 1,
            })
        ),
        endDate: dateToString(
            endOfWeek(filterDate || new Date(), {
                weekStartsOn: 1,
            })
        ),
    });

    // Auto refresh data after 5 minutes
    useEffect(() => {
        if (!props.autoRefresh) return;

        const intervalId = setInterval(
            async () => await refetchCalendar(),
            5 * 60 * 1000
        );

        return () => clearInterval(intervalId);
    }, [props.autoRefresh, refetchCalendar]);

    // Auto reload page at 01:00 every day
    useEffect(() => {
        if (!props.autoRefresh) return;

        const now = new Date();

        const tomorrowAt1 = addHours(endOfDay(now), 1);

        const timeout = tomorrowAt1.getTime() - now.getTime();

        const intervalId = setInterval(() => {
            window.location.reload();
        }, timeout);

        return () => clearInterval(intervalId);
    }, [props.autoRefresh]);

    const weekDates = useMemo(
        () =>
            eachDayOfInterval({
                start: startOfWeek(filterDate || new Date(), {
                    weekStartsOn: 1,
                }),
                end: endOfWeek(filterDate || new Date(), {
                    weekStartsOn: 1,
                }),
            }),
        [filterDate]
    );

    const sortCalendarItems = useCallback(
        (a: CalendarStop[], b: CalendarStop[]) => {
            // Helper function to calculate score based on status
            const calculateScore = (list: CalendarStop[]) => {
                if (!list) return 3; // Handle null/undefined lists, sort them at end.

                const isCompleted = list.every(
                    (stop) => stop.status === "done"
                );
                const isArchived = list.some(
                    (stop) => stop.status === "archived"
                );
                const hasBeenStarted = list.some(
                    (stop) =>
                        stop.status !== "unhandled" &&
                        stop.status !== "assigned" &&
                        stop.status !== "archived" &&
                        stop.status !== "done"
                );

                // Assign scores based on status priority
                if (isArchived) return 3;
                if (isCompleted) return 2;
                if (hasBeenStarted) return 0;
                return 1; // For non-started, non-completed, non-archived items
            };

            // Calculate scores for both lists
            const scoreA = calculateScore(a);
            const scoreB = calculateScore(b);

            // Compare scores to determine order
            return scoreA - scoreB;
        },
        []
    );

    const externalStops = useMemo<KeyString<CalendarStopDraft[]>>(() => {
        const result: KeyString<CalendarStopDraft[]> = {};

        for (const date in calendar) {
            const stops = calendar[date]?.unhandled_stops || [];

            result[date] = stops.filter((sd) => !!sd.external);
        }

        return result;
    }, [calendar]);

    const unhandledStops = useMemo<KeyString<CalendarStopDraft[]>>(() => {
        const result: KeyString<CalendarStopDraft[]> = {};

        for (const date in calendar) {
            const stops = calendar[date]?.unhandled_stops || [];

            result[date] = stops.filter((sd) => !sd.external);
        }

        return result;
    }, [calendar]);

    return (
        <div className="fleet-calendar">
            <div className="controls">
                <section>
                    {props.fullPage && (
                        <div className="alrik-logo-wrapper">
                            <img
                                className="text-logo"
                                src={alrikLogo}
                                alt="Alrik Logo"
                            />
                            <img
                                className="text-logo"
                                src={alrikText}
                                alt="Alrik Text"
                                style={{
                                    height: "40px",
                                }}
                            />
                        </div>
                    )}
                    <IconButtonOLD
                        icon="chevron-left"
                        onClick={() =>
                            setFilterDate((state) =>
                                subWeeks(state || new Date(), 1)
                            )
                        }
                        style={{
                            backgroundColor: "transparent",
                            color: "var(--text-color)",
                        }}
                    />
                    <DateInput
                        value={filterDate}
                        onChange={setFilterDate}
                        showAllDates
                        ignoreUnselectableDates
                        width="150px"
                    />
                    <IconButtonOLD
                        icon="chevron-right"
                        onClick={() =>
                            setFilterDate((state) =>
                                addWeeks(state || new Date(), 1)
                            )
                        }
                        style={{
                            backgroundColor: "transparent",
                            color: "var(--text-color)",
                        }}
                    />
                </section>

                <section>
                    <Button
                        label={t("activeFleet.updateAllData")}
                        variant="secondary"
                        onClick={() => refetchCalendar()}
                        isLoading={isCalendarFetching}
                    />
                    <Button
                        label={t("general.today")}
                        variant={
                            filterDate
                                ? isToday(filterDate)
                                    ? "secondary"
                                    : "primary"
                                : "primary"
                        }
                        onClick={() => setFilterDate(new Date())}
                        style={{ width: "100px" }}
                    />
                    {!props.fullPage && (
                        <NavLink
                            to={ROUTE.FleetCalendarFullPage}
                            target="_blank"
                        >
                            <Button
                                label={t("activeFleet.calendarFullPageLabel")}
                                variant="secondary"
                            />
                        </NavLink>
                    )}
                </section>
            </div>
            <div className="calendar-wrapper">
                {weekDates.map((date) => (
                    <div key={dateToString(date)} className="date-column">
                        <NavLink
                            to={{
                                pathname: ROUTE.Cockpit,
                                search: `?${
                                    QUERY_PARAM.FilterDate
                                }=${dateToString(date)}`,
                            }}
                            className="top"
                            style={
                                isToday(date)
                                    ? {
                                          backgroundColor:
                                              "var(--highlight-color)",
                                      }
                                    : undefined
                            }
                        >
                            <p className="text-sm">
                                {t(
                                    `general.weekdays.${
                                        WEEK_DAYS[getDay(date)]
                                    }`
                                )}
                            </p>
                            <p>{dateToString(date)}</p>
                        </NavLink>
                        {calendarStatus !== "success" ? (
                            <Spinner padding="10px" />
                        ) : (
                            calendar[dateToString(date)] && (
                                <>
                                    {!!unhandledStops[dateToString(date)]
                                        .length && (
                                        <CalendarTable
                                            key={UNHANDLED_COLUMN_ID}
                                            theme="unhandled"
                                            title={t(
                                                "fleetPlanner.unhandledTitle"
                                            )}
                                            stops={
                                                unhandledStops[
                                                    dateToString(date)
                                                ]
                                            }
                                            onStopClick={(stops) =>
                                                setPopupStops(stops)
                                            }
                                        />
                                    )}
                                    {!!externalStops[dateToString(date)]
                                        ?.length && (
                                        <CalendarTable
                                            key={OUTSIDE_FLEET_COLUMN_ID}
                                            theme="outside-fleet"
                                            title={t(
                                                "fleetPlanner.outsideFleetTitle"
                                            )}
                                            stops={
                                                externalStops[
                                                    dateToString(date)
                                                ] || []
                                            }
                                            onStopClick={(stops) =>
                                                setPopupStops(stops)
                                            }
                                        />
                                    )}

                                    {calendar[dateToString(date)]?.tours
                                        ?.sort((a, b) =>
                                            sortCalendarItems(a.stops, b.stops)
                                        )
                                        .map((tour) => (
                                            <CalendarTable
                                                key={tour.id}
                                                title={
                                                    tour.preferred_driver_name
                                                }
                                                stops={tour.stops}
                                                tourId={tour.id}
                                                hideEta={
                                                    tour.dispatched_at &&
                                                    tour.updated_at
                                                        ? !isAfter(
                                                              stringToDate(
                                                                  tour.dispatched_at
                                                              )!,
                                                              stringToDate(
                                                                  tour.updated_at
                                                              )!
                                                          )
                                                        : true
                                                }
                                                onStopClick={(stops) =>
                                                    setPopupStops(stops)
                                                }
                                                onTourIdClick={() =>
                                                    setPopupTour(tour)
                                                }
                                            />
                                        ))}
                                </>
                            )
                        )}
                    </div>
                ))}
            </div>

            {popupStops && (
                <StopsInfoPopup
                    showPopup={!!popupStops}
                    onClose={() => setPopupStops(null)}
                    stops={popupStops}
                    fetchOrder
                />
            )}

            {popupTour && (
                <CalendarTourMap
                    showPopup={!!popupTour}
                    onClose={() => setPopupTour(null)}
                    tour={popupTour}
                    onStopClick={(stop) => {
                        if (!popupTour) return;
                        const pickup = popupTour.stops.find(
                            (s) =>
                                s.group_id === stop.group_id &&
                                s.stop_type_id === STOP_DRAFT_TYPE.Pickup
                        );
                        const dropoff = popupTour.stops.find(
                            (s) =>
                                s.group_id === stop.group_id &&
                                s.stop_type_id === STOP_DRAFT_TYPE.Dropoff
                        );

                        if (!pickup || !dropoff) return;
                        if (
                            pickup.stop_type_id === STOP_DRAFT_TYPE.Pause ||
                            dropoff.stop_type_id === STOP_DRAFT_TYPE.Pause
                        )
                            return;
                        setPopupTour(null);
                        setPopupStops({ pickup, dropoff });
                    }}
                />
            )}
        </div>
    );
}

export default FleetCalendar;
