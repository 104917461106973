import { faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";
import IconButton from "../../../buttons/IconButton";
import "./style.scss";

type Props = {
    label: string;
    onClick: () => void;
    onFavouriteClick: () => void;
    isFocused?: boolean;
    isFavourite?: boolean;
    noFavourite?: boolean;
};

function AddressSearchRow(props: Props) {
    return (
        <div
            className="address-search-row"
            tabIndex={0}
            role="button"
            onClick={props.onClick}
            data-focused={props.isFocused}
            style={
                props.isFocused
                    ? {
                          backgroundColor: "var(--color-neutral-100)",
                      }
                    : {}
            }
        >
            <span className="text-sm">{props.label}</span>
            {!props.noFavourite && (
                <IconButton
                    iconProps={{
                        icon: props.isFavourite ? faStarSolid : faStarRegular,
                        color: props.isFavourite
                            ? "var(--color-primary-400)"
                            : "var(--color-neutral-600)",
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onFavouriteClick();
                    }}
                />
            )}
        </div>
    );
}

export default AddressSearchRow;
