import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";
import Icon from "../../../components/UI/Icon";
import useDirections from "../../../hooks/functionality/useDirections";
import { StopDraftsTour, TourStop } from "../../../shared/types/api";
import {
    calculateSpiral,
    getLatLngCountMap,
    getLocationKey,
} from "../../../shared/utility/misc";
import {
    getStopOrderMap,
    getStopStatus,
} from "../../../shared/utility/stop-draft";
import "./style.scss";

type Props = {
    tour?: StopDraftsTour;
    stops?: TourStop[];
    hoveredStopId?: number | null;
    tourColor?: {
        primary: string;
        accent: string;
    };
    disableDirections?: boolean;
    alwaysShowMarkerPositionNumber?: boolean;
    shouldShowClusterBadge?: boolean;
};

function TourMarkers(props: Props) {
    const stopList = useMemo(() => {
        return props.stops || props.tour?.stops;
    }, [props.stops, props.tour?.stops]);

    const stopOrderMap = useMemo(() => {
        if (!stopList) return {};
        return getStopOrderMap(stopList);
    }, [stopList]);

    const uniqueStopsToShow = useMemo(() => {
        if (!stopList) return [];
        const foundGroupIds = new Set();
        return stopList.filter((stop) => {
            if (!stop.motion_tools_stop_group) return true;
            if (foundGroupIds.has(stop.motion_tools_stop_group)) return false;
            foundGroupIds.add(stop.motion_tools_stop_group);
            return true;
        });
    }, [stopList]);

    const latLngStopCounts = useMemo(
        () =>
            getLatLngCountMap(
                uniqueStopsToShow
                    .filter((stop) => stop.lat && stop.lng)
                    .map((stop) => ({
                        lat: +stop.lat!,
                        lng: +stop.lng!,
                    }))
            ),
        [uniqueStopsToShow]
    );

    const { completedStops, incompleteStops } = useMemo(() => {
        const completedStops = uniqueStopsToShow.filter((stop) => {
            const stopStatus = getStopStatus(stop);
            return stopStatus === "completed";
        });
        const incompleteStops = uniqueStopsToShow.filter((stop) => {
            const stopStatus = getStopStatus(stop);
            return stopStatus !== "completed";
        });
        const lastCompletedStop = completedStops[completedStops.length - 1];

        if (lastCompletedStop) {
            incompleteStops.unshift(lastCompletedStop);
        }

        return {
            completedStops,
            incompleteStops,
        };
    }, [uniqueStopsToShow]);

    const mapPoints = useMemo(() => {
        return uniqueStopsToShow
            .filter((s) => s.lat && s.lng)
            .map((stop) => {
                const stopStatus = getStopStatus(stop);
                const stopLabel =
                    stopOrderMap[
                        stop.motion_tools_stop_group || stop.id.toString()
                    ] + 1;

                return {
                    id: stop.id,
                    label: stopLabel,
                    groupId: stop.motion_tools_stop_group,
                    location: {
                        lat: +stop.lat!,
                        lng: +stop.lng!,
                    },
                    stopStatus,
                };
            });
    }, [stopOrderMap, uniqueStopsToShow]);

    useDirections({
        path: incompleteStops
            .filter((stop) => stop.lat && stop.lng)
            .map((stop) => ({
                lat: +stop.lat!,
                lng: +stop.lng!,
            })),
        directionsColor: props.tourColor?.primary,
        hide: props.disableDirections,
        zIndex: 2,
    });

    useDirections({
        path: completedStops
            .filter((stop) => stop.lat && stop.lng)
            .map((stop) => ({
                lat: +stop.lat!,
                lng: +stop.lng!,
            })),
        directionsColor: props.tourColor?.accent,
        hide: props.disableDirections,
        zIndex: 1,
    });

    const motionVariants = {
        initial: { opacity: 0, scale: 0 },
        enter: { opacity: 1, scale: 1 },
    };

    return (
        <>
            {mapPoints.map((point, i) => (
                <AdvancedMarker
                    key={i}
                    position={
                        latLngStopCounts[getLocationKey(point.location)] > 1
                            ? calculateSpiral(point.location, i)
                            : point.location
                    }
                    zIndex={
                        point.id === props.hoveredStopId
                            ? Number.MAX_SAFE_INTEGER
                            : point.stopStatus === "completed"
                            ? undefined
                            : mapPoints.length - i
                    }
                >
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={{
                            hidden: { opacity: 0, scale: 0.5 },
                            visible: {
                                opacity: 1,
                                scale:
                                    point.id === props.hoveredStopId ? 1.3 : 1,
                                y: point.id === props.hoveredStopId ? -5 : 0,
                            },
                        }}
                        exit="hidden"
                        className="tour-marker"
                        style={{
                            backgroundColor:
                                point.stopStatus === "completed"
                                    ? props.tourColor?.accent
                                    : props.tourColor?.primary,
                            boxShadow:
                                point.stopStatus === "completed"
                                    ? "none"
                                    : "var(--shadow-lg)",
                        }}
                    >
                        <AnimatePresence>
                            {latLngStopCounts[getLocationKey(point.location)] >
                                1 &&
                                props.shouldShowClusterBadge && (
                                    <motion.div
                                        variants={motionVariants}
                                        initial="initial"
                                        animate="enter"
                                        exit="initial"
                                        className="count"
                                        style={{
                                            backgroundColor:
                                                props.tourColor?.accent,
                                        }}
                                    >
                                        <p>
                                            {
                                                latLngStopCounts[
                                                    getLocationKey(
                                                        point.location
                                                    )
                                                ]
                                            }
                                        </p>
                                    </motion.div>
                                )}
                        </AnimatePresence>
                        {point.stopStatus === "completed" &&
                        !props.alwaysShowMarkerPositionNumber ? (
                            <Icon icon={faCheck} size="sm" />
                        ) : (
                            <span className="text-2xs">{point.label}</span>
                        )}
                    </motion.div>
                </AdvancedMarker>
            ))}
        </>
    );
}

export default TourMarkers;
