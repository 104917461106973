import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { forwardRef, useMemo } from "react";
import { TooltipAlignment } from "../../../shared/types/internal";
import Icon from "../../UI/Icon";
import Spinner from "../../UI/Spinner";
import Tooltip from "../../UI/Tooltip";
import "./style.scss";

type ButtonColor = {
    backgroundColor: string;
    color: string;
    iconColor: string;
    border: string;
};

type ButtonVariants = {
    primary: ButtonColor;
    secondary: ButtonColor;
    disabled: ButtonColor;
};

const variants: ButtonVariants = {
    primary: {
        backgroundColor: "var(--color-primary-400)",
        color: "var(--color-neutral-900)",
        iconColor: "var(--color-primary-600)",
        border: "1px solid var(--color-primary-400)",
    },
    secondary: {
        backgroundColor: "var(--color-pure-white)",
        color: "var(--color-neutral-900)",
        iconColor: "var(--color-neutral-400)",
        border: "1px solid var(--color-neutral-200)",
    },
    disabled: {
        backgroundColor: "var(--color-neutral-100)",
        color: "var(--color-neutral-400)",
        iconColor: "var(--color-neutral-400)",
        border: "1px solid var(--color-neutral-200)",
    },
};

type Props = {
    label?: string;
    variant: keyof ButtonVariants;
    leadingIcon?: IconDefinition;
    leadingIconColor?: string;
    trailingIcon?: IconDefinition;
    onClick?: (event: React.MouseEvent) => void;
    style?: React.CSSProperties;
    className?: string;
    isLoading?: boolean;
    disabled?: boolean;
    noSubmit?: boolean;
    tooltipData?: {
        description: string;
        alignment?: TooltipAlignment;
        minWidth?: string;
    };
};

const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const buttonClasses = ["button", "waves-effect"];

    if (props.className) {
        buttonClasses.push(props.className);
    }

    const { backgroundColor, color, iconColor, border } = useMemo(() => {
        return props.disabled ? variants.disabled : variants[props.variant];
    }, [props.disabled, props.variant]);

    const ActualButton = (
        <button
            ref={ref}
            onClick={(e) => {
                if (props.isLoading || props.disabled) {
                    e.preventDefault();
                    return;
                }
                if (props.onClick) props.onClick(e);
            }}
            className={buttonClasses.join(" ")}
            disabled={props.disabled}
            style={{
                boxShadow: props.disabled ? "none" : "var(--shadow)",
                backgroundColor,
                border,
            }}
            type={props.noSubmit || props.onClick ? "button" : undefined}
        >
            {/* Leading icon */}
            {props.isLoading && (
                <div className="button-loading">
                    <Spinner width="15px" />
                </div>
            )}
            {props.leadingIcon && !props.isLoading && (
                <Icon
                    icon={props.leadingIcon}
                    size="lg"
                    color={props.leadingIconColor || iconColor}
                />
            )}

            {/* Label */}
            {props.label && (
                <p
                    className="text-xs"
                    style={{
                        color,
                    }}
                >
                    {props.label}
                </p>
            )}

            {/* Trailing icon */}
            {props.trailingIcon &&
                (props.isLoading ? (
                    <div className="button-loading">
                        <Spinner width="15px" />
                    </div>
                ) : (
                    <Icon
                        icon={props.trailingIcon}
                        size="lg"
                        color={iconColor}
                    />
                ))}
        </button>
    );

    return (
        <div className="button-wrapper" style={props.style}>
            {props.tooltipData ? (
                <Tooltip
                    anchor={ActualButton}
                    alignment={
                        props.tooltipData.alignment || {
                            x: "right",
                            y: "top",
                        }
                    }
                    minWidth={props.tooltipData.minWidth}
                >
                    <p>{props.tooltipData.description}</p>
                </Tooltip>
            ) : (
                ActualButton
            )}
        </div>
    );
});

export default Button;
