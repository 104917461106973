import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faSquareCheck, faSquareMinus } from "@fortawesome/pro-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "../../UI/Icon";
import "./style.scss";

type Props = {
    label?: string;
    subLabel?: string;
    checked: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    style?: React.CSSProperties;
};

function Checkbox(props: Props) {
    const checkboxClasses = ["checkbox"];
    if (props.checked) {
        checkboxClasses.push("checked");
    }
    if (props.disabled) {
        checkboxClasses.push("disabled");
    }

    const motionVariants = {
        initial: {
            opacity: 0,
            transition: {
                duration: 0.2,
            },
        },
        enter: {
            opacity: 1,
            transition: {
                duration: 0.2,
            },
        },
    };

    return (
        <label
            className={checkboxClasses.join(" ")}
            style={{
                cursor: props.disabled ? "default" : "pointer",
                ...props.style,
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <input
                type="checkbox"
                checked={props.checked}
                disabled={props.disabled}
                onChange={(e) => {
                    props.onChange(e.target.checked);
                }}
                hidden
            />
            <motion.div
                className="fa-layers fa-fw"
                whileTap={{ scale: props.disabled ? 1 : 0.9 }}
            >
                <AnimatePresence>
                    {!props.disabled && props.checked && (
                        <motion.div
                            variants={motionVariants}
                            initial="initial"
                            animate="enter"
                            exit="initial"
                        >
                            <Icon
                                icon={faSquareCheck}
                                size="xl"
                                color="var(--color-primary-400)"
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {!props.disabled && !props.checked && (
                        <motion.div
                            variants={motionVariants}
                            initial="initial"
                            animate="enter"
                            exit="initial"
                        >
                            <Icon
                                icon={faSquare}
                                size="xl"
                                color="var(--color-neutral-600)"
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {props.disabled && (
                        <motion.div
                            variants={motionVariants}
                            initial="initial"
                            animate="enter"
                            exit="initial"
                        >
                            <Icon
                                icon={faSquareMinus}
                                size="xl"
                                color="var(--color-neutral-300)"
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>

            {props.label && (
                <span
                    style={{
                        color: props.disabled
                            ? "var(--color-neutral-300)"
                            : "var(--color-neutral-600)",
                    }}
                >
                    {props.label}
                </span>
            )}
            {props.subLabel && (
                <span
                    style={{
                        marginLeft: "auto",
                        color: "var(--color-neutral-400)",
                    }}
                >
                    {props.subLabel}
                </span>
            )}
        </label>
    );
}

export default Checkbox;
