import { Draggable } from "@hello-pangea/dnd";
import PauseCard, { PauseCardProps } from "../PauseCard";
import useUserFeatures from "../../../hooks/functionality/useUserFeatures";
import { useMemo } from "react";
import { getStopStatus } from "../../../shared/utility/stop-draft";

type Props = {
    id: string;
    isDragDisabled?: boolean;
} & PauseCardProps;

function PauseCardDraggable(props: Props) {
    const features = useUserFeatures();
    const status = useMemo(() => getStopStatus(props.pause), [props.pause]);

    return (
        <Draggable
            draggableId={props.id}
            index={props.index}
            isDragDisabled={
                props.isDragDisabled ||
                !features?.manage_tours_fleet_planner ||
                status === "completed" ||
                status === "started"
            }
        >
            {(provided) => <PauseCard provided={provided} {...props} />}
        </Draggable>
    );
}

export default PauseCardDraggable;
