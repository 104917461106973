import { useTranslation } from "react-i18next";
import "./style.scss";

type Props = {};

function LowVersionBanner(props: Props) {
    const { t } = useTranslation();

    return (
        <div
            className="low-version-banner"
            onClick={() => window.location.reload()}
        >
            <span>{t("general.lowVersionBanner")}</span>
        </div>
    );
}

export default LowVersionBanner;
