import { Draggable } from "@hello-pangea/dnd";
import { useMemo } from "react";
import useUserFeatures from "../../../hooks/functionality/useUserFeatures";
import { getStopStatus } from "../../../shared/utility/stop-draft";
import GroupStopCard, { GroupStopCardProps } from "../GroupStopCard";
import "./style.scss";

type Props = {
    index: number;
    isDragDisabled?: boolean;
} & GroupStopCardProps;

function GroupStopCardDraggable(props: Props) {
    const features = useUserFeatures();

    const canStopBeMoved = useMemo(() => {
        const stopStatus = getStopStatus(props.stop);
        return (
            stopStatus !== "completed" &&
            stopStatus !== "unloading" &&
            stopStatus !== "loading"
        );
    }, [props.stop]);

    return (
        <Draggable
            key={props.stop.id}
            draggableId={props.stop.id.toString()}
            index={props.index}
            isDragDisabled={
                props.isDragDisabled ||
                !canStopBeMoved ||
                !features?.manage_tours_fleet_planner
            }
        >
            {(provided) => <GroupStopCard provided={provided} {...props} />}
        </Draggable>
    );
}

export default GroupStopCardDraggable;
