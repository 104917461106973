import { faWarning } from "@fortawesome/pro-duotone-svg-icons";
import {
    faChainBroken,
    faDash,
    faPersonDolly,
    faPersonDollyEmpty,
    faShare,
    faTag,
    faTimer,
    faTruck,
    faUserHardHat,
    faWeightHanging,
} from "@fortawesome/pro-regular-svg-icons";
import { DraggableProvided } from "@hello-pangea/dnd";
import { isAfter, parse } from "date-fns";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Badge from "../../../components/UI/Badge";
import Icon from "../../../components/UI/Icon";
import TextButton from "../../../components/buttons/TextButton";
import Checkbox from "../../../components/inputs/Checkbox";
import useHasActiveEllipsis from "../../../hooks/functionality/useHasActiveEllipsis";
import { StopDraft } from "../../../shared/types/api";
import { formatEta } from "../../../shared/utility/date";
import { getStopStatus } from "../../../shared/utility/stop-draft";
import {
    STOP_DRAFT_TYPE,
    STOP_STATUS_TEXT,
} from "../../../shared/values/enums";
import "./style.scss";

export type GroupStopCardProps = {
    // Core Data
    stop: StopDraft;
    index: number;
    displayIndex?: number;
    runningWeight?: number;
    eta?: string;
    // Drag and Drop
    provided?: DraggableProvided;

    // Action Handlers
    onHover?: (groupId: number) => void;
    onCheck?: (stopDraft: StopDraft) => void;
    checked?: boolean;
    onDegroup?: (stopDraft: StopDraft) => void;
    isDegrouping?: boolean;

    // Display Options
    hasGroupsAround?: {
        above: boolean;
        below: boolean;
    };
};

function GroupStopCard(props: GroupStopCardProps) {
    const { t } = useTranslation();

    const cargoContentText = useRef<HTMLParagraphElement | null>(null);
    const { hasActiveEllipsis } = useHasActiveEllipsis(
        cargoContentText,
        props.stop.cargo_content
    );

    const [isCargoContentExpanded, setIsCargoContentExpanded] = useState(false);

    const stopStatus = useMemo(() => getStopStatus(props.stop), [props.stop]);

    const { isStopCompleted, statusText } = useMemo(() => {
        return {
            isStopCompleted: stopStatus === "completed",
            statusText: STOP_STATUS_TEXT[stopStatus],
            showStatus: stopStatus !== "planned",
        };
    }, [stopStatus]);

    const stopDraftCardClasses = ["group-stop-card"];

    if (isStopCompleted) {
        stopDraftCardClasses.push("completed");
    }

    if (props.hasGroupsAround?.above) {
        stopDraftCardClasses.push("grouped-above");
    }

    if (props.hasGroupsAround?.below) {
        stopDraftCardClasses.push("grouped-below");
    }

    const etaString = useMemo(() => {
        const eta = formatEta(
            props.stop.completed_at ||
                props.stop.arrived_at ||
                props.stop.eta_internal ||
                undefined,
            {
                showTimeIfInThePast: true,
            }
        );

        return eta || props.eta;
    }, [
        props.eta,
        props.stop.arrived_at,
        props.stop.completed_at,
        props.stop.eta_internal,
    ]);

    const isLate = useMemo(() => {
        if (!props.stop.time_tooltip || !etaString) return false;

        const parsedTooltip = parse(
            props.stop.time_tooltip,
            "HH:mm",
            new Date()
        );
        const parsedEta = parse(etaString, "HH:mm", new Date());

        return isAfter(parsedEta, parsedTooltip);
    }, [props.stop.time_tooltip, etaString]);

    const warningIconStyle = {
        "--fa-primary-color": "var(--color-neutral-900)",
        "--fa-primary-opacity": "1",
        "--fa-secondary-color": "var(--color-yellow-400)",
        "--fa-secondary-opacity": "1",
    };

    return (
        <div
            className={stopDraftCardClasses.join(" ")}
            onMouseEnter={() => props.onHover?.(props.stop.id)}
            onMouseLeave={() => props.onHover?.(0)}
            data-group-id={props.stop.group_id}
            data-card-id={props.stop.id}
            ref={props.provided?.innerRef}
            {...props.provided?.draggableProps}
            style={{
                backgroundColor: "var(--color-pure-white)",
                opacity: isStopCompleted ? 0.5 : 1,
                ...props.provided?.draggableProps.style,
            }}
            {...props.provided?.dragHandleProps}
        >
            <div className="top">
                <div className="header">
                    <Checkbox
                        disabled={!props.onCheck || stopStatus !== "planned"}
                        checked={props.checked || false}
                        onChange={() => props.onCheck?.(props.stop)}
                        label={props.stop.order_number}
                    />

                    {props.onDegroup && (
                        <TextButton
                            leadingIcon={faChainBroken}
                            onClick={() => props.onDegroup?.(props.stop)}
                            label={t("fleetPlanner.degroup")}
                            isLoading={props.isDegrouping}
                            variant="secondary"
                        />
                    )}
                    <div className="status-time-wrapper">
                        {isLate && (
                            <Icon
                                icon={faWarning}
                                style={warningIconStyle as React.CSSProperties}
                                fixedWidth
                                size="lg"
                            />
                        )}
                        <p
                            className="text-xs"
                            style={{
                                color: "var(--text-color-light)",
                                marginLeft: "auto",
                            }}
                        >
                            {`${t(`groupingStops.${statusText}`)} ${
                                etaString || "-"
                            }`}
                        </p>
                    </div>
                </div>
            </div>

            <div className="addresses-wrapper">
                <div className="address">
                    <p className="text-sm">
                        {props.displayIndex?.toString() ||
                            (props.index + 1).toString()}
                    </p>
                    <Icon icon={faDash} color="var(--color-neutral-400)" />
                    <div className="address-text">
                        <p className="text-sm">{props.stop.to_location}</p>
                    </div>
                </div>
            </div>

            <div className="addresses-wrapper">
                <div className="address">
                    <div className="address-info">
                        {props.stop.stop_type_id === STOP_DRAFT_TYPE.Pickup ? (
                            <Badge
                                icon={faPersonDolly}
                                variant="success"
                                title={t("bigVolume.loading")}
                            />
                        ) : (
                            <Badge
                                iconClass="fa-flip-horizontal"
                                icon={faPersonDollyEmpty}
                                variant="neutral"
                                title={t("bigVolume.unloading")}
                            />
                        )}

                        {props.stop.time_tooltip && (
                            <Badge
                                variant={isLate ? "warning" : "neutral"}
                                icon={faTimer}
                                title={
                                    props.stop.stop_type_id ===
                                    STOP_DRAFT_TYPE.Pickup
                                        ? `${t(
                                              "popup.stopsInfo.earliestPickup"
                                          )} ${props.stop.time_tooltip}`
                                        : `${t(
                                              "popup.stopsInfo.latestDropoff"
                                          )} ${props.stop.time_tooltip}`
                                }
                            />
                        )}
                        {props.stop.contact && (
                            <Badge
                                variant="neutral"
                                icon={faUserHardHat}
                                title={props.stop.contact.name}
                            />
                        )}
                    </div>
                </div>
            </div>

            {props.stop.cargo_content && (
                <div className="cargo-content-wrapper">
                    <p
                        ref={cargoContentText}
                        className={`cargo-content${
                            isCargoContentExpanded ? " expanded" : ""
                        }`}
                        style={{ color: "var(--text-color-light)" }}
                    >
                        {props.stop.cargo_content}
                    </p>

                    {hasActiveEllipsis && (
                        <TextButton
                            label={
                                isCargoContentExpanded
                                    ? t("activeFleet.showLess")
                                    : t("activeFleet.showMore")
                            }
                            variant="secondary"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsCargoContentExpanded(
                                    !isCargoContentExpanded
                                );
                            }}
                            style={{ padding: "8px 8px" }}
                            wrapperStyle={{ width: "fit-content" }}
                        />
                    )}
                </div>
            )}

            <div className="bottom">
                {props.stop.weight_kg || props.runningWeight !== undefined ? (
                    <div className="weight-info">
                        <Badge
                            variant="neutral"
                            icon={faWeightHanging}
                            title={`${
                                props.stop.weight_kg
                                    ? props.stop.stop_type_id ===
                                      STOP_DRAFT_TYPE.Dropoff
                                        ? "- "
                                        : "+ "
                                    : ""
                            }${props.stop.weight_kg || 0} kg`}
                        />
                        <Badge
                            variant="neutral"
                            icon={faTruck}
                            title={`${props.runningWeight || 0} kg`}
                        />
                    </div>
                ) : null}
                <div className="tags">
                    {props.stop.order?.assigned_location_id && (
                        <Badge
                            title={t("fleetPlanner.assignedByAlrik")}
                            variant="success"
                            icon={faShare}
                        />
                    )}
                    {props.stop?.origin_location?.name && (
                        <Badge
                            title={props.stop?.origin_location?.name}
                            variant="success"
                            icon={faShare}
                        />
                    )}
                    {props.stop.tags &&
                        props.stop.tags.map((tag) => (
                            <Badge
                                key={tag}
                                title={tag}
                                variant="neutral"
                                icon={faTag}
                                hasTitleTooltip
                            />
                        ))}
                </div>
            </div>
        </div>
    );
}

export default GroupStopCard;
