import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import Icon from "../../UI/Icon";
import "./style.scss";
import { forwardRef } from "react";

type Props = {
    iconProps: FontAwesomeIconProps;
    label?: string;
    onClick?: (event: React.MouseEvent) => void;
    style?: React.CSSProperties;
};

const IconButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    return (
        <button
            ref={ref}
            className="icon-button"
            type={props.onClick ? "button" : undefined}
            onClick={props.onClick}
            style={props.style}
        >
            <Icon {...props.iconProps} />
            {props.label && <p className="text-sm">{props.label}</p>}
        </button>
    );
});

export default IconButton;
