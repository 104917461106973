import axios from "axios";
import environment from "../environment";
import {
    DirectionsResponse,
    GoogleAutoCompleteResponse,
    GoogleGeocodeResponse,
} from "../shared/types/google";
import { getLocale } from "../shared/utility/misc";

export async function getLocationFromAddress(address: string) {
    const result = await axios.get<GoogleGeocodeResponse>(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
            params: {
                address: address,
                key: environment.googleGeocodeApiKey,
                language: getLocale() || "en",
            },
        }
    );

    const firstResult = result.data.results?.[0];
    if (!firstResult) {
        return {
            location: null,
            locationType: null,
        };
    }

    return {
        location: firstResult.geometry?.location || null,
        locationType: firstResult.geometry?.location_type || null,
    };
}

export async function getAddressFromLocation(
    location: google.maps.LatLngLiteral
) {
    const result = await axios.get<GoogleGeocodeResponse>(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
            params: {
                latlng: location.lat + "," + location.lng,
                key: environment.googleGeocodeApiKey,
                language: getLocale() || "en",
            },
        }
    );

    const firstResult = result.data.results?.[0];
    if (!firstResult) {
        return {
            address: null,
            locationType: null,
        };
    }

    return {
        address: firstResult.formatted_address,
        locationType: firstResult.geometry?.location_type || null,
    };
}

export async function getGooglePlacesAutoComplete(
    input: string,
    country?: string
) {
    return axios.post<{
        suggestions?: GoogleAutoCompleteResponse[];
    }>(
        "https://places.googleapis.com/v1/places:autocomplete",
        {
            input: input,
            includedRegionCodes: country || "se",
            languageCode: getLocale() || "en",
            includedPrimaryTypes: ["street_address", "establishment"],
        },
        {
            headers: {
                "X-Goog-Api-Key": environment.googleMapsApiKey,
                "x-Goog-FieldMask":
                    "suggestions.placePrediction.placeId,suggestions.placePrediction.text.text",
            },
        }
    );
}

export async function getDirectionsRoute(
    path: {
        lat: number;
        lng: number;
    }[]
) {
    const originLocation = {
        latitude: path[0].lat,
        longitude: path[0].lng,
    };

    const destinationLocation = {
        latitude: path[path.length - 1].lat,
        longitude: path[path.length - 1].lng,
    };

    let intermediates;
    if (path.length > 2) {
        intermediates = path.slice(1, path.length - 1).map((point) => ({
            location: {
                latLng: {
                    latitude: point.lat,
                    longitude: point.lng,
                },
            },
            vehicleStopover: true,
        }));
    }

    return axios.post<DirectionsResponse>(
        "https://routes.googleapis.com/directions/v2:computeRoutes",
        {
            origin: {
                location: {
                    latLng: originLocation,
                },
                vehicleStopover: true,
            },
            destination: {
                location: {
                    latLng: destinationLocation,
                },
                vehicleStopover: true,
            },
            intermediates: intermediates,
            travelMode: "DRIVE",
            routingPreference: "TRAFFIC_UNAWARE",
            computeAlternativeRoutes: false,
            units: "METRIC",
            polylineEncoding: "ENCODED_POLYLINE",
        },

        {
            headers: {
                "X-Goog-Api-Key": environment.googleMapsApiKey,
                "x-Goog-FieldMask":
                    "routes.legs.duration,routes.legs.distanceMeters,routes.polyline",
            },
        }
    );
}

export async function getDistanceBetweenTwoLatLng({
    from_lat,
    from_lng,
    to_lat,
    to_lng,
}: {
    from_lat: number;
    from_lng: number;
    to_lat: number;
    to_lng: number;
}) {
    return axios.post<DirectionsResponse>(
        "https://routes.googleapis.com/directions/v2:computeRoutes",
        {
            origin: {
                location: {
                    latLng: {
                        latitude: from_lat,
                        longitude: from_lng,
                    },
                },
                vehicleStopover: true,
            },
            destination: {
                location: {
                    latLng: {
                        latitude: to_lat,
                        longitude: to_lng,
                    },
                },
                vehicleStopover: true,
            },
            travelMode: "DRIVE",
            routingPreference: "TRAFFIC_UNAWARE",
            computeAlternativeRoutes: false,
            units: "METRIC",
            polylineEncoding: "ENCODED_POLYLINE",
        },

        {
            headers: {
                "X-Goog-Api-Key": environment.googleMapsApiKey,
                "x-Goog-FieldMask":
                    "routes.legs.duration,routes.legs.distanceMeters,routes.polyline",
            },
        }
    );
}

export async function getDistanceForList(
    data: {
        from_lat: number;
        from_lng: number;
        to_lat: number;
        to_lng: number;
        [key: string]: any;
    }[]
) {
    const result: any[] = [];

    for (let i = 0; i < data.length; i++) {
        const d = data[i];

        let distanceMeters: number | null = null;

        try {
            const res = await getDistanceBetweenTwoLatLng(d);

            distanceMeters =
                res.data.routes?.[0].legs.reduce(
                    (acc, leg) => acc + leg.distanceMeters,
                    0
                ) || null;
        } catch (error) {
            console.error(
                "Error getting distance between two addresses",
                error
            );
        }

        result.push({
            ...d,
            distance_meters: distanceMeters,
            distance_km: distanceMeters ? distanceMeters / 1000 : null,
        });
    }

    return result;
}

export async function getLatLngForList(
    data: {
        pickup_address: string;
        dropoff_address: string;
        [key: string]: any;
    }[]
) {
    const result: any[] = [];

    for (let i = 0; i < data.length; i++) {
        const d = data[i];

        let fromLocation: {
            lat: number;
            lng: number;
        } | null = null;
        let toLocation: {
            lat: number;
            lng: number;
        } | null = null;

        try {
            fromLocation = (await getLocationFromAddress(d.pickup_address))
                .location;
            toLocation = (await getLocationFromAddress(d.dropoff_address))
                .location;
        } catch (error) {
            console.error(
                `Error getting distance between two addresses ${d.pickup_address} and ${d.dropoff_address}`,
                error
            );
        }

        result.push({
            ...d,
            from_lat: fromLocation?.lat || null,
            from_lng: fromLocation?.lng || null,
            to_lat: toLocation?.lat || null,
            to_lng: toLocation?.lng || null,
        });
    }

    return result;
}
