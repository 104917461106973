import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faStop } from "@fortawesome/pro-solid-svg-icons";
import Icon from "../Icon";
import "./style.scss";

type Props = {
    label: string;
    timestamp?: string;
    isCompleted?: boolean;
    style?: React.CSSProperties;
};

export default function StatusBadge(props: Props) {
    return (
        <div className="status-badge" style={props.style}>
            <div
                className="icon"
                style={{
                    backgroundColor: props.isCompleted
                        ? "var(--color-primary-400)"
                        : "var(--color-neutral-200)",
                }}
            >
                <Icon
                    icon={props.isCompleted ? faCheck : faStop}
                    color={
                        props.isCompleted
                            ? "var(--color-primary-600)"
                            : "var(--color-neutral-400)"
                    }
                    size="lg"
                    fixedWidth
                />
            </div>
            <div className="label">
                <p className="text-xs">{props.label}</p>
                <p
                    className="text-2xs"
                    style={{
                        color: "var(--color-neutral-500)",
                        opacity: props.timestamp ? 1 : 0,
                    }}
                >
                    {props.timestamp || "-"}
                </p>
            </div>
        </div>
    );
}
