import { ErrorBoundary } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { getMe } from "./api/auth";
import Spinner from "./components/UI/Spinner";
import ErrorComponent from "./components/widgets/ErrorComponent";
import Layout from "./hoc/Layout";
import NonAuthLayout from "./hoc/NonAuthLayout";
import useAnalytics from "./hooks/functionality/useAnalytics";
import useRouteAccessible from "./hooks/functionality/useRouteAccessible";
import useUserFeatures from "./hooks/functionality/useUserFeatures";
import ActiveFleet from "./pages/ActiveFleet";
import ActiveOrders from "./pages/ActiveOrders";
import AuthCode from "./pages/auth/AuthCode";
import EmailSent from "./pages/auth/EmailSent";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ResetPassword from "./pages/auth/ResetPassword";
import Setup from "./pages/auth/Setup";
import BigVolume from "./pages/BigVolume";
import Drivers from "./pages/Drivers";
import ExternalCarrierOrder from "./pages/ExternalCarrierOrder";
import Fleet from "./pages/Fleet";
import FleetCalendar from "./pages/FleetCalendar";
import FleetDashboard from "./pages/FleetDashboard";
import FleetManualOrder from "./pages/FleetManualOrder";
import FleetSettings from "./pages/FleetSettings";
import FulfilledFleetTours from "./pages/FulfilledFleetTours";
import FulfilledOrders from "./pages/FulfilledOrders";
import NewOrder from "./pages/NewOrder";
import Orders from "./pages/Orders";
import PublicOrder from "./pages/PublicOrder";
import Settings from "./pages/Settings";
import Support from "./pages/Support";
import Tracking from "./pages/Tracking";
import TrackingRedirect from "./pages/TrackingRedirect";
import memberstack from "./shared/services/memberstack";
import { User } from "./shared/types/api";
import { MemberstackMember } from "./shared/types/memberstack";
import {
    CockpitState,
    FleetPlannerSettings,
    ReduxState,
} from "./shared/types/redux";
import { API_ERROR, LOCAL_STORAGE_KEY, ROUTE } from "./shared/values/enums";
import store from "./store";
import { setUser } from "./store/slices/auth";
import { setStopColumns } from "./store/slices/cockpit";
import { setFleetPlannerSettings } from "./store/slices/fleetPlanner";

function App() {
    useAnalytics();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const { user } = useSelector((state: ReduxState) => state.auth);
    const features = useUserFeatures();
    const { isRouteAccessible } = useRouteAccessible();

    const [isAuthLoading, setIsAuthLoading] = useState(true);

    const authSetup = useCallback(
        async (isAuthenticated: boolean) => {
            setIsAuthLoading(true);

            if (!isAuthenticated) {
                dispatch(setUser(null));
                queryClient.clear();
                setIsAuthLoading(false);
                return;
            }

            let user: User | null = null;
            try {
                const res = await getMe();
                user = res.data;
                dispatch(setUser(user));
            } catch (error) {
                if (isAxiosError(error)) {
                    if (
                        error.response?.data.detail !== API_ERROR.BadToken &&
                        error.response?.data.detail !== API_ERROR.SessionExpired
                    ) {
                        toast.error(t("errorMessage.fetchCustomerError"), {
                            duration: 20000,
                        });
                    }
                }
                dispatch(setUser(null));
                setIsAuthLoading(false);
                return;
            }

            const fleetPlannerSettingsString = localStorage.getItem(
                LOCAL_STORAGE_KEY.FleetPlannerSettings
            );
            const cockpitStopColumnsString = localStorage.getItem(
                LOCAL_STORAGE_KEY.CockpitStopColumns
            );

            if (fleetPlannerSettingsString) {
                const fleetPlannerSettings: FleetPlannerSettings = JSON.parse(
                    fleetPlannerSettingsString
                );
                dispatch(
                    setFleetPlannerSettings({
                        ...fleetPlannerSettings,
                        showAllDriversInCompany: user.company_entity
                            ?.show_location_orders
                            ? fleetPlannerSettings.showAllDriversInCompany
                            : 0,
                    })
                );
            }

            if (cockpitStopColumnsString) {
                const cockpitStopColumns: CockpitState["stopColumns"] =
                    JSON.parse(cockpitStopColumnsString);
                const currentColumns = store.getState().cockpit.stopColumns;
                dispatch(
                    setStopColumns({
                        ...currentColumns,
                        ...cockpitStopColumns,
                    })
                );
            }

            setIsAuthLoading(false);
        },
        [dispatch, queryClient, t]
    );

    useEffect(() => {
        const memberToken = localStorage.getItem(
            LOCAL_STORAGE_KEY.MemberstackToken
        );

        authSetup(!!memberToken);
    }, [authSetup]);

    useEffect(() => {
        const listener = memberstack.onAuthChange(
            (member: MemberstackMember | null) => authSetup(!!member)
        );

        return () => {
            listener.unsubscribe();
        };
    }, [authSetup]);

    const isLoggedIn = !!user && !isAuthLoading;
    const userHasCompany = isLoggedIn && !!user.company_entity;
    const userHasLocation = isLoggedIn && !!user.location_entity;
    const isLoggedOut = !user && !isAuthLoading;

    const startPage = useMemo(() => {
        if (!userHasCompany || !userHasLocation) {
            return ROUTE.Settings;
        }

        if (
            user.location_entity?.mt_organization ||
            user.location_entity?.use_alrik_driver_app
        ) {
            if (features?.read_fleet_planner) {
                return ROUTE.Fleet;
            }

            if (features?.create_internal_order) {
                return ROUTE.FleetManualOrder;
            }

            if (features?.read_fleet_planner_calendar) {
                return ROUTE.FleetCalendar;
            }
        }

        return ROUTE.Orders;
    }, [
        features?.create_internal_order,
        features?.read_fleet_planner,
        features?.read_fleet_planner_calendar,
        user?.location_entity?.mt_organization,
        user?.location_entity?.use_alrik_driver_app,
        userHasCompany,
        userHasLocation,
    ]);

    let routes = (
        <div className="app-loading">
            <Spinner padding="10px" />
        </div>
    );

    if (isLoggedIn) {
        routes = (
            <Layout excludeNavbarRoutes={[ROUTE.FleetCalendarFullPage]}>
                <ErrorBoundary
                    fallback={({ error, resetError }) => (
                        <ErrorComponent
                            error={error as Error}
                            resetError={resetError}
                        />
                    )}
                >
                    <Routes>
                        <Route
                            path="*"
                            element={<Navigate replace to={startPage} />}
                        />
                        {userHasCompany && userHasLocation && (
                            <>
                                {isRouteAccessible(ROUTE.Orders) && (
                                    <Route
                                        path={ROUTE.Orders}
                                        element={<Orders />}
                                    >
                                        <Route
                                            path={ROUTE.Orders}
                                            element={
                                                <Navigate
                                                    replace
                                                    to={ROUTE.NewOrder}
                                                />
                                            }
                                        />
                                        {isRouteAccessible(ROUTE.NewOrder) && (
                                            <Route
                                                path={ROUTE.NewOrder}
                                                element={<NewOrder />}
                                            />
                                        )}
                                        {isRouteAccessible(
                                            ROUTE.ActiveOrders
                                        ) && (
                                            <Route
                                                path={ROUTE.ActiveOrders}
                                                element={<ActiveOrders />}
                                            />
                                        )}
                                        {isRouteAccessible(
                                            ROUTE.FulfilledOrders
                                        ) && (
                                            <Route
                                                path={ROUTE.FulfilledOrders}
                                                element={<FulfilledOrders />}
                                            />
                                        )}
                                    </Route>
                                )}
                                {isRouteAccessible(ROUTE.Fleet) && (
                                    <Route
                                        path={ROUTE.Fleet}
                                        element={<Fleet />}
                                    >
                                        <Route
                                            path={ROUTE.Fleet}
                                            element={
                                                <Navigate
                                                    replace
                                                    to={ROUTE.Cockpit}
                                                />
                                            }
                                        />
                                        {isRouteAccessible(ROUTE.Cockpit) && (
                                            <Route
                                                path={ROUTE.Cockpit}
                                                element={<BigVolume />}
                                            />
                                        )}
                                        {isRouteAccessible(
                                            ROUTE.ActiveFleet
                                        ) && (
                                            <Route
                                                path={ROUTE.ActiveFleet}
                                                element={<ActiveFleet />}
                                            />
                                        )}
                                        {isRouteAccessible(
                                            ROUTE.FleetCalendar
                                        ) && (
                                            <Route
                                                path={ROUTE.FleetCalendar}
                                                element={<FleetCalendar />}
                                            />
                                        )}
                                        {isRouteAccessible(
                                            ROUTE.FleetCalendarFullPage
                                        ) && (
                                            <Route
                                                path={
                                                    ROUTE.FleetCalendarFullPage
                                                }
                                                element={
                                                    <FleetCalendar
                                                        fullPage
                                                        autoRefresh
                                                    />
                                                }
                                            />
                                        )}
                                        {isRouteAccessible(
                                            ROUTE.FulfilledFleetTours
                                        ) && (
                                            <Route
                                                path={ROUTE.FulfilledFleetTours}
                                                element={
                                                    <FulfilledFleetTours />
                                                }
                                            />
                                        )}
                                        {isRouteAccessible(ROUTE.Drivers) && (
                                            <Route
                                                path={ROUTE.Drivers}
                                                element={<Drivers />}
                                            />
                                        )}
                                        {isRouteAccessible(ROUTE.Dashboard) && (
                                            <Route
                                                path={ROUTE.Dashboard}
                                                element={<FleetDashboard />}
                                            />
                                        )}
                                        {isRouteAccessible(
                                            ROUTE.FleetSettings
                                        ) && (
                                            <Route
                                                path={ROUTE.FleetSettings}
                                                element={<FleetSettings />}
                                            />
                                        )}
                                        {isRouteAccessible(
                                            ROUTE.FleetManualOrder
                                        ) && (
                                            <Route
                                                path={ROUTE.FleetManualOrder}
                                                element={<FleetManualOrder />}
                                            />
                                        )}
                                    </Route>
                                )}
                            </>
                        )}
                        {isRouteAccessible(ROUTE.Support) && (
                            <Route path={ROUTE.Support} element={<Support />} />
                        )}
                        {isRouteAccessible(ROUTE.Settings) && (
                            <Route
                                path={ROUTE.Settings}
                                element={<Settings />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.PublicOrder) && (
                            <Route
                                path={ROUTE.PublicOrder}
                                element={<PublicOrder shouldUseQueryParams />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.PublicOrder) && (
                            <Route
                                path={ROUTE.PublicOrder + "/:publicId"}
                                element={<PublicOrder />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.TrackingRedirect) && (
                            <Route
                                path={ROUTE.TrackingRedirect}
                                element={
                                    <TrackingRedirect shouldUseQueryParams />
                                }
                            />
                        )}
                        {isRouteAccessible(ROUTE.TrackingRedirect) && (
                            <Route
                                path={ROUTE.TrackingRedirect + "/:trackingCode"}
                                element={<TrackingRedirect />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.Tracking) && (
                            <Route
                                path={ROUTE.Tracking}
                                element={<Tracking />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.ExternalOrder) && (
                            <Route
                                path={ROUTE.ExternalOrder}
                                element={
                                    <ExternalCarrierOrder
                                        shouldUseQueryParams
                                    />
                                }
                            />
                        )}
                        {isRouteAccessible(ROUTE.ExternalOrder) && (
                            <Route
                                path={ROUTE.ExternalOrder + "/:externalId"}
                                element={<ExternalCarrierOrder />}
                            />
                        )}
                    </Routes>
                </ErrorBoundary>
            </Layout>
        );
    }

    if (isLoggedOut) {
        routes = (
            <NonAuthLayout>
                <ErrorBoundary
                    fallback={({ error, resetError }) => (
                        <ErrorComponent
                            error={error as Error}
                            resetError={resetError}
                        />
                    )}
                >
                    <Routes>
                        <Route
                            path="*"
                            element={<Navigate replace to={ROUTE.Login} />}
                        />
                        {isRouteAccessible(ROUTE.Login) && (
                            <Route path={ROUTE.Login} element={<Login />} />
                        )}
                        {isRouteAccessible(ROUTE.Register) && (
                            <Route
                                path={ROUTE.Register}
                                element={<Register />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.EmailSent) && (
                            <Route
                                path={ROUTE.EmailSent}
                                element={<EmailSent />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.AuthCode) && (
                            <Route
                                path={ROUTE.AuthCode}
                                element={<AuthCode />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.Setup) && (
                            <Route path={ROUTE.Setup} element={<Setup />} />
                        )}
                        {isRouteAccessible(ROUTE.ResetPassword) && (
                            <Route
                                path={ROUTE.ResetPassword}
                                element={<ResetPassword />}
                            />
                        )}

                        {isRouteAccessible(ROUTE.PublicOrder) && (
                            <Route
                                path={ROUTE.PublicOrder}
                                element={<PublicOrder shouldUseQueryParams />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.PublicOrder) && (
                            <Route
                                path={ROUTE.PublicOrder + "/:publicId"}
                                element={<PublicOrder />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.TrackingRedirect) && (
                            <Route
                                path={ROUTE.TrackingRedirect}
                                element={
                                    <TrackingRedirect shouldUseQueryParams />
                                }
                            />
                        )}
                        {isRouteAccessible(ROUTE.TrackingRedirect) && (
                            <Route
                                path={ROUTE.TrackingRedirect + "/:trackingCode"}
                                element={<TrackingRedirect />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.Tracking) && (
                            <Route
                                path={ROUTE.Tracking}
                                element={<Tracking />}
                            />
                        )}
                        {isRouteAccessible(ROUTE.ExternalOrder) && (
                            <Route
                                path={ROUTE.ExternalOrder}
                                element={
                                    <ExternalCarrierOrder
                                        shouldUseQueryParams
                                    />
                                }
                            />
                        )}
                        {isRouteAccessible(ROUTE.ExternalOrder) && (
                            <Route
                                path={ROUTE.ExternalOrder + "/:externalId"}
                                element={<ExternalCarrierOrder />}
                            />
                        )}
                    </Routes>
                </ErrorBoundary>
            </NonAuthLayout>
        );
    }

    return routes;
}

export default App;
