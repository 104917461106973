import { ChangeEvent, CSSProperties, useCallback } from "react";
import "./style.scss";

type Props = {
    value?: string;
    onChange?: (value: string) => void;
    label?: string | null;
    labelColor?: string;
    placeholder?: string | null;
    tooltip?: string | null;
    width?: string;
    completed?: boolean;
    textareaStyle?: CSSProperties;
};

function Textarea(props: Props) {
    const { onChange } = props;

    const textareaClasses = ["textarea-wrapper", "text-sm"];
    if (props.completed) {
        textareaClasses.push("completed");
    }

    const onChangeHandler = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement>) => {
            if (onChange) {
                onChange(e.target.value);
            }
        },
        [onChange]
    );

    return (
        <label
            className={textareaClasses.join(" ")}
            style={{ width: props.width }}
        >
            {(props.label || props.tooltip) && (
                <div
                    className="textarea-label"
                    style={{ color: props.labelColor }}
                >
                    {props.label && (
                        <span className="text-xs">{props.label}</span>
                    )}
                </div>
            )}
            <textarea
                className="textarea text-xs"
                placeholder={props.placeholder || undefined}
                value={props.value}
                onChange={onChangeHandler}
                style={props.textareaStyle}
            />
        </label>
    );
}

export default Textarea;
