import { useMemo } from "react";
import { Column } from "..";
import Tooltip from "../../../components/UI/Tooltip";
import "./style.scss";
import { TooltipAlignment } from "../../../shared/types/internal";

type Props<T> = {
    column: Column<T>;
    item: T;
    value: JSX.Element | null;
    tooltipAlignment?: TooltipAlignment;
};

function TableCell<T>(props: Props<T>) {
    const tooltipText = useMemo(
        () => props.column.getTooltipText?.(props.item, props.column) || null,
        [props.column, props.item]
    );

    return (
        <td
            className="table-cell"
            style={{
                width: props.column.width,
                minWidth: props.column.width,
                maxWidth: props.column.width,
            }}
        >
            {tooltipText ? (
                <Tooltip
                    anchor={props.value}
                    alignment={
                        props.tooltipAlignment || {
                            x: "right",
                            y: "bottom",
                        }
                    }
                >
                    <p className="tooltip-text">{tooltipText}</p>
                </Tooltip>
            ) : (
                props.value
            )}
        </td>
    );
}

export default TableCell;
