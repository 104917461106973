import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
    AdvancedMarker,
    useMap,
    useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import Icon from "../../../components/UI/Icon";
import MapInfoWindow from "../../../components/UI/MapInfoWindow";
import { MapPoint } from "../../../shared/types/internal";
import "./style.scss";

type Props = {
    location: google.maps.LatLngLiteral;
    linePoint?: MapPoint["linePoint"];
    onClick?: (event: google.maps.MapMouseEvent) => void;
    zIndex?: number;
    label?: string;
    icon?: IconDefinition;
    infoWindowData?: {
        title: string;
        description?: string;
        description2?: string;
    };
    statusLabel?: string;
    statusIcon?: {
        icon: IconDefinition;
        color: string;
    };
    color?: string;
};

function MapMarker(props: Props) {
    const map = useMap();
    const maps = useMapsLibrary("maps");
    const [showInfoWindow, setShowInfoWindow] = useState(false);

    const motionVariants = {
        hidden: { opacity: 0, scale: 0.5 },
        visible: { opacity: 1, scale: 1 },
    };

    const polylinePath = useMemo(() => {
        if (!props.linePoint) return [];

        return [
            {
                lat: +props.location.lat!,
                lng: +props.location.lng!,
            },
            {
                lat: +props.linePoint.lat!,
                lng: +props.linePoint.lng!,
            },
        ];
    }, [props.linePoint, props.location.lat, props.location.lng]);

    useEffect(() => {
        if (!map || !maps) return;
        const polyline = new maps.Polyline({
            path: polylinePath,
            map,
        });

        return () => polyline.setMap(null);
    }, [map, maps, polylinePath]);

    return (
        <>
            <AdvancedMarker
                position={props.location}
                onClick={props.onClick}
                onMouseEnter={() => setShowInfoWindow(true)}
                onMouseLeave={() => setShowInfoWindow(false)}
                zIndex={showInfoWindow ? Number.MAX_SAFE_INTEGER : props.zIndex}
            >
                <AnimatePresence>
                    {showInfoWindow && props.infoWindowData && (
                        <MapInfoWindow
                            statusIcon={props.statusIcon}
                            statusLabel={props.statusLabel}
                            description={props.infoWindowData.description}
                            description2={props.infoWindowData.description2}
                        />
                    )}
                </AnimatePresence>
                <motion.div
                    className="map-marker"
                    variants={motionVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    whileHover={{ scale: 1.2 }}
                    style={{ backgroundColor: props.color }}
                >
                    {props.icon && (
                        <Icon
                            icon={props.icon}
                            color="var(--color-pure-white)"
                        />
                    )}
                    {props.label && (
                        <span className="text-2xs">{props.label}</span>
                    )}
                    {props.statusIcon && (
                        <div className="map-marker-status">
                            <Icon
                                icon={props.statusIcon.icon}
                                size="xs"
                                color={props.statusIcon.color}
                            />
                        </div>
                    )}
                </motion.div>
            </AdvancedMarker>
            {props.linePoint && (
                <AdvancedMarker
                    position={{
                        lat: +props.linePoint.lat,
                        lng: +props.linePoint.lng,
                    }}
                    zIndex={props.linePoint.zIndex}
                >
                    <motion.div
                        className="map-marker"
                        variants={motionVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        {props.linePoint.icon && (
                            <Icon
                                icon={props.linePoint.icon}
                                color="var(--color-pure-white)"
                            />
                        )}
                    </motion.div>
                </AdvancedMarker>
            )}
        </>
    );
}

export default MapMarker;
