import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Banner from "../../components/widgets/Banner";
import LowVersionBanner from "../../components/widgets/LowVersionBanner";
import Navbar from "../../containers/Navbar";
import { ReduxState } from "../../shared/types/redux";
import "./style.scss";

type Props = {
    children: React.ReactNode;
    excludeNavbarRoutes?: string[];
};

function Layout(props: Props) {
    const location = useLocation();
    const hasLowVersion = useSelector(
        (state: ReduxState) => state.auth.hasLowVersion
    );

    return (
        <div className="app-wrapper">
            <Banner
                hide={!!props.excludeNavbarRoutes?.includes(location.pathname)}
            />
            {hasLowVersion && <LowVersionBanner />}
            {props.excludeNavbarRoutes?.includes(location.pathname) ? null : (
                <Navbar />
            )}
            <div className="content">{props.children}</div>
        </div>
    );
}

export default Layout;
