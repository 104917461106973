import {
    faChevronDown,
    faChevronRight,
    faFilter,
    faFilterSlash,
    faX,
} from "@fortawesome/pro-regular-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/Button";
import IconButton from "../../components/buttons/IconButton";
import TextButton from "../../components/buttons/TextButton";
import Checkbox from "../../components/inputs/Checkbox";
import Switch from "../../components/inputs/Switch";
import Icon from "../../components/UI/Icon";
import Modal from "../../hoc/Modal";
import useStopFilter from "../../hooks/functionality/useStopFilter";
import { StopTableData } from "../../pages/BigVolume";
import { StopDraft } from "../../shared/types/api";
import { KeyString, StopFilter } from "../../shared/types/internal";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import "./style.scss";

type FilterSections = keyof StopFilter;

type Props = {
    stops: StopDraft[];
    stopTableData: StopTableData[];
    activeFilter: StopFilter;
    onFilterChange: (key: FilterSections, value: string[]) => void;
    onClearFilter: () => void;
    showOnlyUnresolved: boolean;
    onShowOnlyUnresolvedChange: (value: boolean) => void;
};

const MAX_INITAL_ITEMS = 5;

function StopFilterModal(props: Props) {
    const { t } = useTranslation();
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
    const [openSection, setOpenSection] = useState<FilterSections | null>(null);
    const [showFullListSection, setShowFullListSection] =
        useState<FilterSections | null>(null);

    const { filterStops } = useStopFilter({
        activeFilter: props.activeFilter,
        stopSearch: "",
        excludeFilter: openSection || undefined,
    });

    const { tagsCount, pickupCityCount, dropoffCityCount } = useMemo(() => {
        const tagsCount: KeyString<number> = {};
        const pickupCityCount: KeyString<number> = {};
        const dropoffCityCount: KeyString<number> = {};

        for (let i = 0; i < props.stops.length; i++) {
            const stop = props.stops[i];

            if (stop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
                if (stop.tags) {
                    for (let j = 0; j < stop.tags.length; j++) {
                        const tag = stop.tags[j];
                        tagsCount[tag] = 0;
                    }
                }

                const city = stop.city || stop.to_location;
                pickupCityCount[city] = 0;
            }

            if (stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff) {
                const city = stop.city || stop.to_location;
                dropoffCityCount[city] = 0;
            }
        }

        const filteredList = props.stopTableData.filter(filterStops);

        for (let i = 0; i < filteredList.length; i++) {
            const { stops, orderDraft } = filteredList[i];

            if (stops) {
                if (stops.pickup.tags) {
                    for (let j = 0; j < stops.pickup.tags.length; j++) {
                        const tag = stops.pickup.tags[j];
                        tagsCount[tag] = (tagsCount[tag] || 0) + 1;
                    }
                }

                const pickupCity =
                    stops.pickup.city || stops.pickup.to_location;
                const dropoffCity =
                    stops.dropoff.city || stops.dropoff.to_location;

                if (pickupCity) {
                    pickupCityCount[pickupCity] =
                        (pickupCityCount[pickupCity] || 0) + 1;
                }

                if (dropoffCity) {
                    dropoffCityCount[dropoffCity] =
                        (dropoffCityCount[dropoffCity] || 0) + 1;
                }
            }

            if (orderDraft) {
                if (orderDraft.tags) {
                    for (let j = 0; j < orderDraft.tags.length; j++) {
                        const tag = orderDraft.tags[j];
                        tagsCount[tag] = (tagsCount[tag] || 0) + 1;
                    }
                }
            }
        }

        return {
            tagsCount,
            pickupCityCount,
            dropoffCityCount,
        };
    }, [filterStops, props.stopTableData, props.stops]);

    const activeFilterCount = useMemo(() => {
        return (
            Object.values(props.activeFilter).reduce(
                (acc, value) => acc + value.length,
                0
            ) + (props.showOnlyUnresolved ? 1 : 0)
        );
    }, [props.activeFilter, props.showOnlyUnresolved]);

    const isAnyFilterActive = useMemo(() => {
        return (
            Object.values(props.activeFilter).some((v) => v.length > 0) ||
            props.showOnlyUnresolved
        );
    }, [props.activeFilter, props.showOnlyUnresolved]);

    const filterSections: {
        key: FilterSections;
        title: string;
        count: KeyString<number>;
    }[] = useMemo(() => {
        return [
            {
                key: "tags",
                title: t("bigVolume.tag"),
                count: tagsCount,
            },
            {
                key: "pickupCities",
                title: t("bigVolume.loading"),
                count: pickupCityCount,
            },
            {
                key: "dropoffCities",
                title: t("bigVolume.unloading"),
                count: dropoffCityCount,
            },
        ];
    }, [dropoffCityCount, pickupCityCount, t, tagsCount]);

    const sortSections = useCallback((aCount: number, bCount: number) => {
        return bCount - aCount;
    }, []);

    const motionVariants = {
        hidden: { opacity: 0, scale: 0 },
        visible: { opacity: 1, scale: 1 },
    };

    return (
        <Modal
            buttonElement={(ref) => (
                <>
                    <AnimatePresence>
                        {activeFilterCount > 0 && (
                            <motion.button
                                type="button"
                                className="filter-count"
                                variants={motionVariants}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                                onClick={() => {
                                    props.onClearFilter();
                                    props.onShowOnlyUnresolvedChange(false);
                                }}
                            >
                                <p className="text-2xs">{activeFilterCount}</p>
                                <div className="clear-button">
                                    <Icon icon={faX} size="xs" />
                                </div>
                            </motion.button>
                        )}
                    </AnimatePresence>
                    <Button
                        ref={ref}
                        leadingIcon={faFilter}
                        label={t("bigVolume.filter")}
                        variant={"secondary"}
                        onClick={() => setIsFilterPopupOpen(true)}
                    />
                </>
            )}
            isOpen={isFilterPopupOpen}
            onClose={() => setIsFilterPopupOpen(false)}
            align="right"
            width="300px"
        >
            <div className="stop-filter-modal">
                <div className="top">
                    <div className="header">
                        <div className="title">
                            <Icon icon={faFilter} size="lg" />
                            <p className="text-sm">{t("bigVolume.filter")}</p>
                        </div>
                        {isAnyFilterActive && (
                            <TextButton
                                variant={"secondary"}
                                leadingIcon={faFilterSlash}
                                label={t("bigVolume.clearAllFilter")}
                                onClick={() => {
                                    props.onClearFilter();
                                    props.onShowOnlyUnresolvedChange(false);
                                }}
                            />
                        )}
                    </div>
                    <Switch
                        isActive={props.showOnlyUnresolved}
                        onChange={(value) => {
                            props.onClearFilter();
                            props.onShowOnlyUnresolvedChange(value);
                        }}
                        label={t("bigVolume.showOnlyOrderDrafts")}
                        style={{
                            justifyContent: "space-between",
                        }}
                        labelStyle={{
                            color: "var(--color-pure-black)",
                        }}
                    />
                </div>
                {filterSections.map((section) => (
                    <section key={section.key}>
                        <div className="top">
                            <IconButton
                                iconProps={{
                                    icon:
                                        openSection === section.key
                                            ? faChevronDown
                                            : faChevronRight,
                                    color: "var(--color-neutral-600)",
                                    fixedWidth: true,
                                }}
                                label={section.title}
                                onClick={() =>
                                    setOpenSection((state) =>
                                        state === section.key
                                            ? null
                                            : section.key
                                    )
                                }
                            />
                            {props.activeFilter[section.key].length > 0 && (
                                <TextButton
                                    variant={"secondary"}
                                    leadingIcon={faFilterSlash}
                                    label={t("bigVolume.clearFilter")}
                                    onClick={() => {
                                        props.onFilterChange(section.key, []);
                                    }}
                                />
                            )}
                        </div>

                        {openSection === section.key && (
                            <div className="option-list">
                                {Object.keys(section.count)
                                    .sort((a, b) =>
                                        sortSections(
                                            section.count[a],
                                            section.count[b]
                                        )
                                    )
                                    .filter(
                                        (_, i) =>
                                            showFullListSection ===
                                                section.key ||
                                            i < MAX_INITAL_ITEMS
                                    )
                                    .map((value) => (
                                        <Checkbox
                                            key={value}
                                            checked={props.activeFilter[
                                                section.key
                                            ].includes(value)}
                                            onChange={(newChecked) =>
                                                props.onFilterChange(
                                                    section.key,
                                                    newChecked
                                                        ? [
                                                              ...props
                                                                  .activeFilter[
                                                                  section.key
                                                              ],
                                                              value,
                                                          ]
                                                        : props.activeFilter[
                                                              section.key
                                                          ].filter(
                                                              (v) => v !== value
                                                          )
                                                )
                                            }
                                            disabled={
                                                section.count[value] === 0
                                            }
                                            label={value.toString()}
                                            subLabel={section.count[
                                                value
                                            ].toString()}
                                        />
                                    ))}
                                {showFullListSection === section.key ? (
                                    <TextButton
                                        variant={"primary"}
                                        label={t("bigVolume.showLessFilter")}
                                        onClick={() => {
                                            setShowFullListSection(null);
                                        }}
                                    />
                                ) : Object.keys(section.count).length >
                                  MAX_INITAL_ITEMS ? (
                                    <TextButton
                                        variant={"primary"}
                                        label={t("bigVolume.showMoreFilter")}
                                        onClick={() => {
                                            setShowFullListSection(section.key);
                                        }}
                                    />
                                ) : null}
                            </div>
                        )}
                    </section>
                ))}
            </div>
        </Modal>
    );
}

export default StopFilterModal;
