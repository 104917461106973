import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
    addStopsToTour,
    removeStopFromTour,
    reorderTour,
} from "../../api/tours";
import { StopDraft, TourStop } from "../../shared/types/api";
import { FleetPlannerReloadDataHandler } from "../../shared/types/internal";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import useFleetPlannerValidation from "./useFleetPlannerValidation";
import { generateUUID } from "../../shared/utility/misc";

function useFleetPlannerDragNEW({
    reloadData,
}: {
    reloadData: FleetPlannerReloadDataHandler;
}) {
    const { t } = useTranslation();

    const [stopIdsLoading, setStopIdsLoading] = useState<number[]>([]);

    const { validateReorderStopList, validateStopChangeStopList } =
        useFleetPlannerValidation();

    const getReorderStopList = useCallback(
        ({
            movedStop,
            destinationIndex,
            sourceIndex,
            stopList,
        }: {
            movedStop: TourStop;
            destinationIndex: number;
            sourceIndex: number;
            stopList: TourStop[];
        }) => {
            let groupedStops = [movedStop];

            if (movedStop.motion_tools_stop_group) {
                groupedStops = stopList
                    .filter((s) => s.stop_type_id !== STOP_DRAFT_TYPE.Pause)
                    .filter(
                        (stop) =>
                            stop.motion_tools_stop_group ===
                            movedStop.motion_tools_stop_group
                    );
            }

            let newStopListOrder = structuredClone(stopList);

            // Mutation section

            // Insert and remove the moved stop
            newStopListOrder.splice(
                destinationIndex,
                0,
                ...newStopListOrder.splice(sourceIndex, 1)
            );

            // Remove the grouped stops except the moved stop
            newStopListOrder = newStopListOrder.filter((stop) => {
                if (stop.id === movedStop.id) {
                    return true;
                }

                return !groupedStops.some(
                    (groupedStop) => groupedStop.id === stop.id
                );
            });

            // Find the new index of the moved stop
            const newIndexOfMovedStop = newStopListOrder.findIndex(
                (stop) => stop.id === movedStop.id
            );

            const groupedStopsWithoutMovedStop = groupedStops.filter(
                (groupedStop) => groupedStop.id !== movedStop.id
            );

            //Insert the grouped stops after the moved stop
            newStopListOrder.splice(
                newIndexOfMovedStop + 1,
                0,
                ...groupedStopsWithoutMovedStop
            );

            // Mutation section end

            return newStopListOrder;
        },
        []
    );

    const getStopsChangeListData = useCallback(
        ({
            movedStop,
            destinationIndex,
            sourceList,
            destinationList,
        }: {
            movedStop: TourStop;
            destinationIndex: number;
            sourceList: TourStop[];
            destinationList: TourStop[];
        }) => {
            let stopsToAdd: TourStop[] = [];

            stopsToAdd = sourceList.filter(
                (s) => s.group_id === movedStop.group_id
            );

            const newSourceStopList = sourceList.filter(
                (s) => !stopsToAdd.some((stopToAdd) => stopToAdd.id === s.id)
            );

            const newDestinationStopList = destinationList.splice(
                destinationIndex,
                0,
                ...stopsToAdd
            );

            return {
                stopsToAdd,
                stopToRemove: movedStop,
                newSourceStopList,
                newDestinationStopList,
            };
        },
        []
    );

    const getReorderStopListWithValidation = useCallback(
        ({
            movedStop,
            stopList,
            destinationIndex,
            sourceIndex,
            allowMoveGroupedStops,
        }: {
            movedStop: TourStop;
            stopList: TourStop[];
            destinationIndex: number;
            sourceIndex: number;
            allowMoveGroupedStops?: boolean;
        }) => {
            const validationMessage = validateReorderStopList({
                movedStop,
                stopList,
                destinationIndex,
                allowMoveGroupedStops,
            });

            const newStopList = getReorderStopList({
                movedStop,
                stopList,
                destinationIndex,
                sourceIndex,
            });

            return {
                validationMessage,
                newStopList,
            };
        },
        [getReorderStopList, validateReorderStopList]
    );

    const getStopsChangeListDataWithValidation = useCallback(
        ({
            movedStop,
            destinationIndex,
            sourceList,
            destinationList,
            dontAllowGroupedStopsChangeColumn,
        }: {
            movedStop: TourStop;
            destinationIndex: number;
            sourceList: TourStop[];
            destinationList: TourStop[];
            dontAllowGroupedStopsChangeColumn: boolean;
        }) => {
            const validationMessage = validateStopChangeStopList({
                movedStop,
                destinationIndex,
                destinationList,
                dontAllowGroupedStopsChangeColumn,
            });

            const newStopsChangeListData = getStopsChangeListData({
                movedStop,
                destinationIndex,
                sourceList,
                destinationList,
            });

            return {
                validationMessage,
                ...newStopsChangeListData,
            };
        },
        [getStopsChangeListData, validateStopChangeStopList]
    );

    const reorderWithApiHandler = useCallback(
        async ({
            movedStop,
            stopList,
            destinationIndex,
            sourceIndex,
            tourId,
            allowMoveGroupedStops,
        }: {
            movedStop: TourStop;
            stopList: TourStop[];
            destinationIndex: number;
            sourceIndex: number;
            tourId: number;
            allowMoveGroupedStops?: boolean;
        }) => {
            const { validationMessage, newStopList } =
                getReorderStopListWithValidation({
                    movedStop,
                    stopList,
                    destinationIndex,
                    sourceIndex,
                    allowMoveGroupedStops,
                });

            if (validationMessage) {
                toast.error(t(validationMessage));
                return;
            }
            const stopAtNewIndex = stopList[destinationIndex];
            if (stopAtNewIndex) {
                setStopIdsLoading((state) => state.concat([stopAtNewIndex.id]));
            }
            setStopIdsLoading((state) => state.concat([movedStop.id]));
            try {
                await reorderTour({
                    tour_id: tourId,
                    stop_draft_ids: newStopList.map((stop) => stop.id),
                });
                await reloadData("tours");
            } catch (error: any) {
                console.log(error);
                if (error.response?.data.error_token) {
                    toast.error(t(error.response?.data.error_token));
                } else {
                    toast.error(t("errorMessage.tourChangesSavedError"));
                }
            } finally {
                await reloadData("tours", "stop-drafts");
                setStopIdsLoading((state) =>
                    state.filter(
                        (id) => id !== movedStop.id && id !== stopAtNewIndex?.id
                    )
                );
            }
        },
        [getReorderStopListWithValidation, reloadData, t]
    );

    const changeListsWithApiHandler = useCallback(
        async ({
            movedStop,
            destinationIndex,
            sourceList,
            destinationList,
            destinationTourId,
            dontAllowGroupedStopsChangeColumn,
        }: {
            movedStop: TourStop;
            destinationIndex: number;
            sourceList: TourStop[];
            destinationList: TourStop[];
            destinationTourId: number;
            dontAllowGroupedStopsChangeColumn: boolean;
        }) => {
            const { validationMessage, stopsToAdd, stopToRemove } =
                getStopsChangeListDataWithValidation({
                    movedStop,
                    destinationIndex,
                    sourceList,
                    destinationList,
                    dontAllowGroupedStopsChangeColumn,
                });

            if (validationMessage) {
                toast.error(t(validationMessage));
                return;
            }

            const stopIdsForLoading = stopsToAdd
                .map((s) => s.id)
                .concat([stopToRemove.id]);

            setStopIdsLoading((state) => state.concat(stopIdsForLoading));

            try {
                if (stopToRemove.tour_id) {
                    await removeStopFromTour(stopToRemove.id);
                }

                await addStopsToTour({
                    tour_id: destinationTourId,
                    stop_draft_ids: stopsToAdd.map((s) => s.id),
                    index: destinationIndex,
                });
            } catch (error: any) {
                console.log(error);
                if (error.response?.data.error_token) {
                    toast.error(t(error.response?.data.error_token));
                } else {
                    toast.error(t("errorMessage.tourChangesSavedError"));
                }
            } finally {
                await reloadData("tours", "stop-drafts");
                setStopIdsLoading((state) =>
                    state.filter((id) => !stopIdsForLoading.includes(id))
                );
            }
        },
        [getStopsChangeListDataWithValidation, reloadData, t]
    );

    const sortTourPickupsFirst = useCallback((stopList: TourStop[]) => {
        const pickups = stopList.filter(
            (stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
        );
        const dropoffs = stopList.filter(
            (stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
        );
        const pauses = stopList.filter(
            (stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Pause
        );

        return pickups.concat(pauses).concat(dropoffs);
    }, []);

    const sortTourPickupsFirstWithApiHandler = useCallback(
        async ({
            stopList,
            tourId,
        }: {
            stopList: TourStop[];
            tourId: number;
        }) => {
            const newOrder = sortTourPickupsFirst(stopList);

            try {
                await reorderTour({
                    tour_id: tourId,
                    stop_draft_ids: newOrder.map((stop) => stop.id),
                });
                await reloadData("tours");
            } catch (error) {
                toast.error(t("errorMessage.tourChangesSavedError"));
            } finally {
                await reloadData("tours", "stop-drafts");
            }
        },
        [reloadData, sortTourPickupsFirst, t]
    );

    const consolidateStopList = useCallback((stopList: TourStop[]) => {
        const pickups = stopList
            .filter((s) => s.stop_type_id !== STOP_DRAFT_TYPE.Pause)
            .filter((stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Pickup)
            .sort((a, b) => a.to_location.localeCompare(b.to_location));

        const dropoffs = stopList
            .filter((s) => s.stop_type_id !== STOP_DRAFT_TYPE.Pause)
            .filter((stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff);

        const pauses = stopList.filter(
            (s) => s.stop_type_id === STOP_DRAFT_TYPE.Pause
        );

        const uniquePickups = [
            ...new Set(pickups.map((stop) => stop.to_location)),
        ];

        const stopsToGroup: StopDraft[][] = [];

        for (let i = 0; i < uniquePickups.length; i++) {
            const uniquePickup = uniquePickups[i];

            const pickupsWithAddress = pickups.filter(
                (stop) => stop.to_location === uniquePickup
            );

            if (pickupsWithAddress.length > 1) {
                const groupId = generateUUID();
                stopsToGroup.push(
                    pickupsWithAddress.map((s) => ({
                        ...s,
                        motion_tools_stop_group: groupId,
                    }))
                );
            }
        }

        const flatStopsToGroup = stopsToGroup.flat();

        const newPickups = pickups
            .map((s) => {
                const groupPickup = flatStopsToGroup.find(
                    (sp) => sp.id === s.id
                );
                if (groupPickup) {
                    return groupPickup;
                }
                return s;
            })
            .sort((a, b) => a.to_location.localeCompare(b.to_location));

        const newTourOrder = [...newPickups, ...pauses, ...dropoffs];

        return {
            stopsToGroup,
            newTourOrder,
        };
    }, []);

    return {
        getReorderStopListWithValidation,
        getStopsChangeListDataWithValidation,

        changeListsWithApiHandler,
        reorderWithApiHandler,

        sortTourPickupsFirst,
        sortTourPickupsFirstWithApiHandler,

        consolidateStopList,

        stopIdsLoading,
    };
}

export default useFleetPlannerDragNEW;
