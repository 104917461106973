import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { TooltipAlignment } from "../../../shared/types/internal";
import Tooltip from "../Tooltip";
import "./style.scss";

type Props = {
    tooltipData?: {
        description: string;
        alignment: TooltipAlignment;
        descriptionMaxWidth?: string;
        minWidth?: string;
    };
} & FontAwesomeIconProps;

function Icon(props: Props) {
    const { tooltipData, ...iconProps } = props;

    if (!tooltipData) {
        return <FontAwesomeIcon {...iconProps} />;
    }

    return (
        <Tooltip
            alignment={tooltipData.alignment}
            anchor={<FontAwesomeIcon {...iconProps} />}
            minWidth={tooltipData.minWidth}
        >
            <p
                className="icon-tooltip-text"
                style={{ maxWidth: tooltipData.descriptionMaxWidth }}
            >
                {tooltipData.description}
            </p>
        </Tooltip>
    );
}

export default Icon;
