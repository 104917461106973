import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { useMemo } from "react";
import { TooltipAlignment } from "../../../shared/types/internal";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import "./style.scss";

type BadgeColor = {
    backgroundColor: string;
    color: string;
    iconColor: string;
};

type BadgeVariants = {
    neutral: BadgeColor;
    info: BadgeColor;
    success: BadgeColor;
    warning: BadgeColor;
    error: BadgeColor;
};

type Props = {
    title: string;
    variant: keyof BadgeVariants;
    icon?: IconDefinition;
    onClick?: () => void;
    style?: React.CSSProperties;
    iconClass?: string;
    tooltipData?: {
        description?: string;
        alignment?: TooltipAlignment;
        minWidth?: string;
    };
    hasTitleTooltip?: boolean;
};

const variants: BadgeVariants = {
    neutral: {
        backgroundColor: "var(--color-neutral-100)",
        color: "var(--color-neutral-700)",
        iconColor: "var(--color-neutral-400)",
    },
    info: {
        backgroundColor: "var(--color-blue-100)",
        color: "var(--color-blue-700)",
        iconColor: "var(--color-blue-500)",
    },
    success: {
        backgroundColor: "var(--color-primary-100)",
        color: "var(--color-primary-800)",
        iconColor: "var(--color-primary-500)",
    },
    warning: {
        backgroundColor: "var(--color-orange-100)",
        color: "var(--color-orange-700)",
        iconColor: "var(--color-orange-500)",
    },
    error: {
        backgroundColor: "var(--color-red-100)",
        color: "var(--color-red-700)",
        iconColor: "var(--color-red-500)",
    },
};

function Badge(props: Props) {
    const { backgroundColor, color, iconColor } = useMemo(
        () => variants[props.variant],
        [props.variant]
    );
    return (
        <Tooltip
            anchor={
                <div
                    className="badge text-xs"
                    style={{
                        backgroundColor,
                        color,
                        cursor: props.onClick ? "pointer" : "default",
                        ...props.style,
                    }}
                    onClick={props.onClick}
                >
                    {props.icon && (
                        <Icon
                            icon={props.icon}
                            color={iconColor}
                            fixedWidth
                            className={props.iconClass}
                        />
                    )}
                    <p>{props.title}</p>
                </div>
            }
            alignment={props.tooltipData?.alignment || { x: "right", y: "top" }}
            hide={!props.hasTitleTooltip && !props.tooltipData?.description}
        >
            <p className="badge-tooltip-text">
                {props.tooltipData?.description || props.title}
            </p>
        </Tooltip>
    );
}

export default Badge;
