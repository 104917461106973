import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Button from "../../../components/buttons/Button";
import AddressSearch from "../../../components/inputs/AddressSearch";
import Input from "../../../components/inputs/Input";
import { motion } from "framer-motion";
import { faTimer } from "@fortawesome/pro-regular-svg-icons";
import Textarea from "../../../components/inputs/Textarea";
import "./style.scss";

type Props = {
    onAddPause?: (data: {
        estimatedOffloadTime: number;
        toLocation?: string;
        driverInstructions?: string;
    }) => void;
    onClose?: () => void;
};

function AddPauseCard(props: Props) {
    const { t } = useTranslation();
    const { onAddPause } = props;

    const [duration, setDuration] = useState("00:30");
    const [toLocation, setToLocation] = useState("");
    const [driverInstructions, setDriverInstructions] = useState("");

    const addPauseHandler = useCallback(() => {
        const [hours, minutes] = duration.split(":").map((v) => +v);

        if (isNaN(hours) || isNaN(minutes) || (hours <= 0 && minutes <= 0)) {
            toast.error(t("errorMessage.invalidPauseDuration"));
            return;
        }

        const durationInMinutes = hours * 60 + minutes;

        onAddPause?.({
            estimatedOffloadTime: durationInMinutes,
            toLocation: toLocation || undefined,
            driverInstructions: driverInstructions || undefined,
        });
    }, [driverInstructions, duration, onAddPause, t, toLocation]);

    const motionVariants = {
        initial: { opacity: 0, x: -50 },
        enter: { opacity: 1, x: 0 },
    };

    return (
        <motion.div
            className="add-pause-card"
            variants={motionVariants}
            initial="initial"
            animate="enter"
            exit="initial"
        >
            <div className="inputs">
                <Input
                    label={t("bigVolume.addPauseTitle")}
                    type="time"
                    value={duration}
                    leadingIcon={faTimer}
                    onChange={setDuration}
                    style={{
                        width: "100%",
                    }}
                />

                <AddressSearch
                    value={toLocation}
                    onChange={setToLocation}
                    width="100%"
                    label={t("bigVolume.pauseToLocation")}
                    noMap={true}
                    placeholder={t("createOrder.fromAddressPlaceholder")}
                />

                <Textarea
                    value={driverInstructions}
                    onChange={setDriverInstructions}
                    label={t("bigVolume.pauseNote")}
                    width="100%"
                    placeholder={t("createOrder.driverInstructionsPlaceholder")}
                    textareaStyle={{ minHeight: "88px", resize: "none" }}
                />

                <div className="horizontal-group">
                    <Button
                        variant={"primary"}
                        onClick={addPauseHandler}
                        label={t("bigVolume.addPause")}
                        style={{ width: "100%" }}
                    />
                    <Button
                        variant={"secondary"}
                        onClick={props.onClose}
                        label={t("bigVolume.cancelPause")}
                        style={{ width: "100%" }}
                    />
                </div>
            </div>
        </motion.div>
    );
}

export default AddPauseCard;
