import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "../../components/buttons/IconButton";
import Modal from "../../hoc/Modal";
import "./style.scss";
import TableCell from "./TableCell";
import TableHeaders from "./TableHeaders";

export type Column<T> = {
    key: keyof T | string;
    title: string | ReactElement;
    getValue?: (item: T, column: Column<T>) => string | number;
    render?: (item: T, column: Column<T>) => ReactElement | null;
    hide?: boolean;
    onSort?: (column: Column<T>) => void;
    sortMode?: "asc" | "desc" | null;
    getHoverTitle?: (item: T, column: Column<T>) => string;
    width?: string;
    getTextColor?: (item: T, column: Column<T>) => string | undefined;
    getTooltipText?: (item: T, column: Column<T>) => string | undefined;
};

type Props<T> = {
    data: T[];
    columns: Column<T>[];
    maxHeight?: string;
    renderModalContent?: (item: T, close: () => void) => ReactElement | null;
    onRowClick?: (item: T) => void;
    emptyStateLabel?: string;
    rowHeight?: string;
    onHeaderPositionChange?: (column: Column<T>, newIndex: number) => void;
    noHeaderPositionChangeKeys?: string[];
};

function Table<T>(props: Props<T>) {
    const { renderModalContent, onRowClick } = props;
    const { t } = useTranslation();

    const [modalOpenIndex, setModalOpenIndex] = useState<number | null>(null);

    const tableClasses = ["table"];
    if (props.maxHeight) {
        tableClasses.push("scrollable");
    }

    const getValueToDisplay = useCallback((item: T, column: Column<T>) => {
        if (column.render) {
            return column.render(item, column);
        }

        if (column.getValue !== undefined) {
            const value = column.getValue(item, column);

            return (
                <span
                    className="value-wrapper"
                    title={column.getTooltipText ? undefined : `${value}`}
                    style={{
                        color: column.getTextColor?.(item, column) || undefined,
                    }}
                >
                    {value}
                </span>
            );
        }

        const value = item[column.key as keyof typeof item] as string;
        return (
            <span
                className="value-wrapper"
                title={column.getHoverTitle?.(item, column) || `${value}`}
                style={{
                    color: column.getTextColor?.(item, column) || undefined,
                }}
            >
                {item[column.key as keyof typeof item] as string}{" "}
            </span>
        );
    }, []);

    const rows = useMemo(
        () =>
            props.data.map((item, rowIndex) => {
                const modalContent = renderModalContent?.(item, () =>
                    setModalOpenIndex(null)
                );

                return (
                    <tr
                        key={`row-${rowIndex}`}
                        onClick={() => onRowClick?.(item)}
                        style={{
                            cursor: onRowClick ? "pointer" : undefined,
                            height: props.rowHeight,
                        }}
                    >
                        {props.columns
                            .filter((column) => !column.hide)
                            .map((column, columnIndex) => {
                                const value = getValueToDisplay(item, column);
                                return (
                                    <TableCell
                                        key={`cell-${column.key.toString()}`}
                                        column={column}
                                        item={item}
                                        value={value}
                                        tooltipAlignment={{
                                            x:
                                                columnIndex >=
                                                props.columns.length / 2
                                                    ? "right"
                                                    : "left",
                                            y:
                                                rowIndex > props.data.length / 2
                                                    ? "top"
                                                    : "bottom",
                                        }}
                                    />
                                );
                            })}
                        {renderModalContent && (
                            <td
                                style={{
                                    borderTop: "var(--border-primary)",
                                    borderBottom: "var(--border-primary)",
                                    padding: "0 16px",
                                }}
                            >
                                {modalContent && (
                                    <Modal
                                        buttonElement={(ref) => (
                                            <IconButton
                                                ref={ref}
                                                iconProps={{
                                                    icon: faCog,
                                                    color: "var(--color-neutral-500)",
                                                    fixedWidth: true,
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setModalOpenIndex((state) =>
                                                        state === rowIndex
                                                            ? null
                                                            : rowIndex
                                                    );
                                                }}
                                            />
                                        )}
                                        isOpen={modalOpenIndex === rowIndex}
                                        onClose={() => setModalOpenIndex(null)}
                                        align={"right"}
                                        topAlign={
                                            rowIndex > props.data.length / 2
                                        }
                                    >
                                        {modalContent}
                                    </Modal>
                                )}
                            </td>
                        )}
                    </tr>
                );
            }),
        [
            getValueToDisplay,
            modalOpenIndex,
            onRowClick,
            props.columns,
            props.data,
            props.rowHeight,
            renderModalContent,
        ]
    );

    return (
        <div
            className={tableClasses.join(" ")}
            style={{
                maxHeight: props.maxHeight,
                height: props.maxHeight,
            }}
        >
            <table>
                <TableHeaders
                    columns={props.columns}
                    hasModalContent={!!renderModalContent}
                    onHeaderPositionChange={props.onHeaderPositionChange}
                    noHeaderPositionChangeKeys={
                        props.noHeaderPositionChangeKeys
                    }
                />
                <tbody>
                    {rows.length === 0 ? (
                        <tr>
                            <td
                                colSpan={100}
                                align="center"
                                height={380}
                                style={{
                                    borderBottom: "none",
                                }}
                            >
                                <p
                                    style={{
                                        color: "var(--color-neutral-400)",
                                    }}
                                >
                                    {props.emptyStateLabel ||
                                        t("general.emptyState")}
                                </p>
                            </td>
                        </tr>
                    ) : (
                        rows
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default Table;
