import {
    faArrowRightFromBracket,
    faMapLocationDot,
    faPlusCircle,
    faUserCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Droppable } from "@hello-pangea/dnd";
import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import alrikLogo from "../../../assets/icons/alrik-logo.svg";
import Button from "../../../components/buttons/Button";
import IconButtonOLD from "../../../components/buttons/IconButtonOLD";
import MapButton from "../../../components/buttons/MapButton";
import Badge from "../../../components/UI/Badge";
import DotsModal, { DotsModalContent } from "../../../components/UI/DotsModal";
import Icon from "../../../components/UI/Icon";
import NewIcon from "../../../components/UI/NewIcon";
import Spinner from "../../../components/UI/Spinner";
import "./style.scss";

type Props = {
    id: string;
    title: string;
    cards: React.ReactNode[];
    modalControls?: DotsModalContent[];
    controls?: {
        start?: DotsModalContent;
        save?: DotsModalContent;
        create?: DotsModalContent;
        map?: DotsModalContent;
        hide?: DotsModalContent;
        dispatch?: DotsModalContent;
        fileUpload?: {
            onChange: (files: File[]) => void;
            isLoading?: boolean;
            disabled?: boolean;
        };
    };
    theme?:
        | "order-draft"
        | "unhandled"
        | "outside-fleet"
        | "alrik"
        | "changed-location";
    infoData?: {
        date?: string;
        endTime?: string;
        duration?: string;
        showEndTime?: boolean;
        peakWeight?: number;
    };
    driverOrganization?: string;
    isLoading?: boolean;
    isUndispatched?: boolean;
    hasUndispatchedChanges?: boolean;
    isDropDisabled?: boolean;
};

function FleetPlannerColumn(props: Props) {
    const { t } = useTranslation();

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (files) => {
            if (props.controls?.fileUpload?.onChange) {
                props.controls.fileUpload.onChange(files);
            }
        },
        disabled: props.controls?.fileUpload?.disabled,
        accept: {
            "application/pdf": [".pdf"],
            "image/jpeg": [".jpg", ".jpeg"],
            "image/png": [".png"],
        },
    });

    const fleetPlannerColumnClasses = ["fleet-planner-column"];
    if (props.theme) {
        fleetPlannerColumnClasses.push(props.theme);
    }
    if (props.isLoading) {
        fleetPlannerColumnClasses.push("loading");
    }

    const cardAmount = useMemo(() => {
        return props.cards.filter((c) => !!c).length;
    }, [props.cards]);

    const dropzoneText = useMemo(() => {
        switch (props.theme) {
            case "alrik":
                return t("fleetPlanner.dropzoneAlrik");

            case "outside-fleet":
                return t("fleetPlanner.dropzoneExternal");

            case "changed-location":
                return t("fleetPlanner.dropzoneChangedLocation");

            default:
                return "";
        }
    }, [props.theme, t]);

    return (
        <Droppable
            droppableId={props.id}
            isDropDisabled={props.isLoading || props.isDropDisabled}
        >
            {(provided) => (
                <div
                    className={fleetPlannerColumnClasses.join(" ")}
                    data-column-id={props.id}
                >
                    <div className="top">
                        <span className="title text-sm" title={props.title}>
                            {!props.theme ? (
                                <Icon
                                    icon={faUserCircle}
                                    size="lg"
                                    color="var(--color-neutral-400)"
                                    style={{ marginRight: "4px" }}
                                />
                            ) : null}
                            {props.theme === "alrik" && (
                                <img src={alrikLogo} alt="Alrik logo" />
                            )}
                            {props.title}
                            {props.theme === "order-draft" ||
                            props.theme === "unhandled" ? (
                                <Badge
                                    title={cardAmount.toString()}
                                    variant={
                                        cardAmount > 10
                                            ? "error"
                                            : cardAmount >= 5
                                            ? "warning"
                                            : "info"
                                    }
                                />
                            ) : null}
                        </span>
                        {props.theme === "outside-fleet" && (
                            <NewIcon
                                expiryDate={new Date("2024-06-07")}
                                style={{
                                    position: "absolute",
                                    top: "0px",
                                    left: "60px",
                                }}
                            />
                        )}
                        <div className="controls">
                            {props.controls?.hide && (
                                <IconButtonOLD
                                    icon="eye-slash"
                                    onClick={props.controls.hide.onClick}
                                    isLoading={props.controls.hide.isLoading}
                                    label={props.controls.hide.title}
                                    short
                                    style={{
                                        backgroundColor: "transparent",
                                        color: "var(--text-color)",
                                    }}
                                    disabled={props.controls.hide.disabled}
                                />
                            )}

                            {props.controls?.map &&
                                props.theme !== "unhandled" && (
                                    <div className="map-button-wrapper">
                                        <Button
                                            variant="secondary"
                                            leadingIcon={faMapLocationDot}
                                            onClick={props.controls.map.onClick}
                                            isLoading={
                                                props.controls.map.isLoading
                                            }
                                            label={props.controls.map.title}
                                            disabled={
                                                props.controls.map.disabled
                                            }
                                            style={
                                                props.controls.map.disabled
                                                    ? {
                                                          backgroundColor:
                                                              "transparent",
                                                          color: "var(--text-color)",
                                                      }
                                                    : undefined
                                            }
                                        />
                                    </div>
                                )}
                            {props.controls?.create && (
                                <Button
                                    variant="secondary"
                                    leadingIcon={faPlusCircle}
                                    label={props.controls.create.title}
                                    onClick={props.controls.create.onClick}
                                    isLoading={props.controls.create.isLoading}
                                    disabled={props.controls.create.disabled}
                                />
                            )}
                            {props.controls?.start && (
                                <Button
                                    variant="secondary"
                                    onClick={props.controls.start.onClick}
                                    isLoading={props.controls.start.isLoading}
                                    label={props.controls.start.title}
                                    disabled={props.controls.start.disabled}
                                />
                            )}
                            {props.controls?.dispatch && (
                                <Button
                                    variant="primary"
                                    leadingIcon={faArrowRightFromBracket}
                                    onClick={props.controls.dispatch.onClick}
                                    isLoading={
                                        props.controls.dispatch.isLoading
                                    }
                                    label={props.controls.dispatch.title}
                                    disabled={props.controls.dispatch.disabled}
                                />
                            )}
                            {props.controls?.save && (
                                <Button
                                    variant="secondary"
                                    onClick={props.controls.save.onClick}
                                    isLoading={props.controls.save.isLoading}
                                    label={props.controls.save.title}
                                    disabled={props.controls.save.disabled}
                                />
                            )}
                            {!!props.modalControls?.length && (
                                <DotsModal content={props.modalControls} />
                            )}
                        </div>
                    </div>
                    {props.driverOrganization && (
                        <div className="info">
                            <span>{props.driverOrganization}</span>
                        </div>
                    )}

                    {(props.hasUndispatchedChanges || props.isUndispatched) && (
                        <div className="attention">
                            {props.hasUndispatchedChanges && (
                                <span>
                                    {t("fleetPlanner.hasUndispatchedChanges")}
                                </span>
                            )}
                            {props.isUndispatched && (
                                <span>{t("fleetPlanner.isUndispatched")}</span>
                            )}
                        </div>
                    )}

                    {props.infoData && (
                        <div className="info">
                            <div className="info-row">
                                {props.infoData.date && (
                                    <span>
                                        {t("fleetPlanner.startTime")}{" "}
                                        {props.infoData.date}
                                    </span>
                                )}
                                {props.infoData.endTime &&
                                    props.infoData.showEndTime && (
                                        <span>
                                            {t("fleetPlanner.endTime")}{" "}
                                            {t("general.aprox")}{" "}
                                            {props.infoData.endTime}
                                        </span>
                                    )}
                                {props.infoData.duration &&
                                    !props.infoData.showEndTime && (
                                        <span>
                                            {t("fleetPlanner.estimatedTime")}{" "}
                                            {props.infoData.duration}
                                        </span>
                                    )}
                            </div>
                            <div className="info-row">
                                {!!props.infoData.peakWeight && (
                                    <span>
                                        {t("fleetPlanner.maxRunningWeight")}{" "}
                                        {props.infoData.peakWeight} kg
                                    </span>
                                )}
                            </div>
                        </div>
                    )}

                    <div
                        className="content"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {props.controls?.fileUpload && (
                            <div
                                className="file-dropzone"
                                {...getRootProps({
                                    style: {
                                        opacity: props.controls.fileUpload
                                            .disabled
                                            ? 0.5
                                            : 1,
                                    },
                                })}
                            >
                                <input {...getInputProps()} />
                                {props.controls.fileUpload.isLoading ? (
                                    <Spinner />
                                ) : (
                                    <p className="text-xs">
                                        {t("activeFleet.dropFile")}
                                    </p>
                                )}
                            </div>
                        )}
                        {props.controls?.map && props.theme === "unhandled" && (
                            <MapButton
                                onClick={props.controls.map.onClick}
                                label={props.controls.map.title}
                                style={{
                                    marginBottom: "20px",
                                    boxShadow: "var(--shadow)",
                                }}
                            />
                        )}
                        {!!dropzoneText && (
                            <div className="card-dropzone">{dropzoneText}</div>
                        )}
                        {cardAmount > 0 ? props.cards : null}
                        <div
                            className="placeholder"
                            style={{
                                maxHeight:
                                    props.theme === "alrik" ||
                                    props.theme === "outside-fleet"
                                        ? "0px"
                                        : undefined,
                            }}
                        >
                            {provided.placeholder}
                        </div>
                        {props.isLoading && (
                            <div className="loading-wrapper">
                                <Spinner />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Droppable>
    );
}

export default FleetPlannerColumn;
