import useDefaultMapCenter from "../../hooks/functionality/useDefaultMapCenter";
import useMapFitBounds from "../../hooks/functionality/useMapFitBounds";
import { StopDraftsTour, TourStop } from "../../shared/types/api";
import Map from "../Map";
import TourMarkers from "../markers/TourMarkers";
import "./style.scss";

type Props = {
    tour?: StopDraftsTour;
    stops?: TourStop[];
    mapId: string;
    alwaysShowMarkerPositionNumber?: boolean;
    boundsPadding?: number;
    hoveredStopId?: number | null;
};

function TourMap(props: Props) {
    const defaultCenter = useDefaultMapCenter();

    useMapFitBounds({
        path: (props.tour?.stops || props.stops || [])
            .filter((stop) => stop.lat && stop.lng)
            .map((stop) => ({
                lat: +stop.lat!,
                lng: +stop.lng!,
            })),
        boundsPadding: props.boundsPadding || 100,
    });

    return (
        <div className="tour-map">
            <Map
                mapId={props.mapId}
                defaultCenter={defaultCenter}
                defaultZoom={12}
                showTrafficToggle
            >
                <TourMarkers
                    hoveredStopId={props.hoveredStopId}
                    tour={props.tour}
                    stops={props.stops}
                    tourColor={{
                        primary: "var(--color-neutral-900)",
                        accent: "var(--color-neutral-900)",
                    }}
                    alwaysShowMarkerPositionNumber={
                        props.alwaysShowMarkerPositionNumber
                    }
                />
            </Map>
        </div>
    );
}

export default TourMap;
