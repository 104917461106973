import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import IconButtonOLD from "../../buttons/IconButtonOLD";
import TextButton from "../../buttons/TextButton";
import "./style.scss";

type Props = {
    hide: boolean;
};

function Banner(props: Props) {
    const { t } = useTranslation();

    const [showBanner, setShowBanner] = useState(true);

    const hasBanner = useMemo(
        () => !!t("general.banner") && showBanner && !props.hide,
        [props.hide, showBanner, t]
    );

    const hasBannerLink = useMemo(
        () => !!t("general.bannerLink") && !!t("general.bannerLinkLabel"),
        [t]
    );

    return (
        <div
            className="banner"
            style={{ display: !hasBanner ? "none" : undefined }}
        >
            <div className="content">
                <span className="text-xs">{t("general.banner")}</span>
                {hasBannerLink && (
                    <a
                        href={t("general.bannerLink")}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <TextButton
                            label={t("general.bannerLinkLabel")}
                            variant={"secondary"}
                        />
                    </a>
                )}
            </div>
            <IconButtonOLD
                icon="cross"
                short
                style={{
                    backgroundColor: "transparent",
                    color: "var(--text-color)",
                }}
                onClick={() => setShowBanner(false)}
            />
        </div>
    );
}

export default Banner;
