export const stopDraftColors = [
    "#7BC86C",
    "#F4DD28",
    "#FFAE3E",
    "#EE7564",
    "#CF8EE6",
    "#5BA4CF",
    "#2BCCE5",
    "#6DEDA9",
    "#FF8ED4",
    "#172A4D",
    "#BE717A",
];

//Needed to compute the value to be able to use it in svgs
export const tourColors = [
    {
        primary: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-cyan-500"
        ),
        accent: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-cyan-400"
        ),
    },
    {
        primary: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-fuchsia-500"
        ),
        accent: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-fuchsia-400"
        ),
    },
    {
        primary: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-indigo-500"
        ),
        accent: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-indigo-400"
        ),
    },
    {
        primary: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-teal-500"
        ),
        accent: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-teal-400"
        ),
    },
    {
        primary: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-orange-500"
        ),
        accent: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-orange-400"
        ),
    },
    {
        primary: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-neutral-600"
        ),
        accent: getComputedStyle(document.documentElement).getPropertyValue(
            "--color-neutral-400"
        ),
    },
];

export const fakeFromAddresses = [
    "Askängsbacken 46, 168 65 Bromma, Sweden",
    "Sköldgatan 10, 118 63 Stockholm, Sweden",
    "Blekingegatan 53, 118 56 Stockholm, Sweden",
    "Nytorgsgatan 29, 116 40 Stockholm, Sweden",
    "Rindögatan 10, 115 36 Stockholm, Sweden",
    "Frimurarevägen 2, 181 41 Lidingö, Sweden",
    "Rådjursvägen 2, 131 42 Nacka, Sweden",
    "Vasagatan 1, 111 20 Stockholm",
    "Drottninggatan 50, 111 21 Stockholm",
    "Stortorget 2, 111 29 Stockholm",
    "Sveavägen 100, 113 50 Stockholm",
    "Kungsträdgårdsgatan 12, 111 47 Stockholm",
    "Österlånggatan 17, 111 31 Stockholm",
    "Birger Jarlsgatan 16, 114 34 Stockholm",
    "Hornsgatan 45, 118 49 Stockholm",
    "Götgatan 67, 116 21 Stockholm",
    "Fleminggatan 22, 112 26 Stockholm",
    "Karlavägen 24, 114 31 Stockholm",
    "Strandvägen 15, 114 56 Stockholm",
    "Västerlånggatan 60, 111 29 Stockholm",
    "Södermalmstorg 4, 116 45 Stockholm",
    "Norrmalmstorg 5, 111 46 Stockholm",
    "Valhallavägen 117, 115 31 Stockholm, Sweden",
    "Hornsgatan 124, 117 28 Stockholm, Sweden",
    "Sveavägen 164, 113 46 Stockholm, Sweden",
    "Olof Palmes gata 29, 111 22 Stockholm, Sweden",
    "Lindhagensgatan 76, 112 18 Stockholm, Sweden",
    "Birger Jarlsgatan 112, 114 20 Stockholm, Sweden",
    "Tegnérgatan 3, 111 40 Stockholm, Sweden",
    "Odengatan 70, 113 22 Stockholm, Sweden",
    "Ringvägen 100, 118 60 Stockholm, Sweden",
    "Karlavägen 58, 114 49 Stockholm, Sweden",
    "Sandstensvägen 31, 167 64 Bromma, Sweden",
    "Drömstigen 49, 167 61 Bromma, Sweden",
    "Sedumbacken 42, 168 77 Bromma, Sweden",
    "Lovö Kyrkallé 1, 178 93 Drottningholm, Sweden",
    "Villavägen 13, 178 33 Ekerö, Sweden",
    "Klövervägen 5, 145 67 Norsborg, Sweden",
    "Alfred Nobels allé 41, 141 52 Huddinge, Sweden",
    "Karin Boyes plats 7, 141 46 Huddinge, Sweden",
    "Högåsvägen 11, 131 47 Nacka, Sweden",
    "Branddammsvägen 10, 132 44 Saltsjö-boo, Sweden",
    "Tellusvägen 9, 138 35 Älta, Sweden",
    "Härbrevägen 8, 142 34 Skogås, Sweden",
    "Sandstigen 8A, 135 50 Tyresö, Sweden",
    "Bergsätravägen 12, 181 61 Lidingö, Sweden",
    "Stockholmsvägen 60, 181 42 Lidingö, Sweden",
    "Armbågsvägen 13, 182 57 Danderyd, Sweden",
    "Torshamnsgatan 14, 164 40 Kista, Sweden",
    "Tisslingeplan 4, 163 61 Spånga, Sweden",
    "Soprangränd 44, 162 47 Vällingby, Sweden",
    "Hästhagen 5, 179 95 Svartsjö, Sweden",
    "Degerby gård 10, 179 96 Svartsjö, Sweden",
    "Lidvägen 9, 177 40 Järfälla, Sweden",
    "Solursgränd 6, 162 65 Vällingby, Sweden",
    "Eriksbergsgatan 3, 114 30 Stockholm, Sweden",
    "Sirishovsvägen 24, 115 21 Stockholm, Sweden",
    "Björksundsslingan 6, 124 31 Bandhagen, Sweden",
    "Sickmundsvägen 2, 141 37 Huddinge, Sweden",
    "Länsvägen 44, 123 52 Farsta, Sweden",
];

export const fakeToAddresses = [
    "Hagsätravägen 143, 124 67 Bandhagen, Sweden",
    "Vaktelstigen 9, 125 56 Älvsjö, Sweden",
    "Mälarhöjdsvägen 19A, 129 40 Hägersten, Sweden",
    "Bäckaskiftsvägen 10, 122 42 Enskede, Sweden",
    "Laxåvägen 44, 128 44 Bagarmossen, Sweden",
    "Saltvägen 39, 123 55 Farsta, Sweden",
    "Filipstadsbacken 72, 123 43 Farsta, Sweden",
    "Centralvägen 6, 171 68 Solna",
    "Stationsvägen 15, 191 40 Sollentuna",
    "Hammarby allé 110, 120 66 Hammarby",
    "Granitvägen 12, 131 49 Nacka",
    "Skolvägen 2, 183 34 Täby",
    "Biblioteksgången 8, 164 46 Kista",
    "Strandpromenaden 3, 181 82 Lidingö",
    "Värdshusvägen 7, 182 75 Danderyd",
    "Sjövägen 14, 139 60 Värmdö",
    "Parkvägen 20, 136 38 Haninge",
    "Solängsvägen 12, 141 42 Huddinge",
    "Tallvägen 4, 175 64 Järfälla",
    "Aspvägen 8, 135 50 Tyresö",
    "Klockarvägen 12, 151 61 Södertälje",
    "Ängsvägen 3, 178 40 Ekerö",
    "Hammarby allé 47, 120 66 Hammarby, Sweden",
    "Stora Nygatan 42, 111 27 Stockholm, Sweden",
    "Götgatan 16, 118 46 Stockholm, Sweden",
    "Skeppsbron 22, 111 30 Stockholm, Sweden",
    "Fleminggatan 39, 112 26 Stockholm, Sweden",
    "Vasagatan 16, 111 20 Stockholm, Sweden",
    "Nybrogatan 53, 114 40 Stockholm, Sweden",
    "Regeringsgatan 65, 111 56 Stockholm, Sweden",
    "Kungsgatan 44, 111 35 Stockholm, Sweden",
    "Sankt Eriksgatan 5, 112 39 Stockholm, Sweden",
    "Lissmavägen 12, 142 91 Huddinge, Sweden",
    "Brambercentrym 2, 136 66 Vendelsö, Sweden",
    "Dåntorpsvägen 17B, 136 50 Jordbro, Sweden",
    "Fyndvägen 3, 135 55 Tyresö, Sweden",
    "Knopvägen 32, 133 44 Saltsjöbaden, Sweden",
    "Fäbodvägen 10, 132 35 Saltsjö-boo, Sweden",
    "Trasthagen 3, 181 41 Lidingö, Sweden",
    "Mäster Samuelsgatan 10, 111 43 Stockholm, Sweden",
    "Drottningholmsvägen 25, 112 40 Stockholm, Sweden",
    "Stilgjutarvägen 24, 129 33 Hägersten, Sweden",
    "Lönnvägen 45, 141 41 Huddinge, Sweden",
    "Pionvägen 19, 146 50 Tullinge, Sweden",
    "Lissma skolväg 4, 141 91 Huddinge, Sweden",
    "Munkhättevägen 41, 147 40 Tumba, Sweden",
    "Tomtbergavägen 10, 145 67 Norsborg, Sweden",
    "Ångermannagatan 182, 162 65 Vällingby, Sweden",
    "Brunkullegränd 8, 162 45 Vällingby, Sweden",
    "Dackevägen 5, 177 34 Järfälla, Sweden",
    "Narviksgatan 5, 164 33 Kista, Sweden",
    "Malmvägen 23, 191 61 Sollentuna, Sweden",
    "Anbudsvägen 123, 187 50 Täby, Sweden",
    "Måttbandsvägen 1, 187 66 Täby, Sweden",
    "Lötvägen 51A, 184 94 Åkersberga, Sweden",
    "Frejvägen 20, 184 92 Åkersberga, Sweden",
    "Ringvägen 16, 185 34 Vaxholm, Sweden",
    "Mjöldammsvägen 13, 185 41 Vaxholm, Sweden",
    "Vikingshillsvägen 44F, 132 37 Saltsjö-boo, Sweden",
    "Månbacken 12, 132 36 Saltsjö-boo, Sweden",
    "Nimrodsvägen 4, 132 35 Saltsjö-boo, Sweden",
    "Fidravägen 4A, 133 44 Saltsjöbaden, Sweden",
    "Sjövägen 2, 133 36 Saltsjöbaden, Sweden",
    "Skrubbasandsvägen 4, 135 70 Stockholm, Sweden",
    "Lunnarevägen 9, 142 51 Skogås, Sweden",
];
