import { DraggableLocation } from "@hello-pangea/dnd";
import { isAxiosError } from "axios";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ZodError } from "zod";
import {
    addStopsToTour,
    createTour,
    dispatchTour,
    removeStopFromTour,
    reorderTour,
} from "../../api/tours";
import { createTourForm } from "../../schemas/form";
import analytics from "../../shared/services/ga4";
import { StopDraft, StopDraftsTour, TourStop } from "../../shared/types/api";
import { FleetPlannerReloadDataHandler } from "../../shared/types/internal";
import { getStopStatus } from "../../shared/utility/stop-draft";
import {
    API_ERROR,
    NEW_TOUR_COLUMN_ID,
    STOP_DRAFT_TYPE,
    UNHANDLED_COLUMN_ID,
} from "../../shared/values/enums";

function useFleetPlannerDrag({
    filterDate,
    tours,
    stopDrafts,
    reloadData,
}: {
    filterDate: string;
    tours?: StopDraftsTour[];
    stopDrafts?: StopDraft[];
    reloadData: FleetPlannerReloadDataHandler;
}) {
    const { t } = useTranslation();

    const [columnIdsLoading, setColumnIdsLoading] = useState<string[]>([]);
    const [unhandledIdsLoading, setUnhandledIdsLoading] = useState<number[]>(
        []
    );

    const createNewTour = useCallback(
        async ({
            stops,
            preferredDriverId,
            date,
            time,
            createdFrom,
            autoDispatch,
        }: {
            stops: { id: number; groupId?: string }[];
            preferredDriverId: string;
            date: string;
            time: string;
            createdFrom: string;
            autoDispatch?: boolean;
        }) => {
            try {
                createTourForm.parse({
                    stops,
                    preferredDriverId,
                    date,
                    time,
                });
            } catch (error) {
                //only print the first error to not overwhelm the user
                toast.error(t((error as ZodError).issues[0].message));
                return;
            }

            setColumnIdsLoading((prev) => [...prev, preferredDriverId]);

            try {
                const res = await createTour({
                    stop_draft_ids: stops.map((s) => s.id),
                    preferred_driver_id: preferredDriverId || undefined,
                    date,
                    time,
                });

                if (autoDispatch && res.data.tour_id && preferredDriverId) {
                    await dispatchTour(res.data.tour_id);
                }

                await reloadData("tours", "stop-drafts");

                toast.success(t("successMessage.tourCreated"));
                analytics.tourCreated(createdFrom);
                return res.data.tour_id;
            } catch (error) {
                if (
                    isAxiosError<{ detail: string; error_token: string }>(error)
                ) {
                    if (
                        error.response?.data.detail === API_ERROR.InvalidStops
                    ) {
                        toast.error(t("errorMessage.tourCreationInvalidStop"));
                        return;
                    }

                    if (error.response?.data.error_token) {
                        toast.error(t(error.response?.data.error_token));
                        return;
                    }
                }

                toast.error(t("errorMessage.tourCreationFailed"));
            } finally {
                setColumnIdsLoading((prev) =>
                    prev.filter((tourId) => tourId !== preferredDriverId)
                );
            }
        },
        [reloadData, t]
    );

    const validateDragEndStopDraftInTour = useCallback(
        ({
            movedStop,
            sourceTour,
            destinationIndex,
            destinationTour,
            allowMoveGroupedStops,
            dontAllowGroupedStopsChangeColumn,
        }: {
            movedStop: TourStop;
            sourceTour?: StopDraftsTour;
            destinationIndex: number;
            destinationTour?: StopDraftsTour;
            allowMoveGroupedStops?: boolean;
            dontAllowGroupedStopsChangeColumn?: boolean;
        }) => {
            if (!destinationTour) {
                //For the rule to not drop alrik assigned stops too create a new tour with a different date
                if (
                    movedStop.order?.assigned_location_id &&
                    movedStop.date_tooltip
                ) {
                    if (filterDate !== movedStop.date_tooltip) {
                        return "errorMessage.cantPutAssignedStopsOnWrongDate";
                    }
                }

                return;
            }

            const isReordering =
                sourceTour?.tour_id === destinationTour.tour_id;

            //For the rule that you cannot move a pause to another tour
            if (
                !isReordering &&
                movedStop.stop_type_id === STOP_DRAFT_TYPE.Pause
            ) {
                return "errorMessage.cantChangeTourOfAPause";
            }

            if (!isReordering && destinationTour.stops.length >= 90) {
                return "errorMessage.invalidMoveMaxStops";
            }

            // For the rule to not place dropoffs before their pickup
            if (isReordering) {
                if (
                    !!movedStop.motion_tools_stop_group &&
                    !allowMoveGroupedStops
                ) {
                    return "errorMessage.invalidMoveGroupReArrange";
                }

                const firstDropoffIndex = destinationTour.stops.findIndex(
                    (stop) =>
                        stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
                        stop.group_id === movedStop.group_id
                );

                const lastPickupIndex = destinationTour.stops.findLastIndex(
                    (stop) =>
                        stop.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                        stop.group_id === movedStop.group_id
                );

                // Because the last pickup and first dropoff might move when we ajust the order of the cards
                // we need to add 1 or subtract 1 from the indexes to get the correct "limit" indexes
                const firstAllowedIndexForPickup = firstDropoffIndex - 1;
                const lastAllowedIndexForDropoff = lastPickupIndex + 1;

                if (movedStop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
                    if (destinationIndex > firstAllowedIndexForPickup) {
                        return "errorMessage.invalidMoveDropoffBeforePickup";
                    }
                }

                if (movedStop.stop_type_id === STOP_DRAFT_TYPE.Dropoff) {
                    if (destinationIndex < lastAllowedIndexForDropoff) {
                        return "errorMessage.invalidMoveDropoffBeforePickup";
                    }
                }
            }

            if (dontAllowGroupedStopsChangeColumn && !isReordering) {
                if (movedStop.motion_tools_stop_group) {
                    return "errorMessage.cantChangeColumnForGroupedStops";
                }
            }

            //For the rule to not drop alrik assigned stops in tours with a different date
            if (
                movedStop.order?.assigned_location_id &&
                movedStop.date_tooltip
            ) {
                if (
                    destinationTour &&
                    destinationTour.date !== movedStop.date_tooltip
                ) {
                    return "errorMessage.cantPutAssignedStopsOnWrongDate";
                }
            }

            // For the rule to not drop cards above completed cards
            const hasCompletedCardsAbove = destinationTour.stops.some(
                (stop, i) => {
                    const stopStatus = getStopStatus(stop);
                    let cannotMoveStop =
                        stopStatus === "completed" ||
                        stopStatus === "loading" ||
                        stopStatus === "unloading";

                    if (stop.stop_type_id === STOP_DRAFT_TYPE.Pause) {
                        cannotMoveStop =
                            stopStatus === "completed" ||
                            stopStatus === "started";
                    }

                    return i >= destinationIndex && cannotMoveStop;
                }
            );

            if (hasCompletedCardsAbove) {
                return "errorMessage.invalidMoveCompletedAbove";
            }

            // For the rule to not drop pauses above started cards
            if (movedStop.stop_type_id === STOP_DRAFT_TYPE.Pause) {
                const hasStartedCardsAbove = destinationTour.stops.some(
                    (stop, i) => {
                        const stopStatus = getStopStatus(stop);

                        const isStopNotStarted = stopStatus === "planned";

                        return i >= destinationIndex && !isStopNotStarted;
                    }
                );

                if (hasStartedCardsAbove) {
                    return "errorMessage.invalidMovePauseAboveStartedStop";
                }
            }

            // For the rule to not drop cards inside a group
            const testOrder = destinationTour.stops.filter(
                (stop) => stop.id !== movedStop.id
            );

            testOrder.splice(destinationIndex, 0, movedStop);
            const cardAbove = testOrder[destinationIndex - 1];
            const cardBelow = testOrder[destinationIndex + 1];
            if (
                cardAbove &&
                cardBelow &&
                cardAbove.motion_tools_stop_group &&
                cardBelow.motion_tools_stop_group
            ) {
                if (
                    cardAbove.motion_tools_stop_group ===
                        cardBelow.motion_tools_stop_group &&
                    cardAbove.motion_tools_stop_group !==
                        movedStop.motion_tools_stop_group &&
                    cardBelow.motion_tools_stop_group !==
                        movedStop.motion_tools_stop_group
                ) {
                    return "errorMessage.invalidMoveInsideGroup";
                }
            }
        },
        [filterDate]
    );

    const onDragEndStopDraftInTour = useCallback(
        async ({
            draggableId,
            source,
            destination,
            isFromUnhandledColumn,
            isDroppedInEmptyColumn,
            createdFrom,
            allowMoveGroupedStops,
            dontAllowGroupedStopsChangeColumn,
        }: {
            draggableId: string;
            source: DraggableLocation;
            destination: DraggableLocation;
            isFromUnhandledColumn: boolean;
            isDroppedInEmptyColumn: boolean;
            createdFrom: string;
            allowMoveGroupedStops?: boolean;
            dontAllowGroupedStopsChangeColumn?: boolean;
        }) => {
            const sourceTour = tours?.find(
                (tour) => tour.tour_id === +source.droppableId
            );
            const destinationTour = tours?.find(
                (tour) => tour.tour_id === +destination.droppableId
            );

            let movedStop: TourStop | null = null;
            if (isFromUnhandledColumn) {
                movedStop =
                    stopDrafts?.find((stop) => stop.id === +draggableId) ||
                    null;
            } else {
                movedStop =
                    sourceTour?.stops.find(
                        (stop) => stop.id === +draggableId
                    ) || null;
            }

            if (!movedStop) return;

            const invalidMessage = validateDragEndStopDraftInTour({
                movedStop,
                sourceTour,
                destinationTour,
                destinationIndex: destination.index,
                allowMoveGroupedStops,
                dontAllowGroupedStopsChangeColumn,
            });

            if (invalidMessage) {
                toast.error(t(invalidMessage));
                return;
            }

            if (source.droppableId !== destination.droppableId) {
                let stopsToAdd: TourStop[] = [];

                if (isFromUnhandledColumn) {
                    stopsToAdd =
                        stopDrafts?.filter(
                            (stop) => stop.group_id === movedStop?.group_id
                        ) || [];
                } else {
                    stopsToAdd =
                        sourceTour?.stops.filter(
                            (stop) => stop.group_id === movedStop?.group_id
                        ) || [];
                }

                if (!stopsToAdd.length) return;

                const stopIdsToAdd = stopsToAdd.map((s) => s.id);

                if (
                    source.droppableId === UNHANDLED_COLUMN_ID ||
                    source.droppableId === NEW_TOUR_COLUMN_ID
                ) {
                    setColumnIdsLoading((prev) => [
                        ...prev,
                        destination.droppableId,
                    ]);
                    setUnhandledIdsLoading((prev) => [
                        ...prev,
                        ...stopIdsToAdd,
                    ]);
                } else {
                    setColumnIdsLoading((prev) => [
                        ...prev,
                        destination.droppableId,
                        source.droppableId,
                    ]);
                }

                try {
                    if (!isFromUnhandledColumn && sourceTour) {
                        await removeStopFromTour(movedStop.id);
                    }

                    if (!isDroppedInEmptyColumn) {
                        await addStopsToTour({
                            tour_id: +destination.droppableId,
                            stop_draft_ids: stopIdsToAdd,
                            index: destination.index,
                        });
                    } else {
                        await createNewTour({
                            stops: stopsToAdd,
                            preferredDriverId: destination.droppableId,
                            date: filterDate,
                            time: "06:00",
                            createdFrom,
                        });
                    }
                } catch (error: any) {
                    console.log(error);
                    if (error.response?.data.error_token) {
                        toast.error(t(error.response?.data.error_token));
                    } else {
                        toast.error(t("errorMessage.tourChangesSavedError"));
                    }
                } finally {
                    await reloadData("tours", "stop-drafts");
                    setColumnIdsLoading((prev) =>
                        prev.filter(
                            (tourId) =>
                                tourId !== destination.droppableId &&
                                tourId !== source.droppableId
                        )
                    );
                    setUnhandledIdsLoading((prev) =>
                        prev.filter((stopId) => !stopIdsToAdd.includes(stopId))
                    );
                }
            } else {
                if (!sourceTour) return;

                let groupedStops = [movedStop];

                if (movedStop.motion_tools_stop_group) {
                    groupedStops = sourceTour.stops
                        .filter((s) => s.stop_type_id !== STOP_DRAFT_TYPE.Pause)
                        .filter(
                            (stop) =>
                                stop.motion_tools_stop_group ===
                                movedStop.motion_tools_stop_group
                        );
                }

                let newTourOrder = structuredClone(sourceTour.stops);

                // Mutation section

                // Insert and remove the moved stop
                newTourOrder.splice(
                    destination.index,
                    0,
                    ...newTourOrder.splice(source.index, 1)
                );

                // Remove the grouped stops except the moved stop
                newTourOrder = newTourOrder.filter((stop) =>
                    stop.id === movedStop.id
                        ? true
                        : !groupedStops.some(
                              (groupedStop) => groupedStop.id === stop.id
                          )
                );

                // Find the new index of the moved stop
                const newIndexOfMovedStop = newTourOrder.findIndex(
                    (stop) => stop.id === movedStop.id
                );

                const groupedStopsWithoutMovedStop = groupedStops.filter(
                    (groupedStop) => groupedStop.id !== movedStop.id
                );

                //Insert the grouped stops after the moved stop
                newTourOrder.splice(
                    newIndexOfMovedStop + 1,
                    0,
                    ...groupedStopsWithoutMovedStop
                );

                // Mutation section end

                setColumnIdsLoading((prev) => [
                    ...prev,
                    destination.droppableId,
                ]);

                try {
                    await reorderTour({
                        tour_id: +destination.droppableId,
                        stop_draft_ids: newTourOrder.map((stop) => stop.id),
                    });
                    await reloadData("tours");
                } catch (error: any) {
                    console.log(error);
                    if (error.response?.data.error_token) {
                        toast.error(t(error.response?.data.error_token));
                    } else {
                        toast.error(t("errorMessage.tourChangesSavedError"));
                    }
                } finally {
                    await reloadData("tours", "stop-drafts");
                    setColumnIdsLoading((prev) =>
                        prev.filter(
                            (tourId) => tourId !== destination.droppableId
                        )
                    );
                }
            }
        },
        [
            createNewTour,
            filterDate,
            reloadData,
            stopDrafts,
            t,
            tours,
            validateDragEndStopDraftInTour,
        ]
    );

    const sortTourPickupsFirst = useCallback(
        async ({ tourId, stops }: { tourId: number; stops: TourStop[] }) => {
            setColumnIdsLoading((prev) => [...prev, tourId.toString()]);

            const pickups = stops.filter(
                (stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
            );

            const dropoffs = stops.filter(
                (stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            const pauses = stops.filter(
                (stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Pause
            );

            const sortedStops = pickups.concat(pauses).concat(dropoffs);

            try {
                await reorderTour({
                    tour_id: tourId,
                    stop_draft_ids: sortedStops.map((stop) => stop.id),
                });
            } catch (error: any) {
                console.log(error);
                if (error.response?.data.error_token) {
                    toast.error(t(error.response?.data.error_token));
                } else {
                    toast.error(t("errorMessage.tourChangesSavedError"));
                }
            } finally {
                await reloadData("tours");
                setColumnIdsLoading((prev) =>
                    prev.filter((tourId) => tourId !== tourId.toString())
                );
            }
        },
        [reloadData, t]
    );

    return {
        onDragEndStopDraftInTour,
        setColumnIdsLoading,
        columnIdsLoading,
        setUnhandledIdsLoading,
        unhandledIdsLoading,
        sortTourPickupsFirst,
        createNewTour,
    };
}

export default useFleetPlannerDrag;
