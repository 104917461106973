import { useCallback } from "react";
import { TourStop } from "../../shared/types/api";
import { getStopStatus } from "../../shared/utility/stop-draft";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";

function useFleetPlannerValidation() {
    const validateNoStopAboveCompletedStops = useCallback(
        ({
            destinationIndex,
            stopList,
        }: {
            destinationIndex: number;
            stopList: TourStop[];
        }) => {
            // For the rule to not drop cards above completed cards
            const hasCompletedCardsAbove = stopList.some((stop, i) => {
                const stopStatus = getStopStatus(stop);
                let cannotMoveStop =
                    stopStatus === "completed" ||
                    stopStatus === "loading" ||
                    stopStatus === "unloading";

                if (stop.stop_type_id === STOP_DRAFT_TYPE.Pause) {
                    cannotMoveStop =
                        stopStatus === "completed" || stopStatus === "started";
                }

                return i >= destinationIndex && cannotMoveStop;
            });

            if (hasCompletedCardsAbove) {
                return "errorMessage.invalidMoveCompletedAbove";
            }
        },
        []
    );

    const validateNoStopInsideGroupedStops = useCallback(
        ({
            movedStop,
            destinationIndex,
            stopList,
        }: {
            movedStop: TourStop;
            destinationIndex: number;
            stopList: TourStop[];
        }) => {
            // For the rule to not drop cards inside a group
            const testOrder = structuredClone(
                stopList.filter((stop) => stop.id !== movedStop.id)
            );
            testOrder.splice(destinationIndex, 0, movedStop);
            const cardAbove = testOrder[destinationIndex - 1];
            const cardBelow = testOrder[destinationIndex + 1];
            if (
                cardAbove &&
                cardBelow &&
                cardAbove.motion_tools_stop_group &&
                cardBelow.motion_tools_stop_group
            ) {
                if (
                    cardAbove.motion_tools_stop_group ===
                        cardBelow.motion_tools_stop_group &&
                    cardAbove.motion_tools_stop_group !==
                        movedStop.motion_tools_stop_group &&
                    cardBelow.motion_tools_stop_group !==
                        movedStop.motion_tools_stop_group
                ) {
                    return "errorMessage.invalidMoveInsideGroup";
                }
            }
        },
        []
    );

    const validateMoveOfAlrikAssignedStops = useCallback(
        (movedStop: TourStop, destinationDate: string) => {
            if (
                !movedStop.order?.assigned_location_id ||
                !movedStop.date_tooltip
            ) {
                return;
            }

            //For the rule to not drop alrik assigned stops in tours with a different date
            if (destinationDate !== movedStop.date_tooltip) {
                return "errorMessage.cantPutAssignedStopsOnWrongDate";
            }
        },
        []
    );

    const validateReorderStopList = useCallback(
        ({
            movedStop,
            stopList,
            destinationIndex,
            allowMoveGroupedStops,
        }: {
            movedStop: TourStop;
            stopList: TourStop[];
            destinationIndex: number;
            //Optional
            allowMoveGroupedStops?: boolean;
        }) => {
            if (!!movedStop.motion_tools_stop_group && !allowMoveGroupedStops) {
                return "errorMessage.invalidMoveGroupReArrange";
            }

            const firstDropoffIndex = stopList.findIndex(
                (stop) =>
                    stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
                    stop.group_id === movedStop.group_id
            );

            const lastPickupIndex = stopList.findLastIndex(
                (stop) =>
                    stop.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                    stop.group_id === movedStop.group_id
            );

            // Because the last pickup and first dropoff might move when we ajust the order of the cards
            // we need to add 1 or subtract 1 from the indexes to get the correct "limit" indexes
            const firstAllowedIndexForPickup = firstDropoffIndex - 1;
            const lastAllowedIndexForDropoff = lastPickupIndex + 1;

            if (movedStop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
                if (destinationIndex > firstAllowedIndexForPickup) {
                    return "errorMessage.invalidMoveDropoffBeforePickup";
                }
            }

            if (movedStop.stop_type_id === STOP_DRAFT_TYPE.Dropoff) {
                if (destinationIndex < lastAllowedIndexForDropoff) {
                    return "errorMessage.invalidMoveDropoffBeforePickup";
                }
            }

            const message = validateNoStopAboveCompletedStops({
                destinationIndex,
                stopList,
            });

            if (message) {
                return message;
            }
        },
        [validateNoStopAboveCompletedStops]
    );

    const validateStopChangeStopList = useCallback(
        ({
            movedStop,
            destinationIndex,
            destinationList,
            dontAllowGroupedStopsChangeColumn,
        }: {
            movedStop: TourStop;
            destinationIndex: number;
            destinationList: TourStop[];
            dontAllowGroupedStopsChangeColumn: boolean;
        }) => {
            //For the rule that you cannot move a pause to another tour
            if (movedStop.stop_type_id === STOP_DRAFT_TYPE.Pause) {
                return "errorMessage.cantChangeTourOfAPause";
            }

            //For the rule that a tour can be a maximum of 90 cards
            if (destinationList.length >= 90) {
                return "errorMessage.invalidMoveMaxStops";
            }

            const aboveCompletedCardsMessage =
                validateNoStopAboveCompletedStops({
                    destinationIndex,
                    stopList: destinationList,
                });
            if (aboveCompletedCardsMessage) {
                return aboveCompletedCardsMessage;
            }

            if (dontAllowGroupedStopsChangeColumn) {
                if (movedStop.motion_tools_stop_group) {
                    return "errorMessage.cantChangeColumnForGroupedStops";
                }
            }

            const insideGroupedStopsMessage = validateNoStopInsideGroupedStops({
                movedStop,
                destinationIndex,
                stopList: destinationList,
            });

            if (insideGroupedStopsMessage) {
                return insideGroupedStopsMessage;
            }
        },
        [validateNoStopAboveCompletedStops, validateNoStopInsideGroupedStops]
    );

    return {
        validateReorderStopList,
        validateStopChangeStopList,
        validateNoStopAboveCompletedStops,
        validateNoStopInsideGroupedStops,
        validateMoveOfAlrikAssignedStops,
    };
}

export default useFleetPlannerValidation;
