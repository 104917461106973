import {
    faFaceSleeping,
    faLocationDot,
    faLongArrowRight,
    faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { DraggableProvided } from "@hello-pangea/dnd";
import { addMinutes } from "date-fns";
import { useMemo } from "react";
import IconButton from "../../../components/buttons/IconButton";
import Icon from "../../../components/UI/Icon";
import { Pause } from "../../../shared/types/api";
import { dateToString, stringToDate } from "../../../shared/utility/date";
import { getStopStatus } from "../../../shared/utility/stop-draft";
import "./style.scss";

export type PauseCardProps = {
    // Drag and Drop
    provided?: DraggableProvided;
    index: number;
    pause: Pause;
    onDetachPause?: (pause: Pause) => void;
};

function PauseCard(props: PauseCardProps) {
    const iconAnimation = {
        "--fa-animation-duration": "3.0s",
    };

    const status = useMemo(() => getStopStatus(props.pause), [props.pause]);

    const startTimeString = useMemo(() => {
        if (!props.pause.estimated_offload_time) return "~ min";

        //If pause is not started we show offload time
        if (!props.pause.arrived_at) {
            return `${props.pause.estimated_offload_time} min`;
        }

        //All other cases we show arrived at
        const formattedArrivedAt = stringToDate(props.pause.arrived_at);
        if (formattedArrivedAt) {
            return dateToString(formattedArrivedAt, { onlyTime: true });
        }
    }, [props.pause.arrived_at, props.pause.estimated_offload_time]);

    const endTimeString = useMemo(() => {
        if (!props.pause.estimated_offload_time) return "~ min";

        //Is pause started we show estimed end time (arrived_at + estimated_offload_time)
        if (props.pause.arrived_at && !props.pause.completed_at) {
            const formattedArrivedAt = stringToDate(props.pause.arrived_at);
            if (formattedArrivedAt) {
                const estimatedEnd = addMinutes(
                    formattedArrivedAt,
                    props.pause.estimated_offload_time
                );
                if (estimatedEnd) {
                    return dateToString(estimatedEnd, {
                        onlyTime: true,
                    });
                }
            }
        }

        //If pause is completed we show the completed time
        if (props.pause.completed_at) {
            const formattedCompletedAt = stringToDate(props.pause.completed_at);
            if (formattedCompletedAt) {
                if (formattedCompletedAt) {
                    return dateToString(formattedCompletedAt, {
                        onlyTime: true,
                    });
                }
            }
        }
    }, [
        props.pause.arrived_at,
        props.pause.completed_at,
        props.pause.estimated_offload_time,
    ]);

    return (
        <div
            className="pause-card"
            ref={props.provided?.innerRef}
            {...props.provided?.draggableProps}
            {...props.provided?.dragHandleProps}
            style={{
                backgroundColor: props.pause.completed_at
                    ? "var(--color-neutral-50)"
                    : "var(--color-neutral-100)",
                ...props.provided?.draggableProps.style,
            }}
        >
            <div
                className="top"
                style={{
                    flexDirection: status === "planned" ? "row" : "column",
                }}
            >
                <Icon
                    icon={faFaceSleeping}
                    beat={status === "started"}
                    style={iconAnimation as React.CSSProperties}
                    size="lg"
                    color={
                        props.pause.completed_at
                            ? "var(--color-neutral-300)"
                            : "var(--color-neutral-400)"
                    }
                />
                <div className="time">
                    <p
                        className="text-sm"
                        style={{
                            color: props.pause.completed_at
                                ? "var(--color-neutral-300)"
                                : "var(--color-neutral-900)",
                        }}
                    >
                        {startTimeString}
                    </p>
                    {(status === "started" || status === "completed") && (
                        <>
                            <Icon
                                icon={faLongArrowRight}
                                size="sm"
                                color={
                                    props.pause.completed_at
                                        ? "var(--color-neutral-300)"
                                        : "var(--color-neutral-400)"
                                }
                            />
                            <p
                                className="text-sm"
                                style={{
                                    color: props.pause.completed_at
                                        ? "var(--color-neutral-300)"
                                        : "var(--color-neutral-400)",
                                }}
                            >
                                {endTimeString}
                            </p>
                        </>
                    )}
                </div>
            </div>
            {status !== "completed" &&
                status !== "started" &&
                props.onDetachPause && (
                    <IconButton
                        iconProps={{
                            icon: faXmark,
                            size: "lg",
                            color: "var(--color-neutral-400)",
                        }}
                        onClick={() => props.onDetachPause?.(props.pause)}
                        style={{
                            position: "absolute",
                            right: "6px",
                            top: "6px",
                        }}
                    />
                )}
            {props.pause.to_location && (
                <div className="location">
                    <Icon
                        icon={faLocationDot}
                        color={
                            props.pause.completed_at
                                ? "var(--color-neutral-300)"
                                : "var(--color-neutral-400)"
                        }
                    />
                    <p
                        className="text-xs"
                        style={{
                            color: props.pause.completed_at
                                ? "var(--color-neutral-300)"
                                : "var(--color-neutral-400)",
                        }}
                    >
                        {props.pause.to_location}
                    </p>
                </div>
            )}
            {props.pause.driver_instructions && (
                <p
                    className="text-xs"
                    style={{
                        color: props.pause.completed_at
                            ? "var(--color-neutral-300)"
                            : "var(--color-neutral-400)",
                    }}
                >
                    {props.pause.driver_instructions}
                </p>
            )}
        </div>
    );
}

export default PauseCard;
