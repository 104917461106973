import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { differenceInDays, startOfDay } from "date-fns";
import { AnimatePresence } from "framer-motion";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Driver } from "../../../shared/types/api";
import { DriverStatus, KeyString } from "../../../shared/types/internal";
import { dateToString, stringToDate } from "../../../shared/utility/date";
import { getDriverDisplayName } from "../../../shared/utility/misc";
import MapMarker from "../MapMarker";
import "./style.scss";
import {
    DRIVER_STATUS_COLOR,
    DRIVER_STATUS_TEXT,
} from "../../../shared/values/enums";

type Props = {
    driver: Driver;
    driverStatusMap: KeyString<DriverStatus>;
    driverColorMap: KeyString<{
        primary: string;
        accent: string;
    }>;
    onClick?: (event: google.maps.MapMouseEvent) => void;
    zIndex?: number;
    hide?: boolean;
};

function DriverMarkerNew(props: Props) {
    const { t } = useTranslation();

    const formatDriverPositionTime = useCallback(
        (driver: Driver) => {
            if (!driver.position_updated_at) return "";
            const lastSeen = stringToDate(driver.position_updated_at);
            if (!lastSeen) return "";

            const differenceInDaysSinceLastSeen = differenceInDays(
                startOfDay(new Date()),
                startOfDay(lastSeen)
            );

            if (differenceInDaysSinceLastSeen > 0) {
                return `${differenceInDaysSinceLastSeen}${t(
                    "driver.lastSeen"
                )}`;
            }

            return dateToString(lastSeen, {
                onlyTime: true,
            });
        },
        [t]
    );

    if (
        !props.driver.mt_driver_id ||
        !props.driver.current_lat ||
        !props.driver.current_lng ||
        props.hide
    ) {
        return null;
    }

    return (
        <AnimatePresence>
            <MapMarker
                key={props.driver.mt_driver_id}
                location={{
                    lat: +props.driver.current_lat,
                    lng: +props.driver.current_lng,
                }}
                label={
                    props.driver.first_name.charAt(0) +
                    props.driver.last_name.charAt(0)
                }
                infoWindowData={{
                    title: t("bigVolume.driverInfoTitle"),
                    description: getDriverDisplayName(props.driver),
                    description2: formatDriverPositionTime(props.driver),
                }}
                statusLabel={
                    props.driverStatusMap && props.driver.mt_driver_id
                        ? t(
                              DRIVER_STATUS_TEXT[
                                  props.driverStatusMap[
                                      props.driver.mt_driver_id
                                  ]
                              ]
                          )
                        : undefined
                }
                statusIcon={
                    props.driverStatusMap && props.driver.mt_driver_id
                        ? {
                              icon: faCircle,
                              color: DRIVER_STATUS_COLOR[
                                  props.driverStatusMap[
                                      props.driver.mt_driver_id
                                  ]
                              ].color,
                          }
                        : undefined
                }
                zIndex={props.zIndex}
                color={
                    props.driverColorMap[props.driver.mt_driver_id || ""]
                        ?.primary
                }
                onClick={props.onClick}
            />
        </AnimatePresence>
    );
}

export default DriverMarkerNew;
