import { faMap } from "@fortawesome/pro-regular-svg-icons";
import { CSSProperties } from "react";
import { useSelector } from "react-redux";
import Map from "../../../maps/Map";
import { ReduxState } from "../../../shared/types/redux";
import { DEFAULT_CENTER } from "../../../shared/values/enums";
import { GOOGLE_MAP_IDS } from "../../../shared/values/google-map-ids";
import Button from "../Button";
import "./style.scss";

type Props = {
    onClick?: () => void;
    label: string;
    style?: CSSProperties;
};

function MapButton(props: Props) {
    const { user } = useSelector((state: ReduxState) => state.auth);

    return (
        <div className="map-button" style={props.style}>
            <Map
                mapId={GOOGLE_MAP_IDS.MapButton}
                center={
                    user?.location_entity?.lat && user?.location_entity?.lng
                        ? {
                              lat: user.location_entity.lat,
                              lng: user.location_entity.lng,
                          }
                        : DEFAULT_CENTER
                }
                zoom={10}
                disableDefaultUI
                zoomControl={false}
            >
                <div className="cover">
                    <Button
                        variant="primary"
                        leadingIcon={faMap}
                        label={props.label}
                        onClick={props.onClick}
                    />
                </div>
            </Map>
        </div>
    );
}

export default MapButton;
