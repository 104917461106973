import { faChevronDown, faSparkles } from "@fortawesome/pro-regular-svg-icons";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import Modal from "../../../hoc/Modal";
import { NavItem } from "../../../shared/types/internal";
import Badge from "../../UI/Badge";
import Icon from "../../UI/Icon";
import "./style.scss";

type Props = {
    to: string;
    label: string;
    subNavItems?: NavItem[];
    disabled?: boolean;
    onClick?: (e: React.MouseEvent) => void;
};

function NavButton(props: Props) {
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(false);
    }, [pathname]);

    const motionParentVariants = {
        initial: {},
        enter: {
            transition: {
                duration: 0.4,
                delayChildren: 0.025,
                staggerChildren: 0.025,
            },
        },
        exit: {
            transition: {
                duration: 0.4,
                delayChildren: 0.025,
                staggerChildren: 0.025,
            },
        },
    };

    const motionVariants = {
        initial: { opacity: 0, y: 20 },
        enter: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 0 },
    };

    const { t } = useTranslation();

    return (
        <div className="nav-button-wrapper">
            {props.subNavItems && props.subNavItems.length > 0 ? (
                <Modal
                    buttonElement={(ref) => (
                        <button
                            type="button"
                            className="expandable-nav-button"
                            ref={ref}
                            style={
                                pathname.includes(props.to)
                                    ? {
                                          color: "var(--color-neutral-900",
                                          fontWeight: 600,
                                      }
                                    : {}
                            }
                            disabled={props.disabled}
                        >
                            <p className="text-sm">{props.label}</p>
                            <Icon
                                icon={faChevronDown}
                                size="lg"
                                color="var(--color-neutral-400)"
                            />
                        </button>
                    )}
                    onHoverStart={
                        props.disabled ? undefined : () => setIsOpen(true)
                    }
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    align="right"
                    width="220px"
                >
                    <motion.div
                        variants={motionParentVariants}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                    >
                        {props.subNavItems.map((subNav) => (
                            <NavLink
                                key={subNav.id}
                                to={subNav.path}
                                onClick={(e) => {
                                    if (props.disabled) return;
                                    setIsOpen(false);
                                    props.onClick?.(e);
                                }}
                            >
                                {({ isActive }) => (
                                    <motion.div variants={motionVariants}>
                                        <button
                                            className="nav-button"
                                            type="button"
                                            style={{
                                                color: isActive
                                                    ? "var(--color-neutral-900)"
                                                    : "var(--color-neutral-500)",
                                            }}
                                            disabled={subNav.disabled}
                                        >
                                            <p className="text-sm">
                                                {subNav.label}
                                            </p>
                                            {subNav.isBeta && (
                                                <Badge
                                                    variant="success"
                                                    title={t("general.beta")}
                                                    icon={faSparkles}
                                                />
                                            )}
                                        </button>
                                    </motion.div>
                                )}
                            </NavLink>
                        ))}
                    </motion.div>
                </Modal>
            ) : (
                <NavLink
                    to={props.to}
                    onClick={props.disabled ? undefined : props.onClick}
                >
                    {({ isActive }) => (
                        <button
                            type="button"
                            className="nav-button"
                            style={{
                                color: isActive
                                    ? "var(--color-neutral-900)"
                                    : "var(--color-neutral-500)",
                            }}
                            disabled={props.disabled}
                        >
                            <p className="text-sm">{props.label}</p>
                        </button>
                    )}
                </NavLink>
            )}
        </div>
    );
}

export default NavButton;
