import nodeAxios from "axios";
import environment from "../../environment";
import { getLocale, getVersion } from "../utility/misc";
import { LOCAL_STORAGE_KEY } from "../values/enums";
import store from "../../store";
import { setHasLowVersion } from "../../store/slices/auth";
import semver from "semver";

const axios = nodeAxios.create({
    baseURL: environment.orderApiProtocol + environment.orderApiUrl,
});

axios.interceptors.request.use((config) => {
    const memberToken = localStorage.getItem(
        LOCAL_STORAGE_KEY.MemberstackToken
    );

    config.headers["x-member-token"] = memberToken;
    config.headers["x-user-locale"] = getLocale();
    config.headers["x-user-version"] = getVersion();
    return config;
});

axios.interceptors.response.use((response) => {
    const minVersion = response.headers["connect-min-version"];
    const currentVersion = environment.appVersion;
    const reduxHasLowVersion = store.getState().auth.hasLowVersion;

    if (minVersion && currentVersion) {
        const hasTooLowVersion = semver.lt(currentVersion, minVersion);
        if (hasTooLowVersion) {
            store.dispatch(setHasLowVersion(true));
        } else if (reduxHasLowVersion) {
            store.dispatch(setHasLowVersion(false));
        }
    }
    return response;
});

export default axios;
