import { DriverStatus, KeyString, StopStatus } from "../types/internal";

export enum LOCAL_STORAGE_KEY {
    TourStatus = "tourStatus",
    MemberstackToken = "_ms-mid",
    UserLocale = "userLocale",
    DisableAnalytics = "disableAnalytics",
    FleetPlannerSettings = "fleetPlannerSettings_v2",
    CockpitStopColumns = "cockpitStopColumns",
}

export enum ROUTE {
    Settings = "/settings",
    Support = "/support",

    Orders = "/orders",
    NewOrder = "/orders/new-order",
    ActiveOrders = "/orders/active",
    FulfilledOrders = "/orders/fulfilled",

    PublicOrder = "/public-order",
    TrackingRedirect = "/track",
    Tracking = "/tracking",
    ExternalOrder = "/external-order",

    Fleet = "/fleet",
    Cockpit = "/fleet/cockpit",
    ActiveFleet = "/fleet/active",
    FulfilledFleetTours = "/fleet/fulfilled",
    Drivers = "/fleet/drivers",
    Dashboard = "/fleet/dashboard",
    FleetCalendar = "/fleet/calendar",
    FleetCalendarFullPage = "/fleet/calendar/full-page",
    FleetSettings = "/fleet/settings",
    FleetManualOrder = "/fleet/manual-order",

    Login = "/login",
    Register = "/register",
    EmailSent = "/email-sent",
    AuthCode = "/auth-code",
    Setup = "/setup",
    ResetPassword = "/reset-password",
}

export enum API_MAX_PER_PAGE {
    Orders = 50,
    Tours = 25,
    StopDraftsTour = 25,
}

export enum QUERY_PARAM {
    OrderMode = "mode",
    DraftId = "draftId",
    ActivationKey = "activation_key",
    FilterDate = "filterDate",
    PublicOrderId = "pid",
    TrackingCode = "tc",
    ExternalOrderId = "eid",
}

export enum STOP_DRAFT_TYPE {
    Pickup = 1,
    Dropoff = 2,
    Pause = 3,
}

export enum API_ERROR {
    EmailInUse = "Email in use",
    BadToken = "Bad token",
    SessionExpired = "User session expired",
    ExpiredPriceHash = "expired_hash",
    InvalidPriceHash = "invalid_hash",
    InvalidTimeslot = "invalid_timeslot",
    InvalidStops = "invalid_stops",
    DriverAlreadyExists = "Driver already exists",
}

export const CAR_NAME = {
    Car: "ordinary-car",
    SmallTruck: "large-van",
    Truck: "truck",
    Crane: "crane",
    MediumCrane: "medium-crane",
    LargeCrane: "large-crane",
} as const;

export const DELIVERY_NAME = {
    Express: "express",
    Scheduled: "scheduled",
    Tomorrow: "tomorrow",
} as const;

export const UNHANDLED_COLUMN_ID = "unhandled";

export const ORDER_DRAFT_COLUMN_ID = "order-draft";

export const ALRIK_COLUMN_ID = "alrik";

export const OUTSIDE_FLEET_COLUMN_ID = "outside-fleet";

export const CHANGED_LOCATION_COLUMN_ID = "changed-location";

export const STOP_MAP_ID = "stop-map";

export const NEW_TOUR_COLUMN_ID = "new-tour";

export const NEW_TOUR_GROUP_ID = "new-tour-group";

export enum NEW_ORDER_SECTION_ID {
    Address = "address",
    Weight = "weight",
    Vehicle = "vehicle",
    Contact = "contact",
    CargoContent = "cargo-content",
}

export const WEEK_DAYS: KeyString<string> = {
    "1": "monday",
    "2": "tuesday",
    "3": "wednesday",
    "4": "thursday",
    "5": "friday",
    "6": "saturday",
    "0": "sunday",
};

export enum FILE_TYPE {
    pdf = "application/pdf",
    jpg = "image/jpeg",
    png = "image/png",
}

export const DEFAULT_CENTER = {
    lat: 59.3293234,
    lng: 18.0685808,
};

export const DRIVER_STATUS_COLOR: Record<
    DriverStatus,
    {
        color: string;
        accent: string;
    }
> = {
    available: {
        color: "var(--color-primary-400)",
        accent: "var(--color-primary-100)",
    },
    "driving-pickup": {
        color: "var(--color-sky-400)",
        accent: "var(--color-sky-100)",
    },
    "driving-dropoff": {
        color: "var(--color-sky-400)",
        accent: "var(--color-sky-100)",
    },
    loading: {
        color: "var(--color-violet-400)",
        accent: "var(--color-violet-100)",
    },
    unloading: {
        color: "var(--color-violet-400)",
        accent: "var(--color-violet-100)",
    },
    break: {
        color: "var(--color-neutral-400)",
        accent: "var(--color-neutral-100)",
    },
};
export const DRIVER_STATUS_TEXT: Record<DriverStatus, string> = {
    available: "driverStatus.available",
    "driving-pickup": "driverStatus.driving-pickup",
    "driving-dropoff": "driverStatus.driving-dropoff",
    loading: "driverStatus.loading",
    unloading: "driverStatus.unloading",
    break: "driverStatus.break",
};
export const STOP_STATUS_COLOR: Record<
    StopStatus,
    {
        color: string;
        accent: string;
    }
> = {
    planned: {
        color: "var(--color-sky-200)",
        accent: "var(--color-sky-50)",
    },
    started: {
        color: "var(--color-sky-400)",
        accent: "var(--color-sky-100)",
    },
    loading: {
        color: "var(--color-violet-400)",
        accent: "var(--color-violet-100)",
    },
    unloading: {
        color: "var(--color-violet-400)",
        accent: "var(--color-violet-100)",
    },
    completed: {
        color: "var(--color-primary-400)",
        accent: "var(--color-primary-100)",
    },
};
export const STOP_STATUS_TEXT: Record<StopStatus, string> = {
    planned: "stopStatus.planned",
    started: "stopStatus.started",
    loading: "stopStatus.loading",
    unloading: "stopStatus.unloading",
    completed: "stopStatus.completed",
};

export const SCHENKER_CARRIER_NAME = "Schenker -> Alrik";
