import {
    faListCheck,
    faListTree,
    faPlus,
    faSpinnerThird,
    faWeightHanging,
} from "@fortawesome/pro-regular-svg-icons";
import { Droppable } from "@hello-pangea/dnd";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { addMinutes, isAfter, isToday } from "date-fns";
import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ZodError } from "zod";
import {
    consolidateTour,
    createTour,
    dispatchTour,
    groupStops,
    updateTour,
} from "../../../api/tours";
import Icon from "../../../components/UI/Icon";
import Button from "../../../components/buttons/Button";
import Dropdown from "../../../components/inputs/Dropdown";
import Input from "../../../components/inputs/Input";
import useActiveTours from "../../../hooks/data/useActiveTours";
import useStopDrafts from "../../../hooks/data/useStopDrafts";
import useUserFeatures from "../../../hooks/functionality/useUserFeatures";
import {
    createTourForm,
    CreateTourForm,
    UpdateTourForm,
    updateTourForm,
} from "../../../schemas/form";
import analytics from "../../../shared/services/ga4";
import {
    Driver,
    Pause,
    StopDraft,
    StopDraftsTour,
    TourStop,
} from "../../../shared/types/api";
import { KeyString } from "../../../shared/types/internal";
import { dateToString, stringToDate } from "../../../shared/utility/date";
import {
    convertToNumberWithSpaces,
    getDriverDisplayName,
    onFormError,
} from "../../../shared/utility/misc";
import {
    cardHoverHandler,
    checkForGroupsAround,
    getRunningWeight,
    getStopListRealtiveEtaPerIdInMinutes,
    getStopOrderMap,
} from "../../../shared/utility/stop-draft";
import { API_ERROR, STOP_DRAFT_TYPE } from "../../../shared/values/enums";
import PauseCardDraggable from "../../cards/PauseCardDraggable";
import StopCardDraggable from "../../cards/StopCardDraggable";
import "./style.scss";

type Props = {
    id: string;
    tour?: StopDraftsTour;
    stops: TourStop[];
    drivers: Driver[];
    filterDate: Date | null;
    isNewTourColumn?: boolean;
    isLoading?: boolean;
    isDraggingCard?: boolean;
    stopIdsLoading: number[];
    tourColor?: string;
    legsDurationInMinutes?: number[];
    onHoverStopId?: (stopId: number | null) => void;
    onClearStops?: () => void;
    onStopCardClick?: ({
        pickup,
        dropoff,
        groupedStops,
    }: {
        pickup: StopDraft;
        dropoff: StopDraft;
        groupedStops?: {
            pickup: StopDraft;
            dropoff: StopDraft;
        }[];
    }) => void;
    onSelectTourId?: (tourId: number) => void;
    onGroupTourClick: (tourId: number) => void;
    onGroupStopsClick?: (stops: TourStop[]) => void;
    onAddPause?: (data: {
        tourId: number;
        toLocation?: string;
        estimatedOffloadTime: number;
        tourPosition: number;
        driverInstructions?: string;
    }) => void;
    onDetachPause?: (pause: Pause) => void;
};

function TourColumn(props: Props) {
    const { t } = useTranslation();
    const { onStopCardClick } = props;

    const features = useUserFeatures();

    const createDefaultValues = useMemo<CreateTourForm>(() => {
        let defaultTime = "06:00";

        // If the chosen date is today, use the current time
        if (props.filterDate && isToday(props.filterDate)) {
            defaultTime = dateToString(new Date(), { onlyTime: true });
        }

        return {
            preferredDriverId: "",
            time: defaultTime,
            date: props.filterDate ? dateToString(props.filterDate) : "",
            stops: props.stops.map((s) => ({
                id: s.id,
                groupId: s.motion_tools_stop_group || s.id.toString(),
            })),
        };
    }, [props.filterDate, props.stops]);

    const updateDefaultValues = useMemo<UpdateTourForm>(() => {
        return {
            preferredDriverId: props.tour?.preferred_driver_id || "",
            time: props.tour?.time || "06:00",
            date: props.tour?.date || "",
            tourId: props.tour?.tour_id || -1,
        };
    }, [
        props.tour?.date,
        props.tour?.preferred_driver_id,
        props.tour?.time,
        props.tour?.tour_id,
    ]);

    const {
        control: createControl,
        handleSubmit: createHandleSubmit,
        setValue: setCreateValue,
        reset: createReset,
        watch: createWatch,
    } = useForm<CreateTourForm>({
        resolver: zodResolver(createTourForm),
        defaultValues: createDefaultValues,
    });
    const {
        control: updateControl,
        handleSubmit: updateHandleSubmit,
        watch: updateWatch,
    } = useForm<UpdateTourForm>({
        resolver: zodResolver(updateTourForm),
        defaultValues: updateDefaultValues,
    });

    const updateTourTime = updateWatch("time");
    const updateTourPreferredDriverId = updateWatch("preferredDriverId");
    const createTourtime = createWatch("time");

    const { refetch: refetchTours } = useActiveTours(
        dateToString(props.filterDate) || undefined
    );

    const { refetch: refetchStops } = useStopDrafts();

    const iconStyle = {
        "--fa-animation-duration": "0.5s",
    };

    useEffect(() => {
        // If the first stop has a time, use that
        if (props.stops[0]?.time_tooltip) {
            setCreateValue("time", props.stops[0].time_tooltip);
            return;
        }
    }, [props.filterDate, props.stops, setCreateValue]);

    useEffect(() => {
        setCreateValue("date", dateToString(props.filterDate));
        setCreateValue(
            "stops",
            props.stops.map((s) => ({
                id: s.id,
                groupId: s.motion_tools_stop_group || undefined,
            }))
        );
    }, [props.filterDate, props.stops, setCreateValue]);

    const { runningWeights, peakWeight } = useMemo(() => {
        return getRunningWeight(props.stops);
    }, [props.stops]);

    const stopsDurationsMap = useMemo(() => {
        return getStopListRealtiveEtaPerIdInMinutes(
            props.stops,
            props.legsDurationInMinutes
        );
    }, [props.legsDurationInMinutes, props.stops]);

    const { mutate: createTourHandler, isPending: isCreatingTour } =
        useMutation({
            mutationFn: async (data: CreateTourForm) => {
                createTourForm.parse(data);

                const res = await createTour({
                    date: data.date,
                    time: data.time,
                    preferred_driver_id: data.preferredDriverId,
                    stop_draft_ids: data.stops.map((s) => s.id),
                });

                if (!!res.data.excluded_stop_draft_ids.length) {
                    toast.error(
                        t("errorMessage.tourCreationSuccessExcludedStops")
                    );
                }

                const stopsWithGroup = data.stops.filter((s) => !!s.groupId);

                if (stopsWithGroup.length > 1) {
                    const { data: tours } = await refetchTours();

                    const createdTour = tours?.find(
                        (tour) => tour.tour_id === res.data.tour_id
                    );

                    const createdTourStopIds =
                        createdTour?.stops.map((s) => s.id) || [];

                    await groupStops(
                        stopsWithGroup
                            .filter((s) => !!createdTourStopIds.includes(s.id))
                            .map((s) => ({
                                stop_draft_id: s.id,
                                group: s.groupId!,
                            }))
                    );
                }

                if (data.preferredDriverId) {
                    await dispatchTour(res.data.tour_id);
                }

                return res.data.tour_id;
            },
            onSuccess: async (tourId: number) => {
                await Promise.all([refetchTours(), refetchStops()]);
                analytics.tourCreated("big-volume");
                toast.success(t("successMessage.tourCreated"));
                createReset();
                props.onClearStops?.();
                props.onSelectTourId?.(tourId);
            },
            onError: (error) => {
                if (error instanceof ZodError) {
                    toast.error(t(error.issues[0].message));
                    return;
                }

                if (
                    isAxiosError<{ detail: string; error_token: string }>(error)
                ) {
                    if (
                        error.response?.data.detail === API_ERROR.InvalidStops
                    ) {
                        toast.error(t("errorMessage.tourCreationInvalidStop"));
                        return;
                    }

                    if (error.response?.data.error_token) {
                        toast.error(t(error.response?.data.error_token));
                        return;
                    }
                }

                toast.error(t("errorMessage.tourCreationFailed"));
            },
        });

    const {
        mutate: onConsolidateAndCreateTour,
        isPending: isConsolidatingAndCreatingTour,
    } = useMutation({
        mutationFn: async (data: CreateTourForm) => {
            createTourForm.parse(data);

            const res = await createTour({
                date: data.date,
                time: data.time,
                preferred_driver_id: data.preferredDriverId || undefined,
                stop_draft_ids: data.stops.map((s) => s.id),
            });

            const { data: tours } = await refetchTours();

            if (data.preferredDriverId) {
                await dispatchTour(res.data.tour_id);
            }

            const createdTour = tours?.find(
                (tour) => tour.tour_id === res.data.tour_id
            );

            if (!createdTour) {
                throw new Error("Tour not found for consolidation");
            }

            await consolidateTour(createdTour);

            return createdTour.tour_id;
        },
        onSuccess: async (tourId: number) => {
            await Promise.all([refetchTours(), refetchStops()]);
            analytics.tourCreated("big-volume");
            analytics.tourConsolidated("big-volume");
            toast.success(t("successMessage.tourConsolidated"));
            createReset();
            props.onClearStops?.();
            props.onSelectTourId?.(tourId);
        },
        onError: (error) => {
            if (error instanceof ZodError) {
                toast.error(t(error.issues[0].message));
                return;
            }

            if (isAxiosError<{ detail: string; error_token: string }>(error)) {
                if (error.response?.data.detail === API_ERROR.InvalidStops) {
                    toast.error(t("errorMessage.tourCreationInvalidStop"));
                    return;
                }

                if (error.response?.data.error_token) {
                    toast.error(t(error.response?.data.error_token));
                    return;
                }
            }

            toast.error(t("errorMessage.tourConsolidateError"));
        },
    });

    const { mutate: updateTourHandler, isPending: isUpdatingTour } =
        useMutation({
            mutationFn: async (data: UpdateTourForm) => {
                updateTourForm.parse(data);

                let hasDispatchedTour = false;
                const hasChangedDriver =
                    !!data.preferredDriverId &&
                    data.preferredDriverId !== props.tour?.preferred_driver_id;

                await updateTour({
                    tour_id: data.tourId,
                    date: data.date,
                    time: data.time,
                    preferred_driver_id: data.preferredDriverId || null,
                });

                if (hasChangedDriver) {
                    await dispatchTour(data.tourId);
                    hasDispatchedTour = true;
                }

                return hasDispatchedTour;
            },
            onSuccess: async (hasDispatchedTour) => {
                await Promise.all([refetchTours(), refetchStops()]);
                toast.success(t("successMessage.tourUpdated"));
                if (hasDispatchedTour) {
                    toast.success(t("successMessage.tourUpdateDispatched"));
                }
            },
            onError: (error) => {
                if (error instanceof ZodError) {
                    toast.error(t(error.issues[0].message));
                    return;
                }
                toast.error(t("errorMessage.tourUpdatedError"));
            },
        });

    const { mutate: dispatchTourHandler, isPending: isDispatchingTour } =
        useMutation({
            mutationFn: async (tourId: number) => {
                await dispatchTour(tourId);
            },
            onSuccess: async () => {
                await Promise.all([refetchTours(), refetchStops()]);
                toast.success(t("successMessage.tourUpdateDispatched"));
            },
            onError: (error: AxiosError<any>) => {
                if (error.response?.data.error_token) {
                    toast.error(t(error.response?.data.error_token));
                } else if (error.response?.data.new_order) {
                    toast.error(t("errorMessage.tourDispatchErrorNewOrder"));
                } else if (error.response?.data.new_tour_id) {
                    toast.error(t("errorMessage.tourDispatchErrorNewTour"));
                } else {
                    toast.error(t("errorMessage.tourUpdateDispatchError"));
                }
            },
        });

    const {
        mutate: updateAndDispatchTourHandler,
        isPending: isUpdatingAndDispatchingTour,
    } = useMutation({
        mutationFn: async (data: UpdateTourForm) => {
            updateTourForm.parse(data);

            await updateTour({
                tour_id: data.tourId,
                date: data.date,
                time: data.time,
                preferred_driver_id: data.preferredDriverId || null,
            });
            if (data.preferredDriverId) {
                await dispatchTour(data.tourId);
            }
        },
        onSuccess: async () => {
            await Promise.all([refetchTours(), refetchStops()]);
            toast.success(t("successMessage.tourUpdated"));
            toast.success(t("successMessage.tourUpdateDispatched"));
        },
        onError: (error: AxiosError<any> | ZodError) => {
            if (error instanceof ZodError) {
                toast.error(t(error.issues[0].message));
                return;
            }
            if (error.response?.data.error_token) {
                toast.error(t(error.response?.data.error_token));
            } else if (error.response?.data.new_order) {
                toast.error(t("errorMessage.tourDispatchErrorNewOrder"));
            } else if (error.response?.data.new_tour_id) {
                toast.error(t("errorMessage.tourDispatchErrorNewTour"));
            } else {
                toast.error(t("errorMessage.tourUpdateDispatchError"));
            }
        },
    });

    const hasUndispatchedChanges = useMemo(() => {
        if (!props.tour) return false;
        if (!props.tour.dispatched_at) return true;

        return props.tour.updated_at
            ? isAfter(
                  stringToDate(props.tour.updated_at)!,
                  stringToDate(props.tour.dispatched_at)!
              )
            : false;
    }, [props.tour]);

    const hasChangedTourValues = useMemo(() => {
        return (
            updateDefaultValues.preferredDriverId !==
                updateTourPreferredDriverId ||
            updateDefaultValues.time !== updateTourTime
        );
    }, [
        updateDefaultValues.preferredDriverId,
        updateDefaultValues.time,
        updateTourPreferredDriverId,
        updateTourTime,
    ]);

    const hasDuplicatePickups = useMemo(() => {
        if (!props.stops) return false;
        const addresses = props.stops
            .filter((stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Pickup)
            .map((stop) => stop.to_location);
        const addressesSet = new Set([...addresses]);
        return addresses.length !== addressesSet.size;
    }, [props.stops]);

    const groupedStopsMap = useMemo(() => {
        const groupedStopsMap: KeyString<StopDraft[]> = {};

        for (let i = 0; i < props.stops.length; i++) {
            const stop = props.stops[i];

            if (!stop.motion_tools_stop_group) continue;

            const groupId = stop.motion_tools_stop_group;

            if (!groupedStopsMap[groupId]) {
                groupedStopsMap[groupId] = [];
            }

            groupedStopsMap[groupId].push(stop);
        }

        return groupedStopsMap;
    }, [props.stops]);

    const stopCardClickHandler = useCallback(
        (stop: StopDraft) => {
            if (!onStopCardClick) return;

            const pickup = props.stops.find(
                (s) =>
                    stop.group_id === s.group_id &&
                    s.stop_type_id === STOP_DRAFT_TYPE.Pickup
            );

            const dropoff = props.stops.find(
                (s) =>
                    stop.group_id === s.group_id &&
                    s.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            if (
                !pickup ||
                !dropoff ||
                pickup.stop_type_id === STOP_DRAFT_TYPE.Pause ||
                dropoff.stop_type_id === STOP_DRAFT_TYPE.Pause
            )
                return;

            if (
                !pickup.motion_tools_stop_group &&
                !dropoff.motion_tools_stop_group
            ) {
                onStopCardClick({ pickup, dropoff });
                return;
            }

            const groupedStops: {
                pickup: StopDraft;
                dropoff: StopDraft;
            }[] = [];

            if (stop.motion_tools_stop_group) {
                const groups = groupedStopsMap[stop.motion_tools_stop_group];

                if (groups) {
                    for (let i = 0; i < groups.length; i++) {
                        const groupedStop = groups[i];

                        const pickup = props.stops.find(
                            (s) =>
                                groupedStop.group_id === s.group_id &&
                                s.stop_type_id === STOP_DRAFT_TYPE.Pickup
                        );

                        const dropoff = props.stops.find(
                            (s) =>
                                groupedStop.group_id === s.group_id &&
                                s.stop_type_id === STOP_DRAFT_TYPE.Dropoff
                        );

                        if (
                            !dropoff ||
                            !pickup ||
                            dropoff.stop_type_id === STOP_DRAFT_TYPE.Pause ||
                            pickup.stop_type_id === STOP_DRAFT_TYPE.Pause
                        )
                            continue;

                        groupedStops.push({
                            pickup,
                            dropoff,
                        });
                    }
                }
            }

            onStopCardClick({ pickup, dropoff, groupedStops });
        },
        [groupedStopsMap, onStopCardClick, props.stops]
    );

    const getEtaStringForStop = useCallback(
        (stop: StopDraft) => {
            const stopEta =
                stop.completed_at || stop.arrived_at || stop.eta_internal;
            if (stopEta) return;

            let tourStartDate: Date | null = null;
            if (props.tour) {
                tourStartDate = stringToDate(
                    `${props.tour.date}T${updateTourTime}`,
                    {
                        localTimezone: true,
                    }
                );
            } else {
                tourStartDate = stringToDate(`2020-01-01T${createTourtime}`, {
                    localTimezone: true,
                });
            }

            if (!tourStartDate) return;

            return dateToString(
                addMinutes(tourStartDate, stopsDurationsMap[stop.id]),
                {
                    onlyTime: true,
                }
            );
        },
        [createTourtime, props.tour, stopsDurationsMap, updateTourTime]
    );

    const isCreationLoading = useMemo(() => {
        return isCreatingTour || isConsolidatingAndCreatingTour;
    }, [isCreatingTour, isConsolidatingAndCreatingTour]);

    const stopOrderMap = useMemo(
        () => getStopOrderMap(props.stops),
        [props.stops]
    );

    const motionVariants = {
        initial: { opacity: 0, x: -30 },
        enter: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -30 },
    };

    return (
        <div className="tour-column" data-column-id={props.id}>
            <motion.div
                className="top"
                variants={motionVariants}
                initial="initial"
                animate="enter"
                exit="exit"
            >
                <motion.div
                    variants={motionVariants}
                    className="horizontal-group"
                    initial="initial"
                    animate="enter"
                    exit="exit"
                >
                    {props.tour ? (
                        <Controller
                            control={updateControl}
                            name="preferredDriverId"
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    value={value}
                                    width="100%"
                                    placeholder={t("bigVolume.chooseDriver")}
                                    onSelect={({ value }) => onChange(value)}
                                    options={[
                                        {
                                            label: t("bigVolume.noDriver"),
                                            value: "",
                                        },
                                        ...props.drivers.map((driver) => ({
                                            label: getDriverDisplayName(driver),
                                            value: driver.mt_driver_id || "",
                                        })),
                                    ]}
                                    isSearchable
                                />
                            )}
                        />
                    ) : (
                        <Controller
                            control={createControl}
                            name="preferredDriverId"
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    value={value}
                                    width="100%"
                                    placeholder={t("bigVolume.chooseDriver")}
                                    onSelect={({ value }) => onChange(value)}
                                    options={[
                                        {
                                            label: t("bigVolume.noDriver"),
                                            value: "",
                                        },
                                        ...props.drivers.map((driver) => ({
                                            label: getDriverDisplayName(driver),
                                            value: driver.mt_driver_id || "",
                                        })),
                                    ]}
                                    isSearchable
                                />
                            )}
                        />
                    )}

                    <p className="text-2xs">
                        {t("fleetPlanner.timeAbbreviation")}
                    </p>
                    {props.tour ? (
                        <Controller
                            control={updateControl}
                            name="time"
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    type="time"
                                    value={value}
                                    onChange={onChange}
                                    placeholder={t(
                                        "fleetPlanner.startTimePlaceholder"
                                    )}
                                />
                            )}
                        />
                    ) : (
                        <Controller
                            control={createControl}
                            name="time"
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    type="time"
                                    value={value}
                                    onChange={onChange}
                                    placeholder={t(
                                        "fleetPlanner.startTimePlaceholder"
                                    )}
                                />
                            )}
                        />
                    )}
                </motion.div>

                <motion.div
                    variants={motionVariants}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    className="horizontal-group"
                >
                    <div className="weight-panel">
                        <Icon
                            icon={faWeightHanging}
                            color="var(--color-neutral-400)"
                            fixedWidth
                        />
                        <p>{convertToNumberWithSpaces(peakWeight, "kg")}</p>
                    </div>
                    <Button
                        disabled={props.stops.length < 4}
                        style={{ width: "100%" }}
                        variant="secondary"
                        leadingIcon={faListTree}
                        label={t("createTour.groupingLabel")}
                        onClick={() => {
                            if (props.tour?.tour_id) {
                                props.onGroupTourClick(props.tour.tour_id);
                            } else if (props.onGroupStopsClick) {
                                props.onGroupStopsClick(props.stops);
                            }
                        }}
                        tooltipData={
                            props.stops.length < 4
                                ? {
                                      description: t(
                                          "createTour.groupingDisabledTooFewStopsTooltip"
                                      ),
                                      alignment: {
                                          y: "bottom",
                                          x: "left",
                                      },
                                  }
                                : undefined
                        }
                    />
                </motion.div>
            </motion.div>

            <Droppable
                droppableId={props.id}
                isDropDisabled={props.isLoading || isCreationLoading}
            >
                {(provided) => (
                    <motion.div
                        className="content"
                        variants={motionVariants}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {!isCreationLoading &&
                            props.stops.map((stop, i) => {
                                if (stop.stop_type_id === STOP_DRAFT_TYPE.Pause)
                                    return (
                                        <PauseCardDraggable
                                            key={stop.id}
                                            id={stop.id.toString()}
                                            index={i}
                                            pause={stop}
                                            onDetachPause={props.onDetachPause}
                                            isDragDisabled={!!stop.completed_at}
                                        />
                                    );

                                const stopBelow = props.stops[i + 1];

                                const hasGroupsAround = checkForGroupsAround({
                                    stop,
                                    stopAbove: props.stops[i - 1],
                                    stopBelow,
                                });

                                const canAddPause =
                                    i !== props.stops.length - 1 &&
                                    props.tour &&
                                    !props.isDraggingCard &&
                                    !stopBelow.completed_at;

                                const stopsInGroup =
                                    groupedStopsMap[
                                        stop.motion_tools_stop_group || ""
                                    ] || [];

                                return (
                                    <StopCardDraggable
                                        key={stop.id}
                                        id={stop.id.toString()}
                                        index={i}
                                        stop={stop}
                                        order={
                                            stopOrderMap[
                                                stop.motion_tools_stop_group ||
                                                    stop.id.toString()
                                            ] + 1
                                        }
                                        hideBecauseOfGroup={
                                            hasGroupsAround.above
                                        }
                                        runningWeight={
                                            runningWeights[
                                                stopsInGroup.length > 1
                                                    ? i +
                                                      (stopsInGroup.length - 1)
                                                    : i
                                            ]
                                        }
                                        isDragDisabled={!!stop.completed_at}
                                        eta={getEtaStringForStop(stop)}
                                        isLoading={props.stopIdsLoading.includes(
                                            stop.id
                                        )}
                                        onHover={(stop) => {
                                            props.onHoverStopId?.(
                                                stop?.id || null
                                            );
                                            cardHoverHandler({
                                                groupId: stop?.group_id || -1,
                                                columnId: props.id,
                                            });
                                        }}
                                        color={props.tourColor}
                                        onClick={() =>
                                            stopCardClickHandler(stop)
                                        }
                                        groupedAmount={stopsInGroup.length}
                                        groupedWeight={stopsInGroup.reduce(
                                            (acc, stop) =>
                                                acc + (stop.weight_kg || 0),
                                            0
                                        )}
                                        onAddPause={
                                            props.onAddPause && canAddPause
                                                ? (data) =>
                                                      props.onAddPause?.({
                                                          ...data,
                                                          tourId: props.tour!
                                                              .tour_id,
                                                      })
                                                : undefined
                                        }
                                    />
                                );
                            })}
                        {props.stops.length === 0 && (
                            <div className="empty-placeholder">
                                <Icon
                                    icon={faListCheck}
                                    size="xl"
                                    color="var(--color-neutral-400)"
                                    fixedWidth
                                />
                                <p className="text-xs">
                                    {t("fleetPlanner.emptyPlaceholderText")}
                                </p>
                            </div>
                        )}
                        {isCreationLoading && (
                            <div className="empty-placeholder">
                                <Icon
                                    icon={faSpinnerThird}
                                    size="3x"
                                    color="var(--color-primary-400)"
                                    fixedWidth
                                    spin
                                    style={iconStyle as React.CSSProperties}
                                />
                                <motion.p
                                    className="text-xs"
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.5 }}
                                    transition={{
                                        duration: 0.2,
                                        ease: "easeInOut",
                                    }}
                                >
                                    {isCreatingTour
                                        ? t("fleetPlanner.creatingTourLoading")
                                        : t(
                                              "fleetPlanner.consolidatingTourLoading"
                                          )}
                                </motion.p>
                            </div>
                        )}
                        {provided.placeholder}
                    </motion.div>
                )}
            </Droppable>

            <motion.div
                className="button-group"
                variants={motionVariants}
                initial="initial"
                animate="enter"
                exit="exit"
            >
                {props.isNewTourColumn ? (
                    <>
                        <Button
                            disabled={
                                props.stops.length === 0 ||
                                isCreationLoading ||
                                !features?.manage_tours_fleet_planner
                            }
                            style={{ width: "100%" }}
                            variant="primary"
                            leadingIcon={faPlus}
                            label={t("createTour.createTourLabel")}
                            onClick={createHandleSubmit(
                                (data) => {
                                    console.log(data);

                                    createTourHandler(data);
                                },
                                (d) => {
                                    console.log(d);

                                    onFormError(d);
                                }
                            )}
                        />
                        <Button
                            disabled={
                                !hasDuplicatePickups ||
                                isCreationLoading ||
                                !features?.manage_tours_fleet_planner
                            }
                            style={{ width: "100%" }}
                            variant="secondary"
                            label={t("createTour.consolidateTourLabel")}
                            onClick={createHandleSubmit(
                                (data) => onConsolidateAndCreateTour(data),
                                onFormError
                            )}
                            tooltipData={
                                !!props.stops.length &&
                                (!hasDuplicatePickups ||
                                    isCreationLoading ||
                                    !features?.manage_tours_fleet_planner)
                                    ? {
                                          description: t(
                                              "bigVolume.cannotConsolidateTooltip"
                                          ),
                                          minWidth: "200px",
                                      }
                                    : undefined
                            }
                        />
                    </>
                ) : hasChangedTourValues ? (
                    <>
                        <Button
                            style={{ width: "100%" }}
                            disabled={
                                !hasChangedTourValues ||
                                !features?.manage_tours_fleet_planner
                            }
                            variant={"primary"}
                            label={t("createTour.saveTourLabel")}
                            isLoading={isUpdatingTour}
                            onClick={updateHandleSubmit(
                                (data) => updateTourHandler(data),
                                onFormError
                            )}
                            tooltipData={
                                !hasChangedTourValues ||
                                !features?.manage_tours_fleet_planner
                                    ? {
                                          description: t(
                                              "bigVolume.cannotSaveTourTooltip"
                                          ),
                                          minWidth: "150px",
                                      }
                                    : undefined
                            }
                        />
                        {hasUndispatchedChanges && !hasChangedTourValues ? (
                            <Button
                                style={{ width: "100%" }}
                                variant="secondary"
                                label={t("createTour.dispatchLabel")}
                                isLoading={isDispatchingTour}
                                onClick={() => {
                                    if (!props.tour) return;
                                    dispatchTourHandler(props.tour.tour_id);
                                }}
                                disabled={!features?.manage_tours_fleet_planner}
                            />
                        ) : (
                            <Button
                                style={{ width: "100%" }}
                                variant="secondary"
                                label={t("createTour.saveAndDispatchLabel")}
                                isLoading={isUpdatingAndDispatchingTour}
                                onClick={updateHandleSubmit(
                                    (data) =>
                                        updateAndDispatchTourHandler(data),
                                    onFormError
                                )}
                                disabled={!features?.manage_tours_fleet_planner}
                            />
                        )}
                    </>
                ) : null}
            </motion.div>
        </div>
    );
}

export default TourColumn;
