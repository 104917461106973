import { faX, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { KeyboardEventHandler, useCallback, useState } from "react";
import { components, StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";
import { DropdownOption } from "../../../shared/types/internal";
import Icon from "../../UI/Icon";
import "./style.scss";

type Props = {
    value: string[];
    onChange: (value: string[]) => void;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    labelColor?: string;
    width?: string;
    completed?: boolean;
};

function MultiSelectText(props: Props) {
    const { onChange } = props;

    const [inputValue, setInputValue] = useState("");

    const handleKeyDown: KeyboardEventHandler = useCallback(
        (event) => {
            switch (event.key) {
                case "Enter":
                case "Tab":
                    // Only add the value if it's not already in the list
                    if (!!inputValue && !props.value.includes(inputValue)) {
                        onChange([...props.value, inputValue]);
                    }
                    setInputValue("");
                    event.preventDefault();
            }
        },
        [inputValue, onChange, props.value]
    );

    const styles: StylesConfig<DropdownOption> = {
        container: (base) => ({
            ...base,
            width: "100%",
            maxWidth: "100%",
        }),
        placeholder: (base) => ({
            ...base,
            color: "var(--text-color-light)",
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: "var(--color-neutral-100)",
            color: "var(--color-neutral-700)",
            borderRadius: "6px",
            padding: "4px 6px",
            margin: "4px 0",
            marginRight: "4px",
        }),
        multiValueLabel: (base) => ({
            ...base,
            fontSize: "inherit",
            lineHeight: "inherit",
            padding: "0",
            paddingLeft: "0",
        }),
        multiValueRemove: (base) => ({
            ...base,
            padding: "0",
            marginLeft: "4px",
            ":hover": {
                backgroundColor: "transparent",
                color: "var(--color-neutral-400)",
            },
        }),
        control: (base, state) => ({
            ...base,
            color: "var(--color-neutral-600)",
            borderRadius: "8px",
            minHeight: "38px",
            border: state.isFocused
                ? "var(--border-primary)"
                : props.completed
                ? "1px solid var(--color-primary-500)"
                : "var(--border-primary)",
            "&:hover": {
                border: "var(--border-primary)",
            },
            cursor: "text",
            boxShadow: "none",
            backgroundColor: props.completed
                ? "var(--color-primary-50)"
                : "var(--color-pure-white)",
        }),
    };

    return (
        <label
            className="multi-select-text-wrapper"
            style={{ width: props.width, maxWidth: props.width }}
        >
            {props.label && (
                <div
                    className="multi-select-text-label"
                    style={{
                        color: props.labelColor,
                    }}
                >
                    <span className="text-xs">{props.label}</span>
                </div>
            )}
            <CreatableSelect
                components={{
                    DropdownIndicator: null,
                    MultiValueLabel: (props) => (
                        <span className="text-xs">
                            <components.MultiValueLabel {...props} />
                        </span>
                    ),
                    MultiValueRemove: (props) => (
                        <components.MultiValueRemove {...props}>
                            <Icon
                                icon={faXmark}
                                color="var(--color-neutral-400)"
                            />
                        </components.MultiValueRemove>
                    ),
                    ClearIndicator: (props) => (
                        <components.ClearIndicator {...props}>
                            <Icon icon={faX} color="var(--color-neutral-400)" />
                        </components.ClearIndicator>
                    ),
                }}
                styles={styles}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(value) => {
                    onChange(value.map((v) => v.value));
                }}
                onBlur={() => {
                    if (!inputValue) return;
                    onChange([...props.value, inputValue]);
                }}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder={props.placeholder}
                value={props.value.map((v) => ({ label: v, value: v }))}
                isDisabled={props.disabled}
            />
        </label>
    );
}

export default MultiSelectText;
