import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import Modal from "../../../hoc/Modal";
import { stopDraftColors } from "../../../shared/values/lists";
import DotsButton from "../../buttons/DotsButton";
import IconButton from "../../buttons/IconButton";
import "./style.scss";

type Props = {
    fixedMenu?: boolean;
    isLoading?: boolean;
    value: string | null;
    onChange: (value: string | null) => void;
    large?: boolean;
    label?: string;
    width?: string;
    defaultColor?: string;
    labelColor?: string;
};

function ColorPicker(props: Props) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const largeStyle: React.CSSProperties = props.large
        ? {
              height: "40px",
              width: "25%",
              minWidth: "25%",
              minHeight: "40px",
              maxWidth: "25%",
              maxHeight: "40px",
              borderRadius: "40px",
          }
        : {};

    return (
        <div className="color-picker" style={{ width: props.width }}>
            {props.label && (
                <span
                    className="text-xs label"
                    style={{ color: props.labelColor }}
                >
                    {props.label}
                </span>
            )}
            <Modal
                buttonElement={(ref) => (
                    <DotsButton
                        ref={ref}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen((state) => !state);
                        }}
                        isLoading={props.isLoading}
                        color={
                            props.value || props.defaultColor || "transparent"
                        }
                        amount={1}
                        style={{
                            backgroundColor: "transparent",
                            height: props.large ? "40px" : "auto",
                            padding: "5px 0",
                            width: "100%",
                        }}
                        dotStyle={{
                            ...largeStyle,
                            border: props.value
                                ? undefined
                                : "var(--border-primary)",
                        }}
                        large
                    />
                )}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                modalClass="color-picker-modal"
                fixedMenu={props.fixedMenu}
                width={props.large ? "100%" : "150px"}
                align="left"
            >
                <>
                    <IconButton
                        iconProps={{
                            icon: faXmark,
                            size: "xl",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(false);
                            props.onChange(null);
                        }}
                    />
                    {stopDraftColors.map((hex) => (
                        <IconButton
                            key={hex}
                            iconProps={{
                                icon: faCircle,
                                size: "xl",
                                color: hex,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                props.onChange(hex);
                                setIsModalOpen(false);
                            }}
                        />
                    ))}
                </>
            </Modal>
        </div>
    );
}

export default ColorPicker;
