import {
    faArrowTurnLeftUp,
    faListTree,
    faSpinnerThird,
    faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import {
    DragDropContext,
    DropResult,
    Droppable,
    ResponderProvided,
} from "@hello-pangea/dnd";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { addMinutes } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { groupStops } from "../../api/tours";
import Button from "../../components/buttons/Button";
import Icon from "../../components/UI/Icon";
import GroupStopCardDraggable from "../../fleet-planner/cards/GroupStopCardDraggable";
import PauseCardDraggable from "../../fleet-planner/cards/PauseCardDraggable";
import Popup from "../../hoc/Popup";
import useDirections from "../../hooks/functionality/useDirections";
import TourMap from "../../maps/TourMap";
import { StopDraft, StopDraftsTour, TourStop } from "../../shared/types/api";
import {
    dateToString,
    formatMinutes,
    stringToDate,
} from "../../shared/utility/date";
import { generateUUID } from "../../shared/utility/misc";
import {
    checkForGroupsAround,
    getRunningWeight,
    getStopListDurationInMinutes,
    getStopListRealtiveEtaPerIdInMinutes,
    getStopOrderMap,
} from "../../shared/utility/stop-draft";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    droppableId: string;
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
    onStopsGrouped: () => void;
    onSortClick?: () => void;
    onStateStopsGrouped?: (stops: TourStop[]) => void;
    isColumnLoading: boolean;
    tour?: StopDraftsTour;
    stops: TourStop[];
};

function GroupTourStopsPopup(props: Props) {
    const { t } = useTranslation();

    const { onStopsGrouped, onStateStopsGrouped } = props;

    const [checkedIds, setCheckedIds] = useState<number[]>([]);
    const [groupingType, setGroupingType] = useState<1 | 2 | null>(null);
    const [degroupingIds, setDegroupingIds] = useState<number[]>([]);
    const [hoveredStopId, setHoveredStopId] = useState<number | null>(null);

    const iconStyle = {
        "--fa-animation-duration": "0.5s",
    };

    const areStopIdsConsecutive = useCallback(
        (stopIds: number[], tourOrder: number[]) => {
            const sortedIds = stopIds.toSorted(
                (a, b) => tourOrder.indexOf(a) - tourOrder.indexOf(b)
            );

            // Finding the index of the first element of stopIds in tourOrder
            const startIndex = tourOrder.indexOf(sortedIds[0]);

            // Check if the first element is not found or there isn't enough space left in mainArray
            if (
                startIndex === -1 ||
                startIndex + sortedIds.length > tourOrder.length
            ) {
                return false;
            }

            // Check the subsequent elements
            for (let i = 1; i < sortedIds.length; i++) {
                if (tourOrder[startIndex + i] !== sortedIds[i]) {
                    return false;
                }
            }

            return true;
        },
        []
    );

    const { mutate: groupStopsHandler, isPending: isGrouping } = useMutation({
        mutationFn: async (stopIds: number[]) => {
            if (stopIds.length < 2) {
                throw new Error("errorMessage.groupingAtLeastTwo");
            }

            if (
                !areStopIdsConsecutive(
                    stopIds,
                    props.stops.map((s) => s.id)
                )
            ) {
                throw new Error(
                    "errorMessage.groupingStopsNeedToBeConsecutive"
                );
            }

            const groupId = generateUUID();

            if (!props.tour && onStateStopsGrouped) {
                onStateStopsGrouped(
                    props.stops.map((stop) => {
                        if (
                            stop.stop_type_id === STOP_DRAFT_TYPE.Pause ||
                            !stopIds.includes(stop.id)
                        )
                            return stop;

                        return {
                            ...stop,
                            motion_tools_stop_group: groupId,
                        };
                    })
                );
            } else {
                await groupStops(
                    stopIds.map((id) => ({
                        stop_draft_id: id,
                        group: groupId,
                    }))
                );
            }
        },
        onSuccess: () => {
            onStopsGrouped();
            setCheckedIds([]);
            setGroupingType(null);
            toast.success(t("successMessage.groupCreated"));
        },
        onError: (error: Error) => {
            if (!isAxiosError(error) && typeof error.message === "string") {
                toast.error(t(error.message));
                return;
            }
            toast.error(t("errorMessage.groupCreationFailed"));
            if (isAxiosError(error)) {
                toast.error(error.response?.data.message);
            }
        },
    });

    const degroupStopsHandler = useCallback(
        async (stopsToDegroup: number[]) => {
            setDegroupingIds((state) => [...state, ...stopsToDegroup]);

            try {
                await groupStops(
                    stopsToDegroup.map((id) => ({
                        stop_draft_id: id,
                        group: null,
                    }))
                );
                onStopsGrouped();
                toast.success(t("successMessage.groupDegrouped"));
            } catch (error) {
                toast.error(t("errorMessage.groupDegroupFailed"));
            } finally {
                setDegroupingIds((state) =>
                    state.filter((id) => !stopsToDegroup.includes(id))
                );
            }
        },
        [onStopsGrouped, t]
    );

    const checkClickHandler = useCallback((stop: StopDraft) => {
        setCheckedIds((state) => {
            let newState = [...state];

            if (newState.includes(stop.id)) {
                newState = newState.filter((id) => id !== stop.id);
            } else {
                newState = [...newState, stop.id];
            }
            if (!newState.length) {
                setGroupingType(null);
            } else {
                setGroupingType(stop.stop_type_id);
            }

            return newState;
        });
    }, []);

    const tourDuration = useMemo(() => {
        return formatMinutes(getStopListDurationInMinutes(props.stops));
    }, [props.stops]);

    const { peakWeight, runningWeights } = useMemo(() => {
        return getRunningWeight(props.stops);
    }, [props.stops]);

    const { pathLegsDurationInMinutes } = useDirections({
        path: props.stops
            .filter((s) => s.lat && s.lng)
            .map((stop) => ({
                lat: +stop.lat!,
                lng: +stop.lng!,
            })),
    });

    const stopsDurationsMap = useMemo(() => {
        return getStopListRealtiveEtaPerIdInMinutes(
            props.stops,
            pathLegsDurationInMinutes
        );
    }, [pathLegsDurationInMinutes, props.stops]);

    const getEtaStringForStop = useCallback(
        (stop: StopDraft) => {
            if (!props.tour) return undefined;

            const stopEta =
                stop.completed_at || stop.arrived_at || stop.eta_internal;
            if (stopEta) return undefined;

            const tourStartDate = stringToDate(
                (props.tour?.date || "2020-01-01") + "T" + props.tour.time,
                {
                    localTimezone: true,
                }
            );

            if (!tourStartDate) return undefined;

            return dateToString(
                addMinutes(tourStartDate, stopsDurationsMap[stop.id]),
                {
                    onlyTime: true,
                }
            );
        },
        [props.tour, stopsDurationsMap]
    );

    const stopOrderMap = useMemo(
        () => getStopOrderMap(props.stops),
        [props.stops]
    );

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            dontCloseOnOutsideClick
            overlayComponent={
                <TourMap
                    mapId={GOOGLE_MAP_IDS.GroupTourMap}
                    tour={props.tour}
                    stops={props.stops}
                    boundsPadding={250}
                    hoveredStopId={hoveredStopId}
                />
            }
        >
            <div className="group-tour-stops-popup">
                <div className="header">
                    <div>
                        <p style={{ color: "var(--color-neutral-500)" }}>
                            {t("groupingStops.assignedDriver")}
                        </p>
                        <p className="text-lg">
                            {props.tour?.preferred_driver ||
                                t("bigVolume.unassignedTourLabel")}
                        </p>
                    </div>
                </div>

                <div className="tour-info">
                    <div className="tour-info-card">
                        <p style={{ color: "var(--color-neutral-500)" }}>
                            {t("groupingStops.tourDuration")}
                        </p>
                        <p className="text-lg">{tourDuration}</p>
                    </div>
                    <div className="tour-info-card">
                        <p style={{ color: "var(--color-neutral-500)" }}>
                            {t("groupingStops.peakWeight")}
                        </p>
                        <p className="text-lg">{`${peakWeight} kg`}</p>
                    </div>
                </div>

                <div className="tour-wrapper">
                    <div className="tour-header">
                        <p className="text-lg">
                            {t("groupingStops.tourHeader")}
                        </p>
                        <Button
                            isLoading={props.isColumnLoading}
                            leadingIcon={faArrowTurnLeftUp}
                            variant="secondary"
                            label={t("createTour.sortByType")}
                            onClick={props.onSortClick}
                        />
                    </div>
                    {!props.isColumnLoading ? (
                        <DragDropContext onDragEnd={props.onDragEnd}>
                            <Droppable droppableId={props.droppableId}>
                                {(provided) => (
                                    <div ref={provided.innerRef}>
                                        {props.stops.map((stop, index) => {
                                            if (
                                                stop.stop_type_id ===
                                                STOP_DRAFT_TYPE.Pause
                                            ) {
                                                return (
                                                    <PauseCardDraggable
                                                        key={stop.id}
                                                        id={stop.id.toString()}
                                                        index={index}
                                                        pause={stop}
                                                    />
                                                );
                                            }

                                            const stopAbove =
                                                props.stops[index - 1];
                                            const stopBelow =
                                                props.stops[index + 1];
                                            const hasGroupsAround =
                                                checkForGroupsAround({
                                                    stop,
                                                    stopAbove,
                                                    stopBelow,
                                                });

                                            const canCheck = groupingType
                                                ? stop.stop_type_id ===
                                                  groupingType
                                                : true;

                                            const canDegroup =
                                                (!hasGroupsAround.above &&
                                                    hasGroupsAround.below) ||
                                                (stop.motion_tools_stop_group &&
                                                    !hasGroupsAround.above &&
                                                    !hasGroupsAround.below);

                                            return (
                                                <GroupStopCardDraggable
                                                    key={stop.id}
                                                    stop={stop}
                                                    index={index}
                                                    displayIndex={
                                                        stopOrderMap[
                                                            stop.motion_tools_stop_group ||
                                                                stop.id.toString()
                                                        ] + 1
                                                    }
                                                    runningWeight={
                                                        runningWeights[index]
                                                    }
                                                    onHover={setHoveredStopId}
                                                    onCheck={
                                                        canCheck
                                                            ? () =>
                                                                  checkClickHandler(
                                                                      stop
                                                                  )
                                                            : undefined
                                                    }
                                                    checked={checkedIds.includes(
                                                        stop.id
                                                    )}
                                                    onDegroup={
                                                        canDegroup
                                                            ? () =>
                                                                  degroupStopsHandler(
                                                                      props.stops
                                                                          .filter(
                                                                              (
                                                                                  s
                                                                              ) =>
                                                                                  s.stop_type_id !==
                                                                                  STOP_DRAFT_TYPE.Pause
                                                                          )
                                                                          .filter(
                                                                              (
                                                                                  s
                                                                              ) =>
                                                                                  s.motion_tools_stop_group ===
                                                                                  stop.motion_tools_stop_group
                                                                          )
                                                                          .map(
                                                                              (
                                                                                  s
                                                                              ) =>
                                                                                  s.id
                                                                          )
                                                                  )
                                                            : undefined
                                                    }
                                                    isDegrouping={degroupingIds.includes(
                                                        stop.id
                                                    )}
                                                    hasGroupsAround={
                                                        hasGroupsAround
                                                    }
                                                    eta={getEtaStringForStop(
                                                        stop
                                                    )}
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    ) : (
                        <div className="loading-placeholder">
                            <Icon
                                icon={faSpinnerThird}
                                size="3x"
                                color="var(--color-primary-400)"
                                fixedWidth
                                spin
                                style={iconStyle as React.CSSProperties}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="group-submit-button-group">
                <Button
                    disabled={!checkedIds.length}
                    leadingIcon={faListTree}
                    variant="primary"
                    label={t("popup.groupTourStops.submit")}
                    onClick={() => groupStopsHandler(checkedIds)}
                    isLoading={isGrouping}
                    style={{ width: "100%" }}
                />
                <Button
                    leadingIcon={faXmark}
                    variant="secondary"
                    label={t("popup.groupTourStops.close")}
                    onClick={() => props.onClose()}
                    style={{ width: "100%" }}
                />
            </div>
        </Popup>
    );
}

export default GroupTourStopsPopup;
